import {collection, getDocs, orderBy, query} from 'firebase/firestore';
import { getClientDB, getOrganisationIdFirebase } from '../api/AxiosManager';

export const getVersions = async () => {
	try {
		const result = await getDocs(
			query(
				collection(
					getClientDB(),
					`${getOrganisationIdFirebase()}/Versions/Versions`
				),
				orderBy('Deployed_On', 'desc'),
			)
		);
		const versionsArray = result.docs.map((document: any) => ({
			...document.data(),
			id: document.id,
		}));
		return versionsArray;
	} catch (e) {
		return [];
	}
};
