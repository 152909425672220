import {
	CLEAR_RELATION_TYPES,
	DELETE_SELECTED_ATTRIBUTES,
	GET_AVAILABLE_LEADS,
	GET_DEFAULT_VALUES,
	GET_PACKAGES,
	GET_PEOPLE_SEARCH,
	GET_RELATION_TYPES,
	SET_SELECTED_ATTRIBUTE,
	SET_SELECTED_ATTRIBUTES,
	UPDATE_VIEW,
	ERROR, SET_LEAD_CUSTOMERS
} from './LeadsActionTypes';
import { LeadsConstants } from '../../constants/LeadsConstants';

const initialState: any = {
	purchaseLeads: {
		relationTypes: [],
		selectedAttribute: {},
		selectedAttributes: [],
		currentView: LeadsConstants.ATTRIBUTES,
		packages: [],
		totalLeads: 0,
		availableLeads: 0,
	},
	retailLeads: [],
	leadCustomers: []
};
let selectedAttribute = {};
let selectedAttributes = [];
let filteredAttributes = [];
let uniqId = '';

const LeadsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_RELATION_TYPES:
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					relationTypes: action.payload,
				},
			};
		case CLEAR_RELATION_TYPES:
			return initialState;
		case SET_SELECTED_ATTRIBUTE:
			uniqId = `id ${new Date().getTime()}`;
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					selectedAttribute: { ...action.payload, id: uniqId },
				},
			};
		case SET_SELECTED_ATTRIBUTES:
			selectedAttributes = [...state.purchaseLeads.selectedAttributes];
			selectedAttributes = selectedAttributes.map((attribute) =>
				attribute.id === action.payload.id ? action.payload : attribute
			);
			selectedAttribute = selectedAttributes.find(
				(attribute) => attribute.id === action.payload.id
			);
			if (!selectedAttribute) {
				selectedAttributes = [...selectedAttributes, action.payload];
			}
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					selectedAttributes,
					selectedAttribute: action.payload,
				},
			};
		case DELETE_SELECTED_ATTRIBUTES:
			filteredAttributes = state.purchaseLeads.selectedAttributes.filter(
				(attribute) => attribute.id !== action.payload.id
			);
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					selectedAttributes: filteredAttributes,
					selectedAttribute: {},
				},
			};
		case UPDATE_VIEW:
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					currentView: action.payload,
				},
			};
		case GET_DEFAULT_VALUES:
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					selectedAttribute: {
						...state.purchaseLeads.selectedAttribute,
						options: action.payload,
					},
				},
			};
		case GET_PACKAGES:
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					packages: action.payload,
				},
			};
		case GET_PEOPLE_SEARCH:
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					totalLeads: action.payload.count,
				},
			};
		case GET_AVAILABLE_LEADS:
			return {
				...state,
				purchaseLeads: {
					...state.purchaseLeads,
					availableLeads: action.payload.count,
				},
			};
			case SET_LEAD_CUSTOMERS:
			return {
				...state,
				leadCustomers: action.payload,
			};
		case ERROR:
			return state;
		default:
			return state;
	}
};

export default LeadsReducer;
