import {
	getAuthUserName,
	getClientDB,
	getOrganisationIdFirebase,
	getUserId,
} from 'api/AxiosManager';
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	endBefore,
	getCountFromServer,
	getDoc,
	getDocs,
	increment,
	limit,
	limitToLast,
	orderBy,
	query,
	setDoc,
	startAfter,
	updateDoc,
	where,
	//	where,
} from 'firebase/firestore';
import { masterDb } from 'firebaseConfig';
import moment from 'moment';

export const createdDetailsCommonPropeties = () => ({
	Created_By: `${getUserId()}/User_Profiles/User_Profiles/${getUserId()}`,
	Created_By_Reference: getClientDB().doc(
		`/${getOrganisationIdFirebase()}/User_Profiles/User_Profiles/${getUserId()}`
	),
	Created_On: moment().toISOString(),
});

export const updatedDetailsCommonPropeties = () => ({
	Updated_By: `${getUserId()}/User_Profiles/User_Profiles/${getUserId()}`,
	Updated_By_Reference: getClientDB().doc(
		`/${getOrganisationIdFirebase()}/User_Profiles/User_Profiles/${getUserId()}`
	),
	Updated_On: moment().toISOString(),
});

export const fetchCount = async (
	collectionRef: string,
	whereConditions = []
) => {
	try {
		const finalWhereConditions = [];
		whereConditions.forEach((condition: any) => {
			finalWhereConditions.push(
				where(condition.field, condition.operator, condition.value)
			);
		});
		const coll = collection(
			getClientDB(),
			`${getOrganisationIdFirebase()}/${collectionRef}`
		);
		const q = query(coll, ...finalWhereConditions);
		const snapshot = await getCountFromServer(q);
		return snapshot.data().count;
	} catch (error) {
		return error;
	}
};

export const fetchAllFirestoreData = async (collectionRef: string) => {
	try {
		const qry = query(
			collection(getClientDB(), `${getOrganisationIdFirebase()}/${collectionRef}`)
		);
		const docResult = await getDocs(qry);
		const result = docResult.docs?.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return result || [];
	} catch (error) {
		return error;
	}
}; // fetch all firestore data with no pagination

export const fetchAllFirestoreDataFromMaster = async (
	collectionRef: string,
	whereConditions = []
) => {
	try {
		const finalWhereConditions = [];
		whereConditions.forEach((condition: any) => {
			finalWhereConditions.push(
				where(condition.field, condition.operator, condition.value)
			);
		});
		const coll = collection(masterDb, `${collectionRef}`);
		const qry = query(coll, ...finalWhereConditions);

		const docResult = await getDocs(qry);
		const result = docResult.docs?.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));
		return result || [];
	} catch (error) {
		return error;
	}
}; // fetch all firestore data with no pagination

export const fetchFirestoreData = async (data: firestoreFetchDataProps) => {
	const {
		firstDocument,
		lastDocument,
		rowsPerPage,
		type,
		collectionRefPath,
		field,
		sort,
		docsCount,
	} = data;
	try {
		const collectionRef = collection(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/${collectionRefPath}`
		);
		let q;

		if (type === 'next') {
			q = query(
				collectionRef,
				orderBy(field, sort),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
		} else if (type === 'prev') {
			q = query(
				collectionRef,
				orderBy(field, sort),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
		} else {
			q = query(collectionRef, orderBy(field, sort), limit(rowsPerPage));
		}
		let count = docsCount;
		if (docsCount === 0) {
			count = await fetchCount(collectionRefPath);
		}

		const snapshots = await getDocs(q);

		const result = snapshots.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return {
			count: count || 0,
			data: result,
			firstDocument: snapshots.docs[0],
			lastDocument: snapshots.docs[snapshots.docs.length - 1],
		};
	} catch (error) {
		return {
			count: 0,
			data: [],
			firstDocument: null,
			lastDocument: null,
			error,
		};
	}
}; // fetch firestore paginated data

export const fetchFirestoreCount = async () => {
	try {
		const snapshot = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/Counts`)
		);
		return snapshot.data();
	} catch (error) {
		return error;
	}
}; // fetch firestore count

export const deleteFirestoreData = async (data: firestoreDeleteDocProps) => {
	try {
		const { docRefPath, countRef } = data;
		const docRef = doc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/${docRefPath}`
		);
		let result = {
			success: false,
		};
		await deleteDoc(docRef);
		if (countRef) {
			await updateDoc(doc(getClientDB(), getOrganisationIdFirebase(), 'Counts'), {
				[countRef]: increment(-1),
			});
		}
		result = {
			success: true,
		};
		return result;
	} catch (error) {
		return {
			success: false,
			error,
		};
	}
}; // delete firestore data

export const deleteDocumentAndSubcollections = async (
	collectionPath,
	documentId,
	subcollection = ''
) => {
	const documentRef = doc(getClientDB(), collectionPath, documentId);

	if (subcollection !== '') {
		const subcollections = await getDocs(collection(documentRef, subcollection));

		// Recursively delete subcollection
		const deleteSubcollections = subcollections.docs.map(async (document) => {
			await deleteDocumentAndSubcollections(
				`${collectionPath}/${documentId}/${subcollection}}`,
				document.id
			);
		});
		await Promise.all(deleteSubcollections);
	}
	// Delete the document itself
	await deleteDoc(documentRef);

	return true;
}; // delete firestore data and subcollections

export const fetchFirestoreDoc = async (docPath: string) => {
	try {
		const docRef = doc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/${docPath}`
		);
		const snapshot = await getDoc(docRef);
		return snapshot.data();
	} catch (error) {
		return error;
	}
}; // fetch firestore doc

export const fetchFirestoreDocFromMasterDB = async (docPath: string) => {
	try {
		const docRef = doc(masterDb, `/${docPath}`);
		const snapshot = await getDoc(docRef);
		return snapshot.data();
	} catch (error) {
		return error;
	}
}; // fetch firestore doc

export const saveDocInFirestore = async (data: firestoreInsertDocProps) => {
	try {
		const { collectionRef, payload, countRef } = data;
		const result = await addDoc(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/${collectionRef}`
			),
			{
				...payload,
				Created_By: getAuthUserName(),
				Created_On: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
				Last_Modified_By: getAuthUserName(),
				Last_Modified_On: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
			}
		);
		if (result) {
			if (countRef) {
				await updateDoc(doc(getClientDB(), getOrganisationIdFirebase(), 'Counts'), {
					[countRef]: increment(1),
				});
			}
			return {
				id: result.id,
			};
		}
		return false;
	} catch (error) {
		return error;
	}
}; // save doc in firestore

export const updateDocInFirestore = async (docPath, payload) => {
	try {
		const docRef = doc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/${docPath}`
		);
		await updateDoc(docRef, {
			...payload,
			Last_Modified_By: getAuthUserName(),
			Last_Modified_On: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
		});
		return true;
	} catch (error) {
		return false;
	}
}; // update doc in firestore

export const updateDocInFirestoreInMasterDB = async (docPath, payload) => {
	try {
		const docRef = doc(masterDb, `/${docPath}`);
		await updateDoc(docRef, {
			...payload,
			Last_Modified_By: getAuthUserName(),
			Last_Modified_On: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
		});
		return true;
	} catch (error) {
		return false;
	}
}; // update doc in firestore

export const setDocInFirestore = async (docPath: string, payload) => {
	try {
		const docRef = doc(
			getClientDB(),
			`/${getOrganisationIdFirebase()}/${docPath}`
		);
		await setDoc(docRef, {
			...payload,
			Last_Modified_By: getAuthUserName(),
			Last_Modified_On: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
		});
		return true;
	} catch (error) {
		return false;
	}
}; // sets complete doc in firestore

export const checkRecordExistsInClientDB = (refPath, key, value) => {
	const collectionRef = getClientDB().collection(
		`${getOrganisationIdFirebase()}/${refPath}`
	);
	const qry = collectionRef.where(key, '==', value);

	const res = qry
		.get()
		.then((querySnapshot) => {
			if (!querySnapshot.empty) {
				return { recordExists: true, data: querySnapshot.docs[0].data() };
			}
			return { recordExists: false, data: null };
		})
		.catch(() => ({ recordExists: false, data: null }));
	return res;
};

export const checkRecordExistsInMasterDB = (refPath, key, value) => {
	const collectionRef = masterDb.collection(`${refPath}`);
	const qry = collectionRef.where(key, '==', value);

	const res = qry
		.get()
		.then((querySnapshot) => {
			if (!querySnapshot.empty) {
				return { recordExists: true, data: querySnapshot.docs[0].data() };
			}
			return { recordExists: false, data: null };
		})
		.catch(() => ({ recordExists: false, data: null }));
	return res;
};

export const checkDocIdExistsInMasterDB = (refPath, docId) => {
	const collectionRef = masterDb.collection(`${refPath}`);

	const docRef = collectionRef.doc(docId);

	const res = docRef
		.get()
		.then((querySnapshot) => {
			if (querySnapshot.exists) {
				return { recordExists: true };
			}
			return { recordExists: false };
		})
		.catch(() => ({ recordExists: false }));
	return res;
};

export const getIp = async () => {
	const response = await fetch('https://ipapi.co/json/');
	const data = await response.json();
	return data;
};

interface firestoreInsertDocProps {
	collectionRef: string;
	payload: any;
	countRef: string;
}

interface firestoreDeleteDocProps {
	docRefPath: string;
	countRef: string;
}

interface firestoreFetchDataProps {
	collectionRefPath: string;
	rowsPerPage: number;
	lastDocument: any;
	firstDocument: any;
	type: 'first' | 'next' | 'prev';
	field?: string;
	sort?: 'asc' | 'desc';
	docsCount?: number;
}
