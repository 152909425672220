import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import IconButton from '@mui/material/IconButton';
import Toast, { ToastTypes } from 'components/ToastNotify';
import {
	ConfirmEmailverification,
	ConfirmPasswordReset,
	verifyoobCode,
} from 'firebaseApis/authentication';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import InputTextField from 'storybook-mui/build/components/TextField';
import Button from 'storybook-mui/build/components/Button';
import { resetPasswordSchema } from './validations/signupValidation';

function AuthActions() {
	const history = useHistory();
	const [passwordVisibility, setPasswordVisibility] = useState(true);
	const [validationOnChange, setValidateOnChange] = useState(false);
	const [codeValid, setCodeValid] = useState(true);
	const { search } = useLocation();
	const { oobCode, mode } = queryString?.parse(search);
	const resetPasswordForm = useFormik({
		validationSchema: resetPasswordSchema,
		validateOnChange: validationOnChange,
		initialValues: {
			Password: '',
			ConfirmPassword: '',
		},
		onSubmit: () => {
			ConfirmPasswordReset(resetPasswordForm.values.Password, oobCode)
				.then(() => {
					Toast({
						title: 'Your Password Updated Successfully',
						type: ToastTypes.SUCCESS,
					});
					history.push('/');
				})
				.catch(() => {
					Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
				});
		},
	});

	const checkCodeValidOrNot = async () => {
		try {
			const result = await verifyoobCode(oobCode);
			if (typeof result === 'string') {
				setCodeValid(true);
			} else {
				setCodeValid(false);
			}
		} catch (error) {
			setCodeValid(false);
		}
	};

	useEffect(() => {
		if (mode === 'resetPassword') {
			checkCodeValidOrNot();
		} else {
			ConfirmEmailverification(oobCode);
		}
	}, []);
	return (
		<div className='h-screen flex items-center justify-center bg-gray-light'>
			<div>
				{codeValid ? (
					<>
						{mode === 'resetPassword' ? (
							<form
								onSubmit={resetPasswordForm.handleSubmit}
								className='flex flex-col space-y-5 bg-white shadow-lg p-5 m-5'
							>
								<h1 className='text-2xl'>Reset your Leap360 account password</h1>
								<InputTextField
									autoComplete='new-password'
									name='Password'
									type={passwordVisibility ? 'password' : 'text'}
									fieldId='password'
									labelName='Password'
									value={resetPasswordForm.values.Password}
									errorMessage={resetPasswordForm.errors.Password}
									valid={resetPasswordForm.errors.Password === undefined}
									onChange={resetPasswordForm.handleChange}
									adornmentMessage={
										<IconButton
											aria-label='toggle password visibility'
											onClick={() => setPasswordVisibility(!passwordVisibility)}
											onMouseDown={(event) => event.preventDefault()}
										>
											{passwordVisibility ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									}
								/>
								<InputTextField
									name='ConfirmPassword'
									type='password'
									autoComplete='new-password'
									fieldId='ConfirmPassword'
									labelName='Confirm Password'
									valid={
										!resetPasswordForm.values.ConfirmPassword ||
										resetPasswordForm.values.ConfirmPassword ===
											resetPasswordForm.values.Password
									}
									errorMessage='Password does not match'
									value={resetPasswordForm.values.ConfirmPassword}
									onChange={resetPasswordForm.handleChange}
								/>

								<Button
									onClick={() => {
										setValidateOnChange(true);
										resetPasswordForm.handleSubmit();
									}}
									title='RESET'
									endIcon={<Icon icon={IconTypes.ArrowForwardIos} />}
									disabled={
										resetPasswordForm.values.Password !==
										resetPasswordForm.values.ConfirmPassword
									}
								/>
							</form>
						) : (
							<div className='shadow bg-white p-8 rounded-md w-full'>
								<div className='flex flex-col justify-center items-center gap-10 py-5'>
									<h1 className='font-bold text-2xl'>Your email has been verified</h1>
									<p className='text-xl'>Your can now sign in with your new account</p>

									<Button
										onClick={() => history.push('/')}
										title='Click here to sign in '
									/>
								</div>
							</div>
						)}
					</>
				) : (
					<h1>Link Expired</h1>
				)}
			</div>
		</div>
	);
}

export default AuthActions;
