import {
	SET_SOCIAL_POSTS_BRAND_MANAGEMENT,
	SET_SOCIAL_FEED_BRAND_MANAGEMENT,
	SET_SOCIAL_ACCOUNTS_BRAND_MANAGEMENT,
	SET_FILTERED_SOCIAL_ACCOUNTS_BRAND_MANAGEMENT,
	SET_SOCIAL_PAGES_BRAND_MANAGEMENT,
	SET_SOCIAL_SELECTED_ACCOUNT_BRAND_MANAGEMENT,
	SET_SOCIAL_SELECTED_PAGE_BRAND_MANAGEMENT,
	SET_SOCIAL_SELECTED_PLATFORM_BRAND_MANAGEMENT,
	SET_SOCIAL_SHOW_PAGES_MANAGEMENT,
	RESET_FEEDS_DATA,
} from './BrandManagementTypes';

interface IBrandManagementReducer {
	socialPosts: any;
	filteredSocialPosts: any;
	socialFeed: any;
	socialAccounts: any;
	filteredSocialAccounts: any;
	pageNames: any;
	selectedAccount: any;
	selectedPage: any;
	showPages: boolean;
	selectedPlatform: string;
}

const initialState = {
	socialPosts: [],
	filteredSocialPosts: [],
	socialFeed: [],
	socialAccounts: [],
	filteredSocialAccounts: [],
	pageNames: [],
	selectedAccount: '',
	selectedPage: '',
	showPages: false,
	selectedPlatform: '',
};

const BrandManagementReducer = (
	state: any = initialState,
	action: any
): IBrandManagementReducer => {
	switch (action.type) {
		case SET_SOCIAL_POSTS_BRAND_MANAGEMENT:
			return {
				...state,
				socialPosts: action.payload,
			};
		case SET_SOCIAL_FEED_BRAND_MANAGEMENT:
			return {
				...state,
				socialFeed: action.payload,
			};
		case SET_SOCIAL_ACCOUNTS_BRAND_MANAGEMENT:
			return {
				...state,
				socialAccounts: action.payload,
			};
		case SET_FILTERED_SOCIAL_ACCOUNTS_BRAND_MANAGEMENT:
			return {
				...state,
				filteredSocialAccounts: action.payload,
			};
		case SET_SOCIAL_PAGES_BRAND_MANAGEMENT:
			return {
				...state,
				pageNames: action.payload,
			};
		case SET_SOCIAL_SELECTED_ACCOUNT_BRAND_MANAGEMENT:
			return {
				...state,
				selectedAccount: action.payload,
			};
		case SET_SOCIAL_SELECTED_PAGE_BRAND_MANAGEMENT:
			return {
				...state,
				selectedPage: action.payload,
			};
		case SET_SOCIAL_SELECTED_PLATFORM_BRAND_MANAGEMENT:
			return {
				...state,
				selectedPlatform: action.payload,
			};
		case SET_SOCIAL_SHOW_PAGES_MANAGEMENT:
			return {
				...state,
				showPages: action.payload,
			};
		case RESET_FEEDS_DATA:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default BrandManagementReducer;
