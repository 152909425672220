import { getClientDB, getOrganisationIdFirebase } from "api/AxiosManager";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";

export const GetLocationIds = async (title) => {
	try {
		const result = await getDocs(
			query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/Locations/Locations`),
				where('Location_Name', '==', title)
			)
		);

		const resultSnapshots = result.docs.map((d) => ({
			id: d.id,
            ...d.data()
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};

export const GetServicesByID = async (id)=>{
	try{
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/Package/Package`, id)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
}

export const GetServiceIds = async (title) => {
	try {
		const result = await getDocs(
			query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/Package/Package`),
				where('Package_Name', '==', title)
			)
		);

		const resultSnapshots = result.docs.map((d) => ({
			id: d.id,
            ...d.data()
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};

export const GetAllOrders = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/websiteBookings/orders`
			)
		);

		const docResult: any = await getDocs(qry);
		const resultArray = docResult.docs.map((item: any) => ({
			...item.data(),
			id: item.id,
		}));
		return resultArray.filter((item) => item?.Display !== false);
	} catch (error) {
		return error;
	}
};

export const GetCalendarGridState = async (id) => {
	try {
		const result = await getDocs(
			query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`),
				where('Associate_User', '==', id)
			)
		);

		const resultSnapshots = result.docs.map((d) => ({
			id: d.id,
            ...d.data()
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};

export const UpdateCalendarGridState = async (id: any, payload: any) => {
	try {
		await updateDoc(
			doc(getClientDB(), `/${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`, id),
			payload
		);
		return {
			success: true,
			message: 'Grid state updated successfully',
		};
	} catch (error) {
		return error;
	}
};
