import React from 'react';

export const FlatIcons = ({
	iconName = 'flaticon-add',
	className,
	textSize,
	onClick,
}: IIcon) => (
	<span
		onClick={onClick}
		className={`${className} flex items-center justify-center ${textSize}`}
	>
		<i className={`flaticon-${iconName} leading-0`} />
	</span>
);

export interface IIcon {
	iconName: string;
	className?: string;
	onClick?: any;
	textSize?:
		| 'text-xs'
		| 'text-sm'
		| 'text-base'
		| 'text-lg'
		| 'text-xl'
		| 'text-2xl'
		| 'text-3xl'
		| 'text-4xl'
		| 'text-5xl'
		| 'text-6xl'
		| 'text-7xl'
		| 'text-8xl'
		| 'text-9xl';
}

export default FlatIcons;
