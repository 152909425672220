import React from 'react';
import Button from 'storybook-mui/build/components/Button';
import TwLogo from 'components/Logos/assets/001-twitter.svg';
import GgLogo from 'components/Logos/assets/008-search.svg';
import FacebookLogo from 'components/Logos/assets/002-facebook.svg';

import {
	GoogleSignIn,
	TwitterSignIn,
	FacebookSignIn,
} from 'firebaseApis/authentication';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
	SetAuthenticationType,
	SetUserInfo,
} from 'reducers/Authentication/AuthActions';
import Toast, { ToastTypes } from 'components/ToastNotify';
import {
	GetDocumentInfo,
} from 'firebaseApis/twoFactorAuthenticationFirebase';
import { showLoading, hideLoading } from 'reducers/Alerts/AlertActions';
import { IUserDataInLocalStorage } from './onboardInterfaces';

function SocialLogins() {
	const dispatch = useDispatch();
	const history = useHistory();

	const redirectToOrgOr2FAPage = async () => {
		const result = await GetDocumentInfo();
		if (result && (result.isGoogleAuthVerfied || result.isSMSAuthVerfied)) {
			history.push('/login2factorauthentication');
		} else {
			history.push('/signup2FA');
		}
	};

	const signInWithFB = async () => {
		dispatch(showLoading());
		try {
			const result: any = await FacebookSignIn();
			if (result.success) {
				dispatch(SetUserInfo(result.data));
				const dataForLocalStorage: IUserDataInLocalStorage = {
					fName: result.data.FirstName,
					lName: result.data.LastName,
					email: result.data.Email,
					login: result.data.Login,
					userId: result.data.User_Id,
					emailVerified: true,
					mobileType: '',
					countryCode: '',
					phoneNumber: '',
					landline_ext: '',
					email_optIn: false,
					sms_optIn: false,
					voice_optIn: false,
					userRegistrationType: 'facebook',
					isGoogleAuthEnabled: result.data.isGoogleAuthEnabled,
					isSMSAuthEnabled: result.data.isSMSAuthEnabled,
				};
				localStorage.setItem('user', JSON.stringify(dataForLocalStorage));
				dispatch(SetAuthenticationType('LOGIN'));
				redirectToOrgOr2FAPage();
			} else if (
				result.code !== 'auth/popup-closed-by-user' &&
				result.code !== 'auth/user-cancelled'
			) {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			}
		} catch (e) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
		dispatch(hideLoading());
	};
	const signInWithGoogle = async () => {
		try {
			dispatch(showLoading());
			const result = await GoogleSignIn();
			if (result.success) {
				dispatch(SetUserInfo(result.data));
				const dataForLocalStorage: IUserDataInLocalStorage = {
					fName: result.data.FirstName,
					lName: result.data.LastName,
					email: result.data.Email,
					login: result.data.Login,
					userId: result.data.User_Id,
					emailVerified: true,
					mobileType: '',
					countryCode: '',
					phoneNumber: '',
					landline_ext: '',
					email_optIn: false,
					sms_optIn: false,
					voice_optIn: false,
					userRegistrationType: 'google',
					isGoogleAuthEnabled: result.data.isGoogleAuthEnabled,
					isSMSAuthEnabled: result.data.isSMSAuthEnabled,
				};
				localStorage.setItem('user', JSON.stringify(dataForLocalStorage));
				dispatch(SetAuthenticationType('LOGIN'));
				redirectToOrgOr2FAPage();
			} else if (
				result.code !== 'auth/popup-closed-by-user' &&
				result.code !== 'auth/user-cancelled'
			) {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			}
		} catch (error) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
		dispatch(hideLoading());
	};

	const signInWithTwitter = async () => {
		try {
			dispatch(showLoading());
			const result = await TwitterSignIn();
			if (result.success) {
				dispatch(SetUserInfo(result.data));
				const dataForLocalStorage: IUserDataInLocalStorage = {
					fName: result.data.FirstName,
					lName: result.data.LastName,
					email: result.data.Email,
					login: result.data.Login,
					userId: result.data.User_Id,
					emailVerified: true,
					mobileType: '',
					countryCode: '',
					phoneNumber: '',
					landline_ext: '',
					email_optIn: false,
					sms_optIn: false,
					voice_optIn: false,
					userRegistrationType: 'twitter',
					isGoogleAuthEnabled: result.data.isGoogleAuthEnabled,
					isSMSAuthEnabled: result.data.isSMSAuthEnabled,
				};
				localStorage.setItem('user', JSON.stringify(dataForLocalStorage));
				dispatch(SetAuthenticationType('LOGIN'));
				redirectToOrgOr2FAPage();
			} else if (
				result.code !== 'auth/popup-closed-by-user' &&
				result.code !== 'auth/user-cancelled'
			) {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			}
		} catch (error) {
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
		dispatch(hideLoading());
	};

	return (
		<div className='social-login-btns-ct px-4'>
			<div className='w-full lg:justify-center lg:items-center flex flex-col lg:flex-row lg:my-5 gap-2 my-3 lg:gap-4'>
				<Button
					title=''
					onClick={signInWithGoogle}
					startIcon={<img src={GgLogo} width='30' height='30' />}
					color='inherit'
					variant='outlined'
					sx={{ color: '#28B446' }}
				/>
				<Button
					title=''
					onClick={() => signInWithTwitter()}
					startIcon={<img src={TwLogo} width='30' height='30' />}
					color='inherit'
					variant='outlined'
					sx={{ color: '#00A6DE' }}
				/>
				<Button
					title=''
					onClick={signInWithFB}
					startIcon={<img src={FacebookLogo} width='30' height='30' />}
					color='inherit'
					variant='outlined'
					sx={{ color: '#00A6DE' }}
				/>
			</div>
		</div>
	);
}

export default SocialLogins;
