import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import Leap360LoaderGif from 'assets/img/leapspin.gif';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(
	props: LinearProgressProps & {
		value: number;
		textcolor?: string;
	}
) {
	const { value } = props;
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<div className='fixed left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 '>
					<img src={Leap360LoaderGif} className=' ease-linear h-52 w-52' />
					<span className='fixed text-white top-1/2 left-1/2 text-center font-bold transform -translate-y-1/2 -translate-x-1/2 text-xl'>
						{`${Math.round(value)}%`}
					</span>
				</div>
			</Box>

			{/* <Box sx={{ minWidth: 35 }}>
				<p
					className={`text-${textcolor || 'gray-dark'} font-bold text-base`}
				>{`${Math.round(value)}%`}</p>
			</Box> */}
		</Box>
	);
}

function LinearProgressBar({
	delay = 1000,
	color = 'info',
	message = '',
	showBackDrop = true,
	textColor = 'black',
	incrementBy = 10,
	currentProgress = 0,
}: ITextInfo) {
	const [progress, setProgress] = useState<number>(0);
	const [intervalValue, setIntervalValue] = useState<any>(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setProgress((counter) => counter + incrementBy);
		}, Math.ceil(delay / 100));
		setIntervalValue(interval);
		return () => {
			clearInterval(interval);
		};
	}, []);
	useEffect(() => {
		if (progress === 100 || currentProgress === 100) {
			setProgress(100);
			clearInterval(intervalValue);
		}
	}, [progress, currentProgress]);
	if (showBackDrop)
		return (
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open
			>
				<div className='flex flex-col items-center justify-center w-full md:w-2/3 mx-auto '>
					<LinearProgressWithLabel
						value={progress}
						color={color}
						textcolor={textColor}
					/>
					<p className='mt-40 text-white font-semibold text-lg italic '>{message}</p>
				</div>
			</Backdrop>
		);

	return (
		<div className='flex felx-col items-center justify-center w-full  md:w-2/3 mx-auto'>
			<LinearProgressWithLabel
				value={progress}
				color={color}
				textcolor={textColor}
			/>

			<p className='mt-40 text-white font-semibold text-lg italic'>{message}</p>
		</div>
	);
}

export interface ITextInfo {
	delay?: number;
	incrementBy?: number;
	currentProgress?: number;
	message?: string;
	showBackDrop?: boolean;
	onProgressComplete?: any;
	color?:
		| 'primary'
		| 'secondary'
		| 'warning'
		| 'success'
		| 'error'
		| 'info'
		| 'inherit';
	textColor?: string;
}

export default LinearProgressBar;
