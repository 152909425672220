import React from 'react';
import { useSelector } from 'react-redux';
import { INewOrgInitialState } from 'reducers/CreateOrg';
import AccountType from './OrgCreateComponents/AccountType';
import OrgCreateWizard from './OrgCreateComponents/OrgCreateWizard';
import IndustrySelection from './OrgCreateComponents/IndustrySelection';
import OrgName from './OrgCreateComponents/OrgName';
import Payment from './OrgCreateComponents/Payment';
import CountrySelection from './OrgCreateComponents/CountrySelection';
import FeatureSelection from './OrgCreateComponents/FeatureSelection';
import OrgReviewDetails from './OrgCreateComponents/OrgReviewDetails';
import BillingConfirmation from './SubOrgComponents/BillingConfirmation';
import InviteUsers from './SubOrgComponents/InviteUsers';

function CreateOrg() {
	const newOrgStore: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);
	const views = [
		{
			component: <AccountType />,
		},
		{
			component: <IndustrySelection />,
		},
		{
			component: <OrgName />,
		},
		{
			component: <CountrySelection />,
		},
		{
			component: <FeatureSelection />,
		},
		{
			component: <OrgReviewDetails />,
		},
		{
			component: <Payment />,
		},
		{
			component: <InviteUsers />,
		},
	];
	if (newOrgStore.Org_Flow_Type === 'SUB') {
		views.splice(views.length - 2, 0, {
			component: <BillingConfirmation />,
		});
	}
	return (
		<div>
			<OrgCreateWizard content={views[newOrgStore.Step].component} />
		</div>
	);
}

export default CreateOrg;
