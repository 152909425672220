import { getUser, getUserId } from 'api/AxiosManager';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import {
	collection,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { masterapp, masterDb } from 'firebaseConfig';

export const GetDocumentInfo = async () => {
	try {
		const result = await getDocs(
			query(
				collection(masterDb, 'User2WayAuthentication'),
				where('userid', '==', getUserId())
			)
		);

		const resultSnapshots = result.docs.map((d) => ({
			id: d.id,
			...d.data(),
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};

export const BasicLoginToGetTwoWayAuthDetails = async (payload: any) => {
	const auth = getAuth(masterapp);
	try {
		const signInResult: any = await signInWithEmailAndPassword(
			auth,
			payload.Email,
			payload.Password
		);
		const userSnapshots = await getDocs(
			query(
				collection(masterDb, 'User2WayAuthentication'),
				where('Email', '==', signInResult?.user?.email)
			)
		);
		const resultArray = userSnapshots.docs.map((document: any) => ({
			User_Id: document.id,
			...document.data(),
		}));
		return {
			success: true,
			data: {
				...resultArray[0],
				emailVerified: signInResult.user.emailVerified,
				currentUser: signInResult.user,
			},
		};
	} catch (error) {
		let errorMessage = '';
		if (error.code === 'auth/user-not-found') {
			errorMessage = 'User not found';
		} else if (error.code === 'auth/wrong-password') {
			errorMessage = 'Wrong password';
		} else if (error.code === 'auth/user-disabled') {
			errorMessage = 'User disabled';
		} else if (error.code === 'auth/too-many-requests') {
			errorMessage = 'Too many requests';
		} else if (error.code === 'auth/invalid-email') {
			errorMessage = 'Invalid email';
		}
		return {
			success: false,
			error: errorMessage,
		};
	}
};

export const isAuthenticationVerified = async (id: any, payload: any) => {
	try {
		await updateDoc(doc(masterDb, 'User2WayAuthentication', id), payload);
		return {
			success: true,
			message: '2-Factor Authentication set up successfully',
		};
	} catch (error) {
		return error;
	}
};

export const GetUserDocInfo = async () => {
	try {
		const result = await getDocs(
			query(collection(masterDb, 'Users'), where('Email', '==', getUser().email))
		);

		const resultSnapshots = result.docs.map((d) => ({
			id: d.id,
			...d.data(),
		}));
		return resultSnapshots[0];
	} catch (error) {
		return error;
	}
};
