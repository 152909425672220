import React, { useEffect, useState } from 'react';
import MUIGridGlobal from 'components/Table/MUIGlobalGrid';
import { useDispatch } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getVersions } from 'firebaseApis/versions';
import { getDateTimeWithUnix } from 'helpers/Formats';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';

const Version = () => {
	const [data, setData] = useState<any>([]);
	const dispatch = useDispatch();
	const columns: GridColDef[] = [
		{
			headerName: 'Org Name',
			field: 'Org_Name',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'App Version',
			field: 'Version',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Deployed On',
			field: 'Deployed_On',
			flex: 1,
			minWidth: 160,
			renderCell: (rowData) =>
				getDateTimeWithUnix(rowData.row.Deployed_On.seconds),
		},
		{
			headerName: 'UI',
			field: 'Storybook',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Frontend POS',
			field: 'Frontend_POS',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Engine',
			field: 'App_Engine',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Billing',
			field: 'Cloudrun_Billing',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Booking',
			field: 'Cloudrun_Booking',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Campaign Processor',
			field: 'Cloudrun_Campaign_Processor',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Email Campaign',
			field: 'Cloudrun_Email_Campaign',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Email Domain Verification',
			field: 'Cloudrun_Email_Domain_Verification',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Organizations',
			field: 'Cloudrun_Organizations',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'POS',
			field: 'Cloudrun_POS',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Send Email',
			field: 'Cloudrun_Send_Email',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Send SMS',
			field: 'Cloudrun_Send_SMS',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Send Voice',
			field: 'Cloudrun_Send_Voice',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Shipping',
			field: 'Cloudrun_Shipping',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'SMS Campaign',
			field: 'Cloudrun_SMS_Campaign',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Social Campaign',
			field: 'Cloudrun_Social_Campaign',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Social Feeds',
			field: 'Cloudrun_Social_Feeds',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Social Login',
			field: 'Cloudrun_Social_Login',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Trigger',
			field: 'Cloudrun_Trigger',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Twilio Api',
			field: 'Cloudrun_Twilio_Api',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Verifone',
			field: 'Cloudrun_Verifone',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Voice Campaign',
			field: 'Cloudrun_Voice_Campaign',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Miscellaneous',
			field: 'Cloudrun_Miscellaneous',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Verifone Client Payment',
			field: 'Cloudrun_Verifone_Client_Payment',
			flex: 1,
			minWidth: 160,
		},
		{
			headerName: 'Changes',
			field: 'Changes',
			flex: 1,
			minWidth: 160,
		},
	];

	const fetchData = async () => {
		dispatch(showLoading());
		const versions = await getVersions();
		setData(versions);
		dispatch(hideLoading());
	};
	useEffect(() => {
		fetchData();
	}, []);
	return (
		<div>
			<div className='flex items-center justify-center font-bold text-black-100'>
				<h1>Current version: {data[0]?.Version}</h1>
			</div>
			<MUIGridGlobal  columns={columns} dataSource={data} rowCount={data.length} />
		</div>
	);
};

export default Version;
