import React from 'react';
import { useHistory } from 'react-router';
import InputTextField from 'storybook-mui/build/components/TextField';
import { Form, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import Button from 'storybook-mui/build/components/Button';
import { useDispatch } from 'react-redux';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { SendInvitationToUser } from 'firebaseApis/users';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { getUser } from 'api/AxiosManager';

const InviteUsers = () => {
	const orgName = getUser()?.organizationName || '';
	const dispatch = useDispatch();
	const history = useHistory();
	const inviteUsers = async (values: any) => {
		dispatch(showLoading());
		try {
			values.users.forEach(async (user: any) => {
				await SendInvitationToUser(user.email);
			});
			Toast({ title: 'Invitation sent successfully', type: ToastTypes.SUCCESS });
			history.push('/Marketing/SingleChannelCampaigns');
			dispatch(hideLoading());
		} catch (error) {
			dispatch(hideLoading());
		}
	};

	return (
		<div className='w-full mx-auto'>
			<div className='flex flex-col gap-6'>
				<h1 className='text-secondary-600 text-4xl text-center'>
					LETS INVITE OTHERS TO <b>{orgName || ''}</b>
				</h1>
				<div>
					<Formik
						initialValues={{
							users: [
								{
									name: '',
									email: '',
								},
							],
						}}
						validationSchema={Yup.object().shape({
							users: Yup.array().of(
								Yup.object().shape({
									name: Yup.string().required('Name is required'),
									email: Yup.string()
										.email('Invalid email')
										.required('Email is required'),
								})
							),
						})}
						validateOnChange
						onSubmit={() => {}}
						render={({
							values,
							errors,
							handleChange,
							validateForm,
							handleSubmit,
						}: {
							values: any;
							errors: any;
							handleChange: any;
							validateForm: any;
							handleSubmit: any;
						}) => (
							<Form>
								<FieldArray
									name='users'
									render={({ remove, push }) => (
										<div className='w-full'>
											{values.users.length > 0 &&
												values.users.map((user, index) => (
													<div className='flex gap-5 bg-white py-4 px-10' key={index}>
														<InputTextField
															name={`users.${index}.name`}
															fieldId={`users.${index}.name`}
															onChange={handleChange}
															value={user.name}
															labelName='Name'
															valid={
																errors &&
																errors.users &&
																errors.users[index] &&
																errors.users[index].name === undefined
															}
															errorMessage={
																errors &&
																errors.users &&
																errors.users[index] &&
																errors.users[index].name
															}
														/>
														<InputTextField
															name={`users.${index}.email`}
															fieldId={`users.${index}.email`}
															onChange={handleChange}
															value={user.email}
															labelName='Email'
															valid={
																errors &&
																errors.users &&
																errors.users[index] &&
																errors.users[index].email === undefined
															}
															errorMessage={
																errors &&
																errors.users &&
																errors.users[index] &&
																errors.users[index].email
															}
														/>
														<Icon
															className='cursor-pointer'
															icon={IconTypes.Delete}
															onClick={() => remove(index)}
														/>
													</div>
												))}
											<div className='flex justify-center w-full mt-10'>
												<Button
													title={
														<h3 className='font-light tracking-wider'>Add another user</h3>
													}
													color='info'
													onClick={() => push({ name: '', email: '' })}
												/>
											</div>
										</div>
									)}
								/>
								<div className='flex justify-center mt-10'>
									<Button
										title={
											<h3
												className='font-light tracking-wider text-gray-500'
												onClick={() => {
													history.push('/Marketing/SingleChannelCampaigns');
												}}
											>
												Skip to Dashboard
											</h3>
										}
										variant='text'
										onClick={() => {}}
									/>
									<Button
										title={<h3 className='font-light tracking-wider'>Next</h3>}
										type='submit'
										onClick={() => {
											handleSubmit();
											validateForm().then((errs) => {
												if (Object.keys(errs).length === 0) {
													inviteUsers(values);
												}
											});
										}}
									/>
								</div>
							</Form>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default InviteUsers;
