const initialState = {
    progressVal: 10
}

export const increaseProgressVal = (val: number) =>
    ({
        type: "increaseProgressBarVal",
        payload: val
    })


const welcomeProgressBarReducer = (state=initialState, action) => {
    switch(action.type) {
        case "increaseProgressBarVal": 
        return {
            ...state,
            progressVal: action.payload
        }
        break;
        default:
            return state;

    }
}

export default welcomeProgressBarReducer;
