import moment from 'moment';
import momentTimezone from 'moment-timezone';

import {
	DEFAULT_CURRENCY_SYMBOL,
	DEFAULT_DATE_FORMAT,
	DEFAULT_DATE_TIME_FORMAT,
	DEFAULT_TIME_FORMAT,
} from '../constants/Constants';
import { getUser } from '../api/AxiosManager';

export const getDateFormat = (date: string, format?: string) => {
	if (!date) {
		return '';
	}
	const newDate = new Date(Date.parse(date));
	const { FormatDate } = getUser()?.OtherConfig; // Take this from config
	return moment(newDate).format(format || FormatDate || DEFAULT_DATE_FORMAT);
};

export const getDateTimeWithUnix = (unix: number, format?: string) => {
	if (!unix) return '';
	const { FormatDate, FormatTime } = getUser()?.OtherConfig;
	return moment
		.unix(unix)
		.format(format || `${FormatDate} ${FormatTime}` || DEFAULT_DATE_FORMAT);
};

export const getDateWithUnix = (unix: number, format?: string) => {
	if (!unix) return '';
	const { FormatDate } = getUser()?.OtherConfig;
	return moment
		.unix(unix)
		.format(format || `${FormatDate}` || DEFAULT_DATE_FORMAT);
};

export const getDateTimeFormat = (date: string, format?: string) => {
	if (!date) {
		return '';
	}
	const { FormatDate, FormatTime } = getUser()?.OtherConfig; // Take this from config
	const newDate = new Date(Date.parse(date));
	return moment(newDate).format(
		format || `${FormatDate} ${FormatTime}` || DEFAULT_DATE_TIME_FORMAT
	);
};

export const getTimeFormat = (date: string, format?: string) => {
	const { FormatTime } = getUser()?.OtherConfig; // Take this from config

	return moment(date).format(format || FormatTime || DEFAULT_TIME_FORMAT);
};
const unicodeToChar = (text: string) =>
	text.replace(/\\u[\dA-F]{4}/gi, (match) =>
		String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
	);

export const getCurrencySymbol = (currencySymbol: string) =>
	unicodeToChar(currencySymbol || DEFAULT_CURRENCY_SYMBOL);

export const getCurrentDateFromTimeZone = (timeZone?: string) => {
	const { TimeZone } = getUser()?.OtherConfig; // Take this from config
	const selectedTimeZone = timeZone || TimeZone;
	const momentTimeZone = selectedTimeZone.split(' ')[1];
	const time = momentTimezone.tz(momentTimeZone);
	return new Date(time.format().split('T')[0]);
};

export const getCurrentTimeFromTimeZone = (timeZone?: string) => {
	const { TimeZone } = getUser()?.OtherConfig; // Take this from config
	const selectedTimeZone = timeZone || TimeZone;
	const momentTimeZone = selectedTimeZone.split(' ')[1];
	const time = momentTimezone.tz(momentTimeZone);
	const timeFormat = time.format('HH mm');
	const [hr, min] = timeFormat.split(' ');
	return new Date(new Date().setHours(Number(hr), Number(min)));
};
export const getDateFromTime = (time: any) => {
	const hr = parseInt(time.split(':')[0], 10);
	const mm = parseInt(time.split(':')[1], 10);
	return new Date(null, null, null, hr, mm);
};
export const getDateFromTimeLocationEndSchedule = (time: any) => {
	if (!time) {
		return null;
	}
	const hr = parseInt(time.split(':')[0], 10);
	const mm = parseInt(time.split(':')[1], 10);
	return new Date(null, null, null, hr, mm + 1);
};
export const getDateFromTimeLocation = (time: any) => {
	if (!time) {
		return null;
	}
	const hr = parseInt(time.split(':')[0], 10);
	const mm = parseInt(time.split(':')[1], 10);
	return new Date(null, null, null, hr, mm);
};
export const getDateFromCampaignScheduleTime = (time: string) => {
	const hr = parseInt(time.split(':')[0], 10);
	const mm = parseInt(time.split(':')[1], 10);
	return new Date(null, null, null, hr, mm + 10);
};
export const getDate = (date: string) => {
	const year = parseInt(date.split(' ')[0], 10);
	const month = parseInt(date.split(' ')[1], 10);
	const day = parseInt(date.split(' ')[2], 10);
	const hr = parseInt(date.split(' ')[3], 10);
	const mm = parseInt(date.split(' ')[4], 10);
	return new Date(year, month - 1, day, hr, mm + 10);
};
export const getValidDate = (
	date: { value: string },
	startTime: string,
	endTime: string
) => {
	if (date?.value && startTime && endTime) {
		const startDate: Date = moment(date.value).toDate();
		startDate.setHours(Number(startTime.split(':')[0]));
		startDate.setMinutes(Number(startTime.split(':')[1]));
		const endDate: Date = moment(date.value).toDate();
		endDate.setHours(Number(endTime.split(':')[0]));
		endDate.setMinutes(Number(endTime.split(':')[1]));
		return { startDate, endDate };
	}
	return null;
};
