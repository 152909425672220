import {
	SET_BOOKING_NAME,
	SET_BOOKING_DESCRIPTION,
	BOOKINGS_UPDATE_VIEW,
	SET_SELECTED_LOCATIONS,
	SET_DATETIME_SLOTS,
	SET_CHECKED_PACKAGES,
	SET_PACKAGE_UPSELLS_COLUMN_DATA,
	SET_PACKAGE_UPSELLS_GRID_DATA,
	SET_FOOD_ADD_ON_PRODUCTS,
	SET_DECORATIVE_ADD_ON_PRODUCTS,
	SET_CUSTOMERINFORMATION,
	SET_BOOKING_POLICY_INFO,
	SET_BOOKING_SEQUENCE,
	RESET_BOOKINGS,
	SET_CHECK_OUT,
	SET_FLOW_TYPE,
	SET_CHECKED_FOOD_ADD_ON_PRODUCTS,
	SET_CHECKED_DECORATIVE_ADD_ON_PRODUCTS,
	SET_OPEN_FLOW_BUILDER,
	SET_DATE_TIME_TOGGLER,
	SET_GUEST_OF_HONOR_UI,
	SET_CHECKED_STAFF,
	SET_COLLECT_DATA,
	SET_ALL_ELEMENTS,
	SET_ORDER_CONFIG,
	SET_BOOKING_IMAGE_URL,
	SET_BOOKING_HOURS_IN_BOOKING_FLOW,
	SET_PAYMENT_CONFIG_IN_bOOKING_FLOW,
} from './BookingTypes';

interface IBookings {
	BookingName: string;
	BookingDescription: string;
	currentView: number;
	locationArray: any;
	checkedselectedLocation: any;
	dateTimeSlots: [];
	selectedFoodAddOnProducts: [];
	selcetedDecorativeAddOnProducts: [];
	customerInformation: any;
	checkedselectedPackages: any;
	packageUpsellColumnData: [];
	packageUpsellsGridData: [];
	bookingPolicyInfo: {};
	bookingSequence: [];
	checkOut: {};
	bookingFlowtype: string;
	checkedFoodAddOnProducts: [];
	checkedDecorativeAddOnProducts: [];
	openFlow_Builder: boolean;
	dateTime: {};
	guestOfHonordynamicUI: string;
	checkedSelectedStaff: [];
	collectData: {};
	allElements: [];
	orderConfig: {};
	bookingImage: string;
	bookingHours: {};
	paymentConfig: {};
}

const initialState: IBookings = {
	BookingName: '',
	BookingDescription: '',
	currentView: 0,
	checkedselectedLocation: [],
	checkedselectedPackages: [],
	checkedFoodAddOnProducts: [],
	checkedDecorativeAddOnProducts: [],
	packageUpsellColumnData: [],
	packageUpsellsGridData: [],
	locationArray: {
		selectedLocations: [],
	},
	dateTimeSlots: [],
	selectedFoodAddOnProducts: [],
	selcetedDecorativeAddOnProducts: [],
	customerInformation: {
		customerValue: 'Contacts',
		checkedSelectedFields: [],
	},
	openFlow_Builder: false,
	checkOut: {
		Allow_Guest_CheckOut: false,
		Include_the_Shipping_Address_in_the_Checkout_Page: false,
		Enable_Cross_Sells_in_checkout_page: false,
		Terms_and_Conditions: 'Include_Terms_and_Condition',
		Select_the_email_Creative: '',
	},
	bookingPolicyInfo: {
		general: {
			Book_Appointments_Upto_Before_Start_Time: null,
			Book_Appointments_Upto_In_The_Future: null,
			Book_Appointments_Upto_Before_Start_Time_Period_Select: 'hours',
			Maximum_No_Of_Booking_Allowed_For_Same_Time_Slot: null,
			Book_Appointments_Upto__In_The_Future_Period_Select: 'months',
			Enable_Timer_For_Booking: false,
			Booking_Timer_value: null,
			Booking_Timer_Period_Select: 'minutes',
		},
		rescheduling: {
			Send_the_Re_Scheduling_Confirmation_via_Email: 'Yes',
			Select_the_Email_Creative: 'Re_Scheduling_Email',
			Send_the_Re_Scheduling_Confirmation_via_Sms: 'Yes',
			Select_the_Sms_Creative: 'Re_Scheduling_Sms',
			Allow_Clients_To_Reschedule_Appointments: 'Upto',
			Allow_Clients_To_Reschedule_Appointments_Upto_Time_before_The_Appointment:
				null,
			Upto_Time_before_The_Appointment_Period_Select: 'minutes',
		},

		cancellation: {
			Send_the_Cancellation_Confirmation_via_Email: 'Yes',
			Select_the_Cancellation_Email_Creative: 'Re_Scheduling_Email',
			Send_the_Cancellation_Confirmation_via_Sms: 'Yes',
			Select_the_Cancellation_Sms_Creative: 'Re_Scheduling_Sms',
			Allow_Clients_To_Cancellation_Appointments: 'Upto',
			Allow_Clients_To_Cancellation_Appointments_Upto_Time_before_The_Appointment:
				null,
			Upto_Time_before_The_Appointment_Cancellation_Period_Select: 'minutes',
			Is_there_any_cancellation_charges_for_the_services: 'No',
			Cancellation_Charges: 'Amount',
			Cancellation_Charges_Value: null,
			Cancellation_Charges_Amount: null,
			Cacellation_Charges_Perentage: null,
		},
	},
	bookingSequence: [],
	bookingFlowtype: 'event',
	dateTime: {
		Toggler: 'allowSelection',
		Date: '',
		From: '',
		To: '',
		DateArray: [],
		DateType: 'individualDates',
		Reservation_Timer: 10,
	},
	guestOfHonordynamicUI: '',
	checkedSelectedStaff: [],
	collectData: { Guest_Of_Honor: 'saitest', Host_Information: 'saitest2' },
	allElements: [],
	orderConfig: {
		Launch_Edit_Orders_Page: '',
		Launch_Reschedule_Orders_Page: '',
		Order_Id_Prefix: '',
	},
	bookingImage: '',
	bookingHours: {
		Is_Booking_Starts_Before: false,
		Is_Booking_Closes_Before: false,
		Booking_Starts_Before_Time: 0,
		Booking_Starts_Period: 'days',
		Booking_Closes_Before_Time: 0,
		Booking_Closes_Period: 'days',
	},
	paymentConfig: {
		Gratuity_Percentage: 16,
	},
};

const bookingsReducer = (state: any = initialState, action): IBookings => {
	switch (action.type) {
		case SET_OPEN_FLOW_BUILDER:
			return { ...state, openFlow_Builder: !action.payload };
		case SET_BOOKING_NAME:
			return { ...state, BookingName: action.payload };
		case SET_BOOKING_DESCRIPTION:
			return { ...state, BookingDescription: action.payload };
		case BOOKINGS_UPDATE_VIEW:
			return { ...state, currentView: action.payload };
		case SET_SELECTED_LOCATIONS:
			return { ...state, checkedselectedLocation: action.payload };
		case SET_DATETIME_SLOTS:
			return { ...state, dateTimeSlots: action.payload };
		case SET_DATE_TIME_TOGGLER:
			return { ...state, dateTime: action.payload };
		case SET_CHECKED_PACKAGES:
			return { ...state, checkedselectedPackages: action.payload };
		case SET_PACKAGE_UPSELLS_COLUMN_DATA:
			return { ...state, packageUpsellColumnData: action.payload };
		case SET_PACKAGE_UPSELLS_GRID_DATA:
			return { ...state, packageUpsellsGridData: action.payload };
		case SET_FOOD_ADD_ON_PRODUCTS:
			return { ...state, selectedFoodAddOnProducts: action.payload };
		case SET_DECORATIVE_ADD_ON_PRODUCTS:
			return { ...state, selcetedDecorativeAddOnProducts: action.payload };
		case SET_CHECKED_FOOD_ADD_ON_PRODUCTS:
			return { ...state, checkedFoodAddOnProducts: action.payload };
		case SET_CHECKED_DECORATIVE_ADD_ON_PRODUCTS:
			return { ...state, checkedDecorativeAddOnProducts: action.payload };
		case SET_CUSTOMERINFORMATION:
			return { ...state, customerInformation: action.payload };
		case SET_BOOKING_POLICY_INFO:
			return { ...state, bookingPolicyInfo: action.payload };
		case SET_BOOKING_SEQUENCE:
			return { ...state, bookingSequence: action.payload };
		case SET_CHECK_OUT:
			return { ...state, checkOut: action.payload };
		case RESET_BOOKINGS:
			return { ...initialState };

		// new flow
		case SET_FLOW_TYPE:
			return { ...state, bookingFlowtype: action.payload };
		case SET_GUEST_OF_HONOR_UI:
			return { ...state, guestOfHonordynamicUI: action.payload };
		case SET_CHECKED_STAFF:
			return { ...state, checkedSelectedStaff: action.payload };
		case SET_COLLECT_DATA:
			return { ...state, collectData: action.payload };
		case SET_ALL_ELEMENTS:
			return { ...state, allElements: action.payload };
		case SET_ORDER_CONFIG:
			return { ...state, orderConfig: action.payload };
		case SET_BOOKING_IMAGE_URL:
			return { ...state, bookingImage: action.payload };
		case SET_BOOKING_HOURS_IN_BOOKING_FLOW:
			return { ...state, bookingHours: action.payload };
		case SET_PAYMENT_CONFIG_IN_bOOKING_FLOW:
			return { ...state, paymentConfig: action.payload };
		default:
			return state;
	}
};

export default bookingsReducer;
