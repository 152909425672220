export const SET_BOOKING_NAME = 'SET_BOOKING_NAME';
export const BOOKINGS_UPDATE_VIEW = 'BOOKINGS_UPDATE_VIEW';
export const SET_BOOKING_DESCRIPTION = 'SET_BOOKING_DESCRIPTION';
export const SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS';
export const SET_DATETIME_SLOTS = 'SET_DATETIME_SLOTS';
export const SET_CUSTOMERINFORMATION = 'SET_CUSTOMERINFORMATION';
export const SET_LOCATION_ARRAY = 'SET_LOCATION_ARRAY';
export const SET_CHECKED_PACKAGES = 'SET_CHECKED_PACKAGES';
export const SET_PACKAGE_UPSELLS_COLUMN_DATA =
	'SET_PACKAGE_UPSELLS_COLUMN_DATA';
export const SET_PACKAGE_UPSELLS_GRID_DATA = 'SET_PACKAGE_UPSELLS_GRID_DATA';
export const RESET_BOOKINGS = 'RESET_BOOKINGS';
export const SET_FOOD_ADD_ON_PRODUCTS = 'SET_FOOD_ADD_ON_PRODUCTS';
export const SET_DECORATIVE_ADD_ON_PRODUCTS = 'SET_DECORATIVE_ADD_ON_PRODUCTS';
export const SET_CHECKED_FOOD_ADD_ON_PRODUCTS =
	'SET_CHECKED_FOOD_ADD_ON_PRODUCTS';
export const SET_CHECKED_DECORATIVE_ADD_ON_PRODUCTS =
	'SET_CHECKED_DECORATIVE_ADD_ON_PRODUCTS';
export const SET_BOOKING_POLICY_INFO = 'SET_BOOKING_POLICY_INFO';
export const SET_BOOKING_SEQUENCE = 'SET_BOOKING_SEQUENCE';
export const SET_CHECK_OUT = 'SET_CHECK_OUT';
export const SET_OPEN_FLOW_BUILDER = 'SET_OPEN_FLOW_BUILDER';
export const SET_DATE_TIME_TOGGLER = 'SET_DATE_TIME_TOGGLER';
export const SET_GUEST_OF_HONOR_UI = 'SET_GUEST_OF_HONOR_UI';

// new builder

export const SET_FLOW_TYPE = 'SET_FLOW_TYPE';
export const SET_CHECKED_STAFF = 'SET_CHECKED_STAFF';
export const SET_COLLECT_DATA = 'SET_COLLECT_DATA';
export const SET_ALL_ELEMENTS = 'SET_ALL_ELEMENTS';
export const SET_ORDER_CONFIG = 'SET_ORDER_CONFIG';
export const SET_BOOKING_IMAGE_URL = 'SET_BOOKING_IMAGE_URL';
export const SET_BOOKING_HOURS_IN_BOOKING_FLOW =
	'SET_BOOKING_HOURS_IN_BOOKING_FLOW';
export const SET_PAYMENT_CONFIG_IN_bOOKING_FLOW =
	'SET_PAYMENT_CONFIG_IN_bOOKING_FLOW';
