import React, { useEffect, useState } from 'react';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import { IAuth } from 'reducers/Authentication';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { resendEmailVerification } from 'firebaseApis/authentication';
import Toast, { ToastTypes } from 'components/ToastNotify';

function WelcomeScreen() {
	const history = useHistory();
	const authStoreData: IAuth = useSelector(
		(state: any) => state.authenticationStore
	);
	const [disableResendEmailButton, setDisableResendEmailButton] =
		useState(false);
	const user = authStoreData.userInfo;
	useEffect(() => {
		if (!user?.email || user?.email === undefined) {
			history.push('/');
		}
	}, []);

	const getIcon = () => {
		const { email } = user;
		let icon;
		if (email.includes('@gmail.com')) {
			icon = IconTypes.Email;
		} else if (email.includes('@yahoo.com')) {
			icon = IconTypes.Email;
		} else if (email.includes('@hotmail.com')) {
			icon = IconTypes.Email;
		} else if (email.includes('@outlook.com')) {
			icon = IconTypes.Email;
		} else if (email.includes('@mail.com')) {
			icon = IconTypes.Email;
		} else if (email.includes('@aol.com')) {
			icon = IconTypes.Email;
		} else if (email.includes('@icloud.com')) {
			icon = IconTypes.Email;
		} else if (email.includes('@me.com')) {
			icon = IconTypes.Email;
		} else {
			icon = IconTypes.Email;
		}
		return icon;
	};

	const resendMail = () => {
		resendEmailVerification(authStoreData.userInfo.currentUser)
			.then(() => {
				Toast({ title: 'Email sent successfully', type: ToastTypes.SUCCESS });
				setDisableResendEmailButton(true);
			})
			.catch(() => {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
			});
	};

	return (
		<div>
			{user?.email !== undefined && (
				<div className='flex flex-col w-full text-center items-center justify-center h-screen'>
					<Icon
						icon={getIcon()}
						className='text-8xl font-bold rounded-full p-4  mb-6 text-gray-700'
					/>
					<p className='font-bold text-5xl my-2 text-secondary-500'>
						Nice to meet you {user.fName}
					</p>
					{user.emailVerified ? (
						<p className='font-semibold text-xl mb-2 text-gray-darkest'>
							Congratulations , Your Email has been verified successfully , You can
							setup your account now
						</p>
					) : (
						<>
							<p className='font-semibold text-3xl my-10 text-gray-600'>
								We’ve sent you a welcome email at
								<span className='italic'> {user.email}</span>
							</p>
							<div className='flex flex-col pt-20 gap-10'>
								<h1
									className={` text-xl my-5 ${
										disableResendEmailButton && 'text-gray-400 pointer-events-none'
									}`}
									onClick={resendMail}
								>
									Didn't received the email yet ?{' '}
									<span className='underline cursor-pointer'>Click Here To Resend</span>
								</h1>
								<div className='flex flex-col'>
									<p className='text-xl'>
										Make sure you check your email to validate your account.
									</p>

									<p className='font-extrabold text-xl italic text-primary-600'>
										<span className='text-black'>Note: </span>If you don't get email.
										check other places it might be, like your junk, spam, social,
										promotions or other folders.
									</p>

									<p className='mb-2 mt-2 text-xl'>
										You won’t be able to use Leap360 until validation is complete.
									</p>
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
}

export default WelcomeScreen;
