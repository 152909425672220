

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';

export const DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

// default dollar '$' sign unicode
export const DEFAULT_CURRENCY_SYMBOL = '\u0024';

export const ALL_VIEWS = ["month", "week", "day", "agenda",]
