import { CreativesConstants } from 'constants/CreativesConstants';
import {
	RESET_POST,
	SET_CREATIVE_NAME,
	SET_CROP_IMAGE_URL,
	SET_FB_ACTION,
	SET_FB_FEELING,
	SET_FB_ICON,
	SET_FILE_INFO,
	SET_PAGE_TITLE,
	SET_POST_MESSAGE,
	SET_SHOW_PREVIEW,
	SET_PREVIEW_IMAGE,
	SET_SELECTED_CATEGORY,
	SET_SELECTED_PLATFORMS,
	SET_SELECTED_VIEW,
	SET_SOCIAL_CATEGORIES,
	SET_SOCIAL_POST_TYPE,
	SET_UPLOADED_IMAGE,
	SET_SOCIAL_CREATIVE,
	SET_ALL_PLATFORMS_FLAG,
	SET_POST_MESSAGES,
	SET_SOCIAL_EXTRAS,
	SET_GOOGLE_CTA_BUTTON_TYPE,
	SET_FB_CTA_BUTTON_TYPE,
	SET_FB_BUTTON_URL,
	SET_GOOGLE_BUTTON_URL,
	SET_GOOGLE_SUMMARY,
	SET_GOOGLE_OFFER_EXTRAS,
	RESET_SOCIAL_CREATIVE,
	SET_LINKEDIN_STATUS_EXTRAS,
	SET_SOCIAL_GRID_PAGE_NUM,
	SET_SOCIAL_GRID_VIEW,
	SET_GOOGLE_START_DATE,
	SET_GOOGLE_END_DATE,
	SET_GOOGLE_START_TIME,
	SET_GOOGLE_END_TIME,
	RESET_POST_TYPE,
	RESET_SELECTED_PLATFORMS,
	SET_INSTA_HASH_TAG,
	SET_SOCIAL_ACCOUNTS,
	SET_SOCIAL_SUBCATEGORIES,
	SET_SELECTED_SUBCATEGORY,
	SET_SOCIAL_MEDIA_TEMPLATE,
	SET_SOCIAL_SEARCH_TEXT,
	SET_SOCIAL_FILTER,
	SET_SOCIAL_CREATIVE_FILTER,
	SET_SOCIAL_SHOW_DELETE_MODAL,
	SET_SOCIAL_CREATIVES_FILTER,
	SET_SOCIAL_FIRST_VISIBLE,
	SET_SOCIAL_LAST_VISIBLE,
	SET_SOCIAL_PAGE_SIZE,
	SET_SOCIAL_PAGE_NUM,
	SET_SOCIAL_TOTAL_ROWS,
	SET_SOCIAL_TOTAL_PAGES,
	SET_SOCIAL_INTERNAL_CATEGORIES,
	SET_SOCIAL_CATEGORY_SAVE_ACTIONS,
	SET_SOCIAL_MAIN_CATEGORY_SAVE_ACTIONS,
	SET_SOCIAL_INTERNAL_SELECTED_CATEGORIES,
	SET_SOCIAL_ADD_CAT_DATA,
	SET_SOCIAL_DELETE_DATA,
	SET_SOCIAL_OPEN_MODAL,
	SET_SOCIAL_MODAL_TYPE,
	SET_SOCIAL_NEW_CATEGORY,
	SET_SOCIAL_FIILTER_MODAL,
	RESET_ALL_SOCIAL_FILTERS,
} from './CreativeActionTypes';

interface IMediaGrid {
	gridPageNum: number;
	gridView: boolean;
}
interface ISocial {
	socialPostCreativeID: number;
	postType: string;
	platforms: string[];
	view: number;
	messages: any;
	message: string;
	preview: boolean;
	pageTitle: string;
	uploadedImages: any;
	croppedImages: any;
	instaHashTag: any;
	fbFeelings: any;
	fbActions: any;
	fbIcons: any;
	fbFeeling: string;
	fbAction: string;
	fbIcon: string;
	fbButtonUrl: string;
	googleButtonUrl: string;
	googleSummary: string;
	googleStartDate: string;
	googleStartTime: string;
	googleEndDate: string;
	googleEndTime: string;
	fbCTAButtonTypes: any;
	googleCTAButtonTypes: any;
	googleCTAButtonType: string;
	fbCTAButtonType: string;
	categories: any;
	selectedCategory: any;
	creativeName: string;
	facebookFileInfo: any;
	googleFileInfo: any;
	instagramFileInfo: any;
	twitterFileInfo: any;
	linkedinFileInfo: any;
	allplatformsFileInfo: any;
	previewImages: any;
	allplatforms: boolean;
	googleOfferExtras: any;
	linkedinStatusExtras: any;
	socialAccounts: any;

	subCategories: any;
	selectedSubCategory: any;
}

interface ICreative {
	social: ISocial;
	mediaGrid: IMediaGrid;
	socialTemplate: {};
	socialFilters: {};
}

const initialState: ICreative = {
	socialTemplate: {},
	social: {
		socialPostCreativeID: 0,
		postType: 'STATUS',
		platforms: [],
		view: 0,
		messages: {
			Facebook: '',
			Google: '',
			Instagram: '',
			Twitter: '',
			Linkedin: '',
		},
		message: '',
		preview: true,
		uploadedImages: {},
		croppedImages: {},
		pageTitle: CreativesConstants.SELECT_POST_TYPE,
		fbFeelings: [],
		fbActions: [],
		fbIcons: [],
		fbCTAButtonTypes: [],
		googleCTAButtonTypes: [],
		instaHashTag: '',
		fbFeeling: '',
		fbAction: '',
		fbIcon: '',
		fbButtonUrl: '',
		googleButtonUrl: '',
		googleSummary: '',
		googleStartDate: '',
		googleStartTime: '',
		googleEndDate: '',
		googleEndTime: '',
		googleCTAButtonType: '',
		fbCTAButtonType: '',
		categories: [],
		selectedCategory: '',
		creativeName: '',
		facebookFileInfo: {},
		instagramFileInfo: {},
		twitterFileInfo: {},
		googleFileInfo: {},
		linkedinFileInfo: {},
		allplatformsFileInfo: {},
		previewImages: {},
		allplatforms: true,
		googleOfferExtras: { title: '', coupon: '', redeemUrl: '', terms: '' },
		linkedinStatusExtras: { title: '', url: '', text: '' },
		socialAccounts: [],
		subCategories: [],
		selectedSubCategory: '',
	},
	mediaGrid: { gridPageNum: 0, gridView: false },
	socialFilters: {
		gridView: false,
		searchText: '',
		filter: false,
		creative: {},
		showDeleteModal: false,
		socialCreatives: [],
		firstVisible: {},
		lastVisible: {},
		pageSize: 25,
		pageNum: 0,
		totalRows: 0,
		totalPages: 0,
		internalCategories: [],
		selectedInternalCategories: [],
		categorySaveActions: {},
		mainCategorySaveActions: {},
		addCatData: {},
		deleteData: {},
		openModal: false,
		modalType: '',
		newCategory: '',
		filterModal: {},
	},
};
let parentObject = {};
let keyFound = '';
const CreativesReducer = (state: any = initialState, action) => {
	switch (action.type) {
		case SET_SOCIAL_EXTRAS:
			return { ...state, social: { ...state.social, ...action.payload } };
		case SET_SOCIAL_POST_TYPE:
			return { ...state, social: { ...state.social, postType: action.payload } };
		case SET_SELECTED_PLATFORMS:
			return { ...state, social: { ...state.social, platforms: action.payload } };
		case SET_SELECTED_VIEW:
			return { ...state, social: { ...state.social, view: action.payload } };
		case SET_POST_MESSAGE:
			return { ...state, social: { ...state.social, message: action.payload } };
		case SET_POST_MESSAGES:
			parentObject = state.social.messages;
			keyFound = parentObject[Object.keys(action.payload)[0]];
			if (keyFound) {
				delete parentObject[Object.keys(action.payload)[0]];
			}
			parentObject = { ...parentObject, ...action.payload };
			return {
				...state,
				social: {
					...state.social,
					messages: { ...state.social.messages, ...parentObject },
				},
			};
		case SET_SHOW_PREVIEW:
			return { ...state, social: { ...state.social, preview: action.payload } };
		case SET_CROP_IMAGE_URL:
			parentObject = state.social.croppedImages;
			keyFound = parentObject[Object.keys(action.payload)[0]];
			if (keyFound) {
				delete parentObject[Object.keys(action.payload)[0]];
			}
			parentObject = { ...parentObject, ...action.payload };
			return {
				...state,
				social: {
					...state.social,
					croppedImages: { ...state.social.croppedImages, ...parentObject },
				},
			};
		case RESET_POST_TYPE:
			return {
				...state,
				social: { ...state.social, postType: '' },
			};
		case RESET_SELECTED_PLATFORMS:
			return {
				...state,
				social: { ...state.social, platforms: [] },
			};
		case RESET_POST:
			return {
				...state,
				social: {
					...state.social,
					messages: {
						Facebook: '',
						Google: '',
						Instagram: '',
						Twitter: '',
						Linkedin: '',
					},
					message: '',
					preview: true,
					uploadedImages: {},
					croppedImages: {},
					instaHashTag: '',
					fbFeeling: '',
					fbAction: '',
					fbIcon: '',
					fbButtonUrl: '',
					googleButtonUrl: '',
					googleSummary: '',
					googleStartDate: '',
					googleStartTime: '',
					googleEndDate: '',
					googleEndTime: '',
					googleCTAButtonType: '',
					fbCTAButtonType: '',
					selectedCategory: '',
					creativeName: '',
					facebookFileInfo: {},
					instagramFileInfo: {},
					twitterFileInfo: {},
					googleFileInfo: {},
					linkedinFileInfo: {},
					allplatformsFileInfo: {},
					previewImages: {},
					allplatforms: true,
					googleOfferExtras: { title: '', coupon: '', redeemUrl: '', terms: '' },
					linkedinStatusExtras: { title: '', url: '', text: '' },
				},
			};
		case SET_UPLOADED_IMAGE:
			parentObject = state.social.uploadedImages;
			keyFound = parentObject[Object.keys(action.payload)[0]];
			if (keyFound) {
				delete parentObject[Object.keys(action.payload)[0]];
			}
			parentObject = { ...parentObject, ...action.payload };
			return {
				...state,
				social: {
					...state.social,
					uploadedImages: { ...state.social.uploadedImages, ...parentObject },
				},
			};
		case SET_PAGE_TITLE:
			return {
				...state,
				social: { ...state.social, pageTitle: action.payload },
			};
		case SET_FB_ICON:
			return {
				...state,
				social: { ...state.social, fbIcon: action.payload },
			};
		case SET_FB_ACTION:
			return {
				...state,
				social: { ...state.social, fbAction: action.payload },
			};
		case SET_FB_FEELING:
			return {
				...state,
				social: { ...state.social, fbFeeling: action.payload },
			};
		case SET_INSTA_HASH_TAG:
			return {
				...state,
				social: { ...state.social, instaHashTag: action.payload },
			};
		case SET_GOOGLE_CTA_BUTTON_TYPE:
			return {
				...state,
				social: { ...state.social, googleCTAButtonType: action.payload },
			};
		case SET_FB_CTA_BUTTON_TYPE:
			return {
				...state,
				social: { ...state.social, fbCTAButtonType: action.payload },
			};
		case SET_FB_BUTTON_URL:
			return {
				...state,
				social: { ...state.social, fbButtonUrl: action.payload },
			};
		case SET_GOOGLE_SUMMARY:
			return {
				...state,
				social: { ...state.social, googleSummary: action.payload },
			};
		case SET_GOOGLE_START_DATE:
			return {
				...state,
				social: { ...state.social, googleStartDate: action.payload },
			};
		case SET_GOOGLE_START_TIME:
			return {
				...state,
				social: { ...state.social, googleStartTime: action.payload },
			};
		case SET_GOOGLE_END_DATE:
			return {
				...state,
				social: { ...state.social, googleEndDate: action.payload },
			};
		case SET_GOOGLE_END_TIME:
			return {
				...state,
				social: { ...state.social, googleEndTime: action.payload },
			};
		case SET_GOOGLE_BUTTON_URL:
			return {
				...state,
				social: { ...state.social, googleButtonUrl: action.payload },
			};
		case SET_GOOGLE_OFFER_EXTRAS:
			return {
				...state,
				social: {
					...state.social,
					googleOfferExtras: {
						...state.social.googleOfferExtras,
						...action.payload,
					},
				},
			};
		case SET_LINKEDIN_STATUS_EXTRAS:
			return {
				...state,
				social: {
					...state.social,
					linkedinStatusExtras: {
						...state.social.linkedinStatusExtras,
						...action.payload,
					},
				},
			};
		case SET_SOCIAL_CATEGORIES:
			return {
				...state,
				social: { ...state.social, categories: action.payload },
			};
		case SET_SELECTED_CATEGORY:
			return {
				...state,
				social: { ...state.social, selectedCategory: action.payload },
			};

		case SET_SOCIAL_SUBCATEGORIES:
			return {
				...state,
				social: { ...state.social, subCategories: action.payload },
			};
		case SET_SELECTED_SUBCATEGORY:
			return {
				...state,
				social: { ...state.social, selectedSubCategory: action.payload },
			};

		case SET_CREATIVE_NAME:
			return {
				...state,
				social: { ...state.social, creativeName: action.payload },
			};
		case SET_FILE_INFO:
			return {
				...state,
				social: { ...state.social, ...action.payload },
			};
		case SET_PREVIEW_IMAGE:
			parentObject = state.social.previewImages;
			keyFound = parentObject[Object.keys(action.payload)[0]];
			if (keyFound) {
				delete parentObject[Object.keys(action.payload)[0]];
			}
			parentObject = { ...parentObject, ...action.payload };
			return {
				...state,
				social: {
					...state.social,
					previewImages: { ...state.social.previewImages, ...parentObject },
				},
			};
		case SET_SOCIAL_CREATIVE:
			return { ...state, social: { ...state.social, ...action.payload } };
		case SET_ALL_PLATFORMS_FLAG:
			return {
				...state,
				social: { ...state.social, allplatforms: action.payload },
			};
		case RESET_SOCIAL_CREATIVE:
			return { ...state, social: initialState.social };
		case SET_SOCIAL_GRID_PAGE_NUM:
			return {
				...state,
				mediaGrid: { ...state.mediaGrid, gridPageNum: action.payload },
			};
		case SET_SOCIAL_ACCOUNTS:
			return {
				...state,
				social: { ...state.social, socialAccounts: action.payload },
			};
		case SET_SOCIAL_MEDIA_TEMPLATE:
			return {
				...state,
				socialTemplate: action.payload,
			};

		case SET_SOCIAL_GRID_VIEW:
			return {
				...state,
				socialFilters: { ...state.socialFilters, gridView: action.payload },
			};
		case SET_SOCIAL_SEARCH_TEXT:
			return {
				...state,
				socialFilters: { ...state.socialFilters, searchText: action.payload },
			};
		case SET_SOCIAL_FILTER:
			return {
				...state,
				socialFilters: { ...state.socialFilters, filter: action.payload },
			};
		case SET_SOCIAL_CREATIVE_FILTER:
			return {
				...state,
				socialFilters: { ...state.socialFilters, creative: action.payload },
			};
		case SET_SOCIAL_SHOW_DELETE_MODAL:
			return {
				...state,
				socialFilters: { ...state.socialFilters, showDeleteModal: action.payload },
			};
		case SET_SOCIAL_CREATIVES_FILTER:
			return {
				...state,
				socialFilters: { ...state.socialFilters, socialCreatives: action.payload },
			};
		case SET_SOCIAL_FIRST_VISIBLE:
			return {
				...state,
				socialFilters: { ...state.socialFilters, firstVisible: action.payload },
			};
		case SET_SOCIAL_LAST_VISIBLE:
			return {
				...state,
				socialFilters: { ...state.socialFilters, lastVisible: action.payload },
			};
		case SET_SOCIAL_PAGE_SIZE:
			return {
				...state,
				socialFilters: { ...state.socialFilters, pageSize: action.payload },
			};

		case SET_SOCIAL_PAGE_NUM:
			return {
				...state,
				socialFilters: { ...state.socialFilters, pageNum: action.payload },
			};

		case SET_SOCIAL_TOTAL_ROWS:
			return {
				...state,
				socialFilters: { ...state.socialFilters, totalRows: action.payload },
			};

		case SET_SOCIAL_TOTAL_PAGES:
			return {
				...state,
				socialFilters: { ...state.socialFilters, totalPages: action.payload },
			};

		case SET_SOCIAL_INTERNAL_CATEGORIES:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					internalCategories: action.payload,
				},
			};
		case SET_SOCIAL_CATEGORY_SAVE_ACTIONS:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					categorySaveActions: action.payload,
				},
			};
		case SET_SOCIAL_MAIN_CATEGORY_SAVE_ACTIONS:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					mainCategorySaveActions: action.payload,
				},
			};
		case SET_SOCIAL_INTERNAL_SELECTED_CATEGORIES:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					selectedInternalCategories: action.payload,
				},
			};
		case SET_SOCIAL_ADD_CAT_DATA:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					addCatData: action.payload,
				},
			};
		case SET_SOCIAL_DELETE_DATA:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					deleteData: action.payload,
				},
			};
		case SET_SOCIAL_OPEN_MODAL:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					openModal: action.payload,
				},
			};
		case SET_SOCIAL_MODAL_TYPE:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					modalType: action.payload,
				},
			};
		case SET_SOCIAL_NEW_CATEGORY:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					newCategory: action.payload,
				},
			};
		case SET_SOCIAL_FIILTER_MODAL:
			return {
				...state,
				socialFilters: {
					...state.socialFilters,
					filterModal: action.payload,
				},
			};
		case RESET_ALL_SOCIAL_FILTERS:
			return {
				...state,
				socialFilters: initialState.socialFilters,
			};

		default:
			return state;
	}
};

export default CreativesReducer;
