import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { BasicLogin, sendPasswordResetLink } from 'firebaseApis/authentication';
import Toast, { ToastTypes } from 'components/ToastNotify';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import InputTextField from 'storybook-mui/build/components/TextField';
import Button from 'storybook-mui/build/components/Button';

import {
	SetAuthenticationType,
	SetUserInfo,
} from 'reducers/Authentication/AuthActions';
import {
	BasicLoginToGetTwoWayAuthDetails,
	GetDocumentInfo,
} from 'firebaseApis/twoFactorAuthenticationFirebase';
import LinearProgressBar from 'components/LinearProgressBar';

import { signinSchema } from './validations/signupValidation';
import SocialLogins from './SocialLogins';
import { delay } from '../../helpers/utils';

export const Loginform = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
	const [passwordLinkSent, setPasswordLinkSent] = useState<any>(false);
	const [passwordVisibility, setPasswordVisibility] = useState(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);

	const closeProgressBar = async () => {
		setCurrentProgress(100);
		await delay(100);
		setLoading(false);
		setCurrentProgress(0);
	};

	const redirectToOrgOr2FAPage = async () => {
		const result = await GetDocumentInfo();
		if (
			result &&
			(result.isGoogleAuthVerfied === true || result.isSMSAuthVerfied === true)
		) {
			await closeProgressBar();

			history.push('/login2factorauthentication');
		} else {
			history.push('/signup2FA');
		}
	};

	const loginUser = async (values: any) => {
		try {
			setLoading(true);
			const result = await BasicLogin(values);
			const response = await BasicLoginToGetTwoWayAuthDetails(values);
			if (result.success) {
				Toast({ title: 'Login Successful', type: ToastTypes.SUCCESS });
				const dataForLocalStorage: any = {
					fName: result.data.FirstName,
					lName: result.data.LastName,
					email: result.data.Email,
					userId: result.data.User_Id,
					emailVerified: result.data.emailVerified,
					mobileType: result.data.select_Phone_Type,
					countryCode: result.data.phone_countryCode,
					phoneNumber: result.data.phoneNumber,
					landline_ext: result.data.landline_ext,
					email_optIn: result.data.email_optIn,
					sms_optIn: result.data.sms_optIn,
					voice_optIn: result.data.voice_optIn,
					isGoogleAuthEnabled: response.data.isGoogleAuthVerfied,
					isSMSAuthEnabled: response.data.isSMSAuthVerfied,
				};
				if (result.data.emailVerified) {
					dispatch(SetUserInfo(dataForLocalStorage));
					localStorage.setItem('user', JSON.stringify(dataForLocalStorage));
					redirectToOrgOr2FAPage();
				} else {
					dispatch(
						SetUserInfo({
							...dataForLocalStorage,
							currentUser: result.data.currentUser,
						})
					);
					await closeProgressBar();
					history.push('/verifyemail');
				}
				dispatch(SetAuthenticationType('LOGIN'));
			} else {
				await closeProgressBar();
				Toast({ title: result.error, type: ToastTypes.ERROR });
			}
		} catch (error) {
			await closeProgressBar();
			Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
		}
	};

	const loginForm = useFormik({
		validationSchema: signinSchema,
		validateOnChange: false,
		initialValues: {
			Email: '',
			Password: '',
		},
		onSubmit: async (values) => {
			loginUser(values);
		},
	});

	const sendForgotPasswordLink = () => {
		sendPasswordResetLink(loginForm.values.Email)
			.then((result) => {
				if (result.success) {
					setPasswordLinkSent(true);
					Toast({ title: 'Password Reset Link Sent', type: ToastTypes.SUCCESS });
				} else {
					Toast({ title: 'Email does not exists', type: ToastTypes.ERROR });
				}
			})
			.catch(() => {
				Toast({ title: 'Something went wrong', type: ToastTypes.ERROR });
				setPasswordLinkSent(false);
			});
	};

	const onBackClick = () => {
		setForgotPasswordClicked(false);
		loginForm.resetForm();
	};
	return (
		<>
			{loading && (
				<LinearProgressBar
					delay={6000}
					incrementBy={1}
					currentProgress={currentProgress}
					message='Please wait while we authenticate your account...'
				/>
			)}
			<div className='flex items-center'>
				<h1 className='text-sm text-gray-500'>New to leap360?</h1>
				<Button
					title={<p>Create an Account</p>}
					onClick={() => history.push('/signup')}
					variant='text'
					size='small'
					sx={{
						'&.MuiButton-root': {
							textDecoration: 'underline',
							color: 'orange',
							fontWeight: '700',
							textTransform: 'none',
						},
					}}
				/>
			</div>

			{!forgotPasswordClicked ? (
				<>
					<form
						className='flex mb-3 gap-4 flex-col mt-5'
						onSubmit={loginForm.handleSubmit}
					>
						<div className='flex flex-col w-full gap-4'>
							<InputTextField
								name='Email'
								fieldId='email'
								autoComplete='username'
								labelName='Email Address'
								value={loginForm.values.Email}
								valid={loginForm.errors.Email === undefined}
								errorMessage='Required'
								onChange={loginForm.handleChange}
							/>
							<InputTextField
								name='Password'
								autoComplete='current-password'
								type={passwordVisibility ? 'password' : 'text'}
								fieldId='password'
								labelName='Password'
								value={loginForm.values.Password}
								errorMessage='Required'
								valid={loginForm.errors.Password === undefined}
								onChange={loginForm.handleChange}
								adornmentMessage={
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => setPasswordVisibility(!passwordVisibility)}
										onMouseDown={(event) => event.preventDefault()}
									>
										{passwordVisibility ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								}
							/>
						</div>

						<Button title='Sign in' type='submit' />
					</form>
				</>
			) : (
				<div>
					{passwordLinkSent ? (
						<div className='w-full'>
							<h1
								className='hover:underline uppercase hover:text-gray-800 text-gray-700 cursor-pointer text-lg text-center'
								onClick={() => {
									setForgotPasswordClicked(false);
									setPasswordLinkSent(false);
								}}
							>
								Click Here To Login
							</h1>
							<h1 className='my-5 w-full lg:w-3/4 lg:mx-auto text-base text-green-400 text-center'>
								Password reset link sent to your email,
								<br /> Please check your mail and click on the verification link to
								reset your password
							</h1>
						</div>
					) : (
						<div className='w-full lg:w-1/2 mx-auto'>
							<h1 className='my-5 text-2xl text-center text-gray-600'>
								Enter your email address to send password reset mail{' '}
							</h1>
							<InputTextField
								name='Email'
								fieldId='email'
								autoComplete='username'
								labelName='Login email id'
								value={loginForm.values.Email}
								valid={loginForm.errors.Email === undefined}
								errorMessage='Required'
								onChange={loginForm.handleChange}
							/>
							<div className='mt-5 text-center flex justify-center gap-4'>
								<Button
									onClick={onBackClick}
									title='Back'
									startIcon={<Icon icon={IconTypes.Back} />}
								/>
								<Button
									onClick={() => sendForgotPasswordLink()}
									title='send'
									endIcon={<Icon icon={IconTypes.ArrowForwardIos} />}
									disabled={!loginForm.values.Email}
								/>
							</div>
						</div>
					)}
				</div>
			)}
			<p className='text-center text-sm text-gray-500'>or sign in with</p>
			<SocialLogins />
			<div className='flex flex-col lg:flex-row lg:items-center'>
				<p className='text-sm text-gray-500'>Need to find</p>
				<div className='flex items-center'>
					<Button
						title={<p>your password</p>}
						onClick={() => setForgotPasswordClicked(true)}
						variant='text'
						size='small'
						sx={{
							'&.MuiButton-root': {
								textDecoration: 'underline',
								color: 'orange',
								fontWeight: '700',
								textTransform: 'none',
							},
						}}
					/>
					<p className='text-sm text-gray-500'>?</p>
				</div>
			</div>
		</>
	);
};

export default Loginform;

export interface ILoginForm {
	setSelectedIndex: any;
}
