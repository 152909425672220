import React from 'react';
import LinearProgress, {
	LinearProgressProps,
} from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(
	props: LinearProgressProps & { value: number; textcolor?: string; }
) {
	const { value, textcolor } = props;
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant='determinate' {...props} />
			</Box>
			<Box className="box-progress-text-ct" style={{left: `${(value === 100? 50: value)}%`}}>
				<p className={`text-${textcolor} font-bold text-base`}>{`${Math.round(
					value
				)}%`}</p>
			</Box>
		</Box>
	);
}

 function WelcomeLinearProgressBar({
	color = 'info',
	message = '',
	textcolor = 'black',
	value = 10
}: ITextInfo) {

	return (
		
		<div className='w-full welcome-prog-bar'>
			<p className={`text-${textcolor} font-semibold text-lg italic`}>{message}</p>
			<LinearProgressWithLabel
				value={value}
				color={color}
				textcolor={textcolor}
			/>
		</div>
	);
}

export interface ITextInfo {
	delay?: number;
	message?: string;
	showBackDrop?: boolean;
	color?:
		| 'primary'
		| 'secondary'
		| 'warning'
		| 'success'
		| 'error'
		| 'info'
		| 'inherit';
	textcolor?: string;
	value?: number;
}

export default WelcomeLinearProgressBar;
