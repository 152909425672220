export const LeadsConstants = {
	ATTRIBUTES: 'attributes',
	ATTRIBUTES_FILTER: 'attributesFilter',
	PACKAGES_SELECTED_ATTRIBUTE: 'packagesSelectedAttribute',
	PACKAGES_SELECTED_PACKAGE: 'packagesSelectedPackage',
	PACKAGES_TERMS: 'packagesTerms',
	PACKAGES_PAYMENT: 'packagesPayment',
	relations: {
		isMissing: 'Is Missing',
		isPresent: 'Is Present',
		inRelation: 'In',
	},
};
