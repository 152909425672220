import {
	DYNAMIC_FORMULA_CREATION,
	SELECT_SCRATCH_TEMPLATE,
	DYNAMIC_FORMULA_CURRENT_POSITION,
	DYNAMIC_VARIABLE_QRCODE_PROPERTIES,
	EMOJI_GROUPS,
	EMOJIS,
	SHOW_SECTIONS,
	SHOW_ROWS,
	SHOW_CELLS,
	OPEN_STRUCTURE_SIDE_BAR,
	OPEN_Leap360_SIDE_BAR,
	OPEN_Blocks_SIDE_BAR,
	OPEN_ORG_SIDE_BAR,
	OPEN_HEADER_SIDE_BAR,
	OPEN_FOOTER_SIDE_BAR,
	DYNAMIC_VARIABLE_EDITOR_VAL,
} from './EmailBuilderActionTypes';

interface IBuilder {
	selectedTemplate: {
		TemplateId: string;
		data: any;
	};
	DynamicFormula: string;
	DynamicFormulaCurrentPosition: {
		curserPosition: number;
		type: string;
	};
	DynamicVariableQRCode: string;
	Emojis: {
		data: any;
		groups: any;
	};
	sectionActive: boolean;
	rowActive: boolean;
	cellActive: boolean;

	StructureSidebarActive: boolean;
	Leap360SidebarActive: boolean;
	OrgSidebarActive: boolean;
	HeaderSidebarActive: boolean;
	FooterSidebarActive: boolean;
	BlocksSidebarActive: boolean;
	dynamicVariableEditorVal: string;
}

const initialState: IBuilder = {
	selectedTemplate: {
		TemplateId: '',
		data: '',
	},
	DynamicFormula: '',
	DynamicFormulaCurrentPosition: {
		curserPosition: 0,
		type: '',
	},
	DynamicVariableQRCode: '',
	Emojis: {
		data: [],
		groups: [],
	},
	sectionActive: false,
	rowActive: false,
	cellActive: false,
	StructureSidebarActive: false,
	Leap360SidebarActive: false,
	OrgSidebarActive: false,
	BlocksSidebarActive: true,
	HeaderSidebarActive: false,
	FooterSidebarActive: false,
	dynamicVariableEditorVal: '',
};

const emailBuilder = (state: any = initialState, action): IBuilder => {
	switch (action.type) {
		case SELECT_SCRATCH_TEMPLATE:
			return { ...state, selectedTemplate: action.payload };
		case DYNAMIC_FORMULA_CREATION:
			return { ...state, DynamicFormula: action.payload };
		case DYNAMIC_FORMULA_CURRENT_POSITION:
			return { ...state, DynamicFormulaCurrentPosition: action.payload };
		case DYNAMIC_VARIABLE_QRCODE_PROPERTIES:
			return { ...state, DynamicVariableQRCode: action.payload };
		case EMOJI_GROUPS:
			return { ...state, Emojis: action.payload };
		case EMOJIS:
			return { ...state, Emojis: action.payload };

		case SHOW_SECTIONS:
			return { ...state, sectionActive: action.payload };
		case SHOW_ROWS:
			return { ...state, rowActive: action.payload };
		case SHOW_CELLS:
			return { ...state, cellActive: action.payload };
		case OPEN_STRUCTURE_SIDE_BAR:
			return { ...state, StructureSidebarActive: action.payload };
		case OPEN_Leap360_SIDE_BAR:
			return { ...state, Leap360SidebarActive: action.payload };
		case OPEN_ORG_SIDE_BAR:
			return { ...state, OrgSidebarActive: action.payload };
		case OPEN_HEADER_SIDE_BAR:
			return { ...state, HeaderSidebarActive: action.payload };
		case OPEN_FOOTER_SIDE_BAR:
			return { ...state, FooterSidebarActive: action.payload };

		case OPEN_Blocks_SIDE_BAR:
			return { ...state, BlocksSidebarActive: action.payload };

		case DYNAMIC_VARIABLE_EDITOR_VAL:
			return {
				...state,
				dynamicVariableEditorVal: action.payload,
			};

		default:
			return state;
	}
};

export default emailBuilder;
