import {
	getClientDB,
	getOrganisationIdFirebase,
	create,
	siteConfigConstants,
	getUserName,
	getXProjKey,
	createInstanceWithArgs,
	createCloudWithProjKey,
	// getUser,
	// setUser,
} from 'api/AxiosManager';
import {
	collection,
	getDocs,
	limit,
	query,
	startAfter,
	limitToLast,
	deleteDoc,
	doc,
	updateDoc,
	increment,
	getDoc,
	addDoc,
	setDoc,
	endBefore,
	where,
	orderBy,
	Timestamp,
} from 'firebase/firestore';
// import { masterDb } from 'firebaseConfig';
import { isEqual } from 'lodash';
import moment from 'moment';
import { IPage } from 'views/Dynamic/Common/interfaces';

export const GetDocumentsForDynamicModule = async ({
	firstDocument,
	lastDocument,
	rowsPerPage,
	type,
	moduleName,
	path,
	whereConditions = [],
}) => {
	try {
		let querySnapshots: any;
		const finalPath = path ?? `${moduleName}/${moduleName}`;
		const finalWhereConditions = [];
		whereConditions.forEach((condition: any) => {
			finalWhereConditions.push(
				where(condition.field, condition.operator, condition.value)
			);
		});
		if (type === 'next') {
			const qry = query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/${finalPath}`),
				...finalWhereConditions,
				orderBy('Updated_At_TimeStamp', 'desc'),
				startAfter(lastDocument),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else if (type === 'prev') {
			const qry = query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/${finalPath}`),
				...finalWhereConditions,
				orderBy('Updated_At_TimeStamp', 'desc'),
				endBefore(firstDocument),
				limitToLast(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		} else {
			const qry = query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/${finalPath}`),
				...finalWhereConditions,
				orderBy('Updated_At_TimeStamp', 'desc'),
				limit(rowsPerPage)
			);
			querySnapshots = await getDocs(qry);
		}

		const resultArray = querySnapshots.docs
			.filter((d: any) => d?.data()?.Display !== false)
			.map((document: any, index: number) => ({
				id: document.id,
				rowIndex: index,
				...document.data(),
				//	...document.data()?.GroupedFieldsValues,
			}));

		return {
			data: resultArray,
			firstDocument: querySnapshots.docs[0],
			lastDocument: querySnapshots.docs[querySnapshots.docs.length - 1],
			success: true,
		};
	} catch (error) {
		return {
			data: [],
			success: false,
			error,
		};
	}
};

export const DeleteDynamicModuleDocument = async ({
	documentId,
	moduleName,
}: any) => {
	try {
		const result = await deleteDoc(
			doc(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/${moduleName}/${moduleName}`,
				documentId
			)
		);
		const instance = create();

		await instance.delete(
			`${
				siteConfigConstants().REACT_APP_DYNAMIC_SEARCH
			}/deleteRow?organization_id=${getOrganisationIdFirebase()}&docid=${documentId}&dataset_name=${moduleName}`
		);

		return result;
	} catch (error) {
		return error;
	}
};

export const UpdateDynamicModuleDocumentsCount = async (
	value: any,
	moduleName: string,
	clientDB = getClientDB(),
	organizationId = getOrganisationIdFirebase()
) => {
	try {
		const result = await updateDoc(doc(clientDB, `${organizationId}`, 'Counts'), {
			[moduleName]: increment(value),
		});
		return result;
	} catch (err) {
		return null;
	}
};
export const DeleteUserProfile = async (documentId, moduleName) => {
	try {
		const taskDocRef = getClientDB()
			.collection(`/${getOrganisationIdFirebase()}/${moduleName}/${moduleName}`)
			.where('Associate_User', '==', documentId);

		taskDocRef.get().then((querySnapshot) => {
			querySnapshot.forEach((docs) => {
				docs.ref.delete();
			});
		});

		await UpdateDynamicModuleDocumentsCount(-1, 'User_Profiles');

		const instance = create();

		await instance.delete(
			`${
				siteConfigConstants().REACT_APP_DYNAMIC_SEARCH
			}/deleteRow?organization_id=${getOrganisationIdFirebase()}&docid=${documentId}&dataset_name=${moduleName}`
		);

		return {};
	} catch (error) {
		return error;
	}
};

export const GetCounts = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts')
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const SaveDynamicModuleDocument = async ({
	documentName,
	moduleName,
	primaryKeyFields,
	payload,
	orgId = getOrganisationIdFirebase(),
	clientDB = getClientDB(),
	reactAppBaseUrl = siteConfigConstants().REACT_APP_DYNAMIC_SEARCH,
	xProjKey = getXProjKey(),
	appAuthorization = siteConfigConstants().REACT_APP_AUTHORIZATION,
}) => {
	try {
		// check if the document already exists

		if (primaryKeyFields.length > 0) {
			// eslint-disable-next-line no-restricted-syntax
			for await (const field of primaryKeyFields) {
				if (
					payload[field] !== undefined &&
					payload[field] !== null &&
					payload[field] !== ''
				) {
					const resultSnapshots = await getDocs(
						query(
							collection(clientDB, `/${orgId}/${moduleName}/${moduleName}`),
							where(field, '==', payload[field])
						)
					);
					if (resultSnapshots.docs.length > 0) {
						return {
							message: `${documentName} already exists`,
							success: false,
						};
					}
				}
			}
		}

		let result = null;

		if (moduleName === 'User_Profiles') {
			await setDoc(
				doc(
					clientDB,
					`/${orgId}/${moduleName}/${moduleName}`,
					payload.Associate_User
				),
				payload
			);
			result = { id: payload.Associate_User };
		} else {
			result = await addDoc(
				collection(clientDB, `/${orgId}/${moduleName}/${moduleName}`),
				payload
			);
		}

		const instance = createInstanceWithArgs(
			reactAppBaseUrl,
			appAuthorization,
			xProjKey,
			orgId
		);
		await instance.post(
			`${reactAppBaseUrl}/insertSingleRow?organization_id=${orgId}&docid=${result.id}&dataset_name=${moduleName}`
		);

		return {
			message: 'Contact added successfully',
			success: true,
			id: result.id,
		};
	} catch (error) {
		return {
			error,
			message: error && error.message ? error.message : 'Error adding contact',
			success: false,
		};
	}
};

export const GetDynamicModuleFormJSON = async (moduleName: string) => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, `${moduleName}_UI`)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const GetDynamicModuleDocumentById = async (
	documentId: any,
	moduleName: any,
	subCollections: any
) => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/${moduleName}/${moduleName}`,
				documentId
			)
		);
		const mainDocumentData = docResult.data();
		let subCollectionsData = {};
		await Promise.all(
			subCollections.map(async (subCollection: any) => {
				const qry = query(
					collection(
						getClientDB(),
						`${getOrganisationIdFirebase()}/${moduleName}/${moduleName}/${documentId}/${subCollection}`
					)
				);
				const subCollectionData = await getDocs(qry);
				const subCollectionResultArray = subCollectionData.docs.map(
					(document: any) => ({
						id: document.id,
						...document.data(),
					})
				);
				subCollectionsData = {
					...subCollectionsData,
					[subCollection]: subCollectionResultArray,
				};
			})
		);

		return { ...mainDocumentData, ...subCollectionsData };
	} catch (error) {
		return error;
	}
};

export const UpdateDynamicModuleDocument = async ({
	documentId,
	moduleName,
	primaryKeyFields,
	payload,
	documentName,
}) => {
	try {
		let alreadyAxists = false;
		const primaryKeyFieldsWithValues = primaryKeyFields.filter(
			(field: any) => field !== ''
		);
		if (primaryKeyFieldsWithValues?.length > 0) {
			const resultSnapshots = await getDocs(
				query(
					collection(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/${moduleName}/${moduleName}`
					),
					where(primaryKeyFields[0], '==', payload[primaryKeyFieldsWithValues[0]])
				)
			);
			if (
				resultSnapshots.docs.length > 0 &&
				resultSnapshots.docs[0].id !== documentId
			) {
				alreadyAxists = true;
			}
		}

		if (alreadyAxists) {
			return {
				message: `${documentName} already exists`,
				success: false,
			};
		}
		if (moduleName === 'User_Profiles') {
			// const payloadForUsersInClient = {
			// 	FirstName: payload.First_Name || '',
			// 	LastName: payload.Last_Name || '',
			// 	phoneNumber: payload.Phone_Number || '',
			// 	phone_countryCode: payload.Phone_Country_Code || '',
			// 	Updated_At: moment().toString(),
			// 	Updated_At_TimeStamp: Timestamp.now(),
			// };
			const payloadForUser = {
				...payload,
				Billing_Status: payload.Is_Active
					? payload.Is_Active
					: payload.Billing_Status,
				FirstName: payload.First_Name || '',
				LastName: payload.Last_Name || '',
				Updated_On: moment().toString(),
				Updated_At: moment().toString(),
				Updated_At_TimeStamp: Timestamp.now(),
				Updated_By: getUserName(),
			};
			// await updateDoc(
			// 	doc(masterDb, 'Users', payload?.User_Id),
			// 	payloadForUsersInClient
			// );
			await updateDoc(
				doc(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/User_Profiles/User_Profiles/${
						payload?.User_Id
					}`
				),

				payloadForUser
			);
			// const local = getUser();
			// if (local?.userId === payload?.Associate_User) {
			// 	setUser({
			// 		...local,
			// 		fName: payload.First_Name || '',
			// 		lName: payload.Last_Name || '',
			// 	});
			// }
		} else {
			await updateDoc(
				doc(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/${moduleName}/${moduleName}`,
					documentId
				),
				{
					...payload,
					Updated_At: moment().toString(),
					Updated_At_TimeStamp: Timestamp.now(),
					Updated_By: getUserName(),
				}
			);
		}

		const instance = create();

		await instance.post(
			`${
				siteConfigConstants().REACT_APP_DYNAMIC_SEARCH
			}/updateRow?organization_id=${getOrganisationIdFirebase()}&docid=${documentId}&dataset_name=${moduleName}`
		);

		return {
			message: `${moduleName} updated successfully`,
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const getDynamicGridData = async (
	Grid_Rows_Path,
	fieldName?,
	fieldValue = undefined,
	fieldOperator = undefined
) => {
	try {
		let rowsQry: any;
		if (fieldName && fieldValue !== undefined && fieldOperator !== undefined)
			rowsQry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/${Grid_Rows_Path}`
				),
				where(fieldName, fieldOperator || '==', fieldValue)
			);
		else
			rowsQry = query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/${Grid_Rows_Path}`
				)
			);
		const rowsResponse = await getDocs(rowsQry);
		const rowsResultArray = rowsResponse.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return {
			data: rowsResultArray.filter((item) => item.Display !== false),
		};
	} catch (error) {
		return [];
	}
};

export const getDynamicOptionsForSelectField = async (
	path: string,
	valueKey: string,
	labelKey: string
) => {
	try {
		const qry = query(
			collection(getClientDB(), `/${getOrganisationIdFirebase()}/${path}`)
		);
		const response = await getDocs(qry);
		const resultArray = response.docs.map((document) => ({
			id: document.id,
			...document.data(),
		}));
		const selectOptions = resultArray.map(
			(item: any) =>
				item.Display !== false && {
					label: item[valueKey],
					value: item[labelKey],
				}
		);
		return selectOptions;
	} catch (error) {
		return [];
	}
};

export const getDynamicGridDataWithApiEndpoint = async (
	endpoint: any,
	payloadKey: any,
	payloadValue: any
) => {
	try {
		const instance = create();
		const response = await instance.post(
			`${siteConfigConstants().REACT_APP_BASE_URL}/${endpoint}`,
			{
				organization_id: getOrganisationIdFirebase(),
				[payloadKey]: payloadValue,
				numPerPage: 10,
				page: 1,
			}
		);
		const resultData = response.data.results.map((item: any) => ({
			id: item.document_id,
			...item.document,
		}));
		return resultData;
	} catch (error) {
		return [];
	}
};

export const getDynamicOptionsForAutoSelectField = async (
	path: any,
	whereClause: any
) => {
	try {
		let qry: any;
		if (whereClause) {
			qry = query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/${path}`),
				where(whereClause.FieldName, '==', whereClause.FieldValue)
			);
		} else {
			qry = query(
				collection(getClientDB(), `/${getOrganisationIdFirebase()}/${path}`)
			);
		}

		const response: any = await getDocs(qry);
		const resultArray: any = response.docs.map((document: any) => ({
			id: document.id,
			...document.data(),
		}));

		return resultArray.filter((item: any) => item?.Display !== false);
	} catch (error) {
		return [];
	}
};

export const CheckDocumnetExistsOrNot = async (
	primaryKeyFields: any,
	moduleName: string
) => {
	try {
		const conditions = primaryKeyFields.map((item: any) =>
			where(item.fieldName, '==', item.fieldValue)
		);
		const qry = query(
			collection(
				getClientDB(),
				`/${getOrganisationIdFirebase()}/${moduleName}/${moduleName}`
			),
			...conditions
		);
		const response = await getDocs(qry);
		if (response.size > 0) {
			return {
				message: 'Contact already exists',
				data: {
					id: response.docs[0].id,
					...response.docs[0].data(),
				},
				success: true,
			};
		}
		return {
			message: 'Contact does not exists',
			data: null,
		};
	} catch (error) {
		return {
			message: 'Contact does not exists',
		};
	}
};

export const GetSidebarMenu = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, `Sidebar_UI`)
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const GetDefaultDynamicJSONSamples = async () => {
	try {
		const resultSnapshots = await getDocs(
			query(
				collection(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/Dynamic_JSON_Samples/Dynamic_JSON_Samples`
				)
			)
		);
		const resultArray = resultSnapshots.docs.map((document) => ({
			id: document.id,
			...document.data(),
		}));
		return resultArray;
	} catch (error) {
		return error;
	}
};

export const CreateNewDynamicModule = async (moduleDetails: any) => {
	try {
		const moduleUIJSON: IPage = {
			...moduleDetails.DefaultJSON,
			OptInType: moduleDetails.OptInType,
			PageTitleOnAdd: `Add ${moduleDetails.DocumentName}`,
			PageTitleOnEdit: `Edit ${moduleDetails.DocumentName}`,
			DocumentName: moduleDetails.DocumentName,
			DisableTabsClick: true,
			TabLevelValidation: true,
			RunCamapigns: moduleDetails.RunCampaigns,
		};

		// creating ui json
		await setDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}`,
				`${moduleDetails.ModuleName.replaceAll(' ', '_')}_UI`
			),
			moduleUIJSON
		);

		// creaing table in bigquery
		const instance = create();
		await instance.post(
			`${
				siteConfigConstants().REACT_APP_DYNAMIC_SEARCH
			}/createDataSet?organization_id=${getOrganisationIdFirebase()}&dataset_name=${moduleDetails.ModuleName.replaceAll(
				' ',
				'_'
			)}`
		);

		// getting existing dynamic modules list
		const existingDynamicModules: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'DynamicModules')
		);

		// adding new dynamic module to the list
		const payload = {
			data: [
				...existingDynamicModules.data().data,
				{
					moduleName: moduleDetails.ModuleName.replaceAll(' ', '_'),
					moduleJSONPath: `${moduleDetails.ModuleName.replaceAll(' ', '_')}_UI`,
					allowCampaigns: moduleDetails.RunCampaigns,
					moduleDescription: moduleDetails.ModuleDescription,
					moduleDisplayName: moduleDetails.ModuleDisplayName,
					MenuName: moduleDetails.Menu,
					deleteInAdmin: true,
					routePath: `Dynamic/${
						moduleDetails.Menu
					}/${moduleDetails.ModuleName.replaceAll(' ', '_')}'`,
				},
			],
		};
		await setDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'DynamicModules'),
			payload
		);

		// creating count property for the dynamic module
		const countsResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts')
		);
		const existingCounts = countsResult.data();
		await setDoc(doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Counts'), {
			...existingCounts,
			[moduleDetails.ModuleName.replaceAll(' ', '_')]: 0,
		});

		// creating dynamic module
		await setDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'Sidebar_UI'),
			{
				menu: moduleDetails.NewSideBar,
			}
		);

		return {
			message: 'Module created successfully',
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const GetAllDynamicModules = async () => {
	try {
		const docResult: any = await getDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}`, 'DynamicModules')
		);
		return docResult.data();
	} catch (error) {
		return error;
	}
};

export const AddDynamicModuleDataToDocumentSubCollection = async ({
	moduleName,
	documentId,
	data,
	subCollectionName,
}) => {
	try {
		data.forEach(async (item: any) => {
			await setDoc(
				doc(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/${moduleName}/${moduleName}/${documentId}/${subCollectionName}/${
						item.Id
					}`
				),
				item
			);
		});
		return {
			message: 'Data added successfully',
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const UpdateDynamicModuleDataToDocumentSubCollection = async ({
	moduleName,
	documentId,
	subCollectionName,
	data,
	existingData,
}) => {
	try {
		data.forEach(async (item: any) => {
			const existingItem = existingData.find((it: any) => it.Id === item.Id);
			delete existingItem?.id;
			if (
				(!isEqual(existingItem, item) && existingItem?.Id === item.Id) ||
				existingItem?.Id !== item.Id ||
				existingItem === undefined
			) {
				await setDoc(
					doc(
						getClientDB(),
						`/${getOrganisationIdFirebase()}/${moduleName}/${moduleName}/${documentId}/${subCollectionName}/${
							item.Id
						}`
					),
					item
				);
			}
		});

		const deletedIds = [];
		existingData.forEach((item): any => {
			const existingItem = data.find((it: any) => it.Id === item.Id);
			if (!existingItem) {
				deletedIds.push(item.Id);
			}
		});
		deletedIds.forEach(async (id: any) => {
			await deleteDoc(
				doc(
					getClientDB(),
					`/${getOrganisationIdFirebase()}/${moduleName}/${moduleName}/${documentId}/${subCollectionName}/${id}`
				)
			);
		});
		return {
			message: 'Data added successfully',
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const AddDocumentDataToDynamicModuleSubCollection = async ({
	moduleName,
	documentId,
	subCollectionName,
	data,
}) => {
	try {
		await setDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}`,
				`${moduleName}/${moduleName}/${documentId}/${subCollectionName}/${data.Id}`
			),
			data
		);
		return {
			message: 'Data added successfully',
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const UpdateDocumentDataToDynamicModuleSubCollection = async ({
	moduleName,
	documentId,
	subCollectionName,
	data,
	existingData,
}) => {
	try {
		const existingItem = existingData;
		existingData.Id = data.Id;
		delete existingItem?.id;
		if (!isEqual(existingData, data)) {
			await setDoc(
				doc(
					getClientDB(),
					`${getOrganisationIdFirebase()}`,
					`${moduleName}/${moduleName}/${documentId}/${subCollectionName}/${data.Id}`
				),
				data
			);
		}
		return {
			message: 'Data added successfully',
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const CopyDynamicModuleDocument = async ({
	fromModule,
	toModule,
	documentData,
}) => {
	try {
		const docResult: any = await getDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}`,
				`${fromModule}/${fromModule}/${documentData.id}`
			)
		);

		const finalDocumentData = docResult.data();

		await addDoc(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}`,
				`${toModule}/${toModule}`
			),
			finalDocumentData
		);
		return {
			message: 'Data added successfully',
			success: true,
		};
	} catch (error) {
		return error;
	}
};

export const GetDynamicModuleAssociateDocuments = async (payload: any) => {
	try {
		const instance = create();
		const response: any = await instance.post(
			`${
				siteConfigConstants().REACT_APP_DYNAMIC_SEARCH
			}/getRelationalDataInformationPagenation`,
			payload
		);
		return response.data;
	} catch (error) {
		return error;
	}
};

// For Locations

export const updateparticularField = async (module_name, docId, payLoad) => {
	try {
		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/${module_name}/${module_name}`,
				`${docId}`
			),
			{
				...payLoad,
			}
		);
		return true;
	} catch (error) {
		return error;
	}
};

export const GetWebsiteLoginUserInfo = async () => {
	try {
		const payload = {
			userid: '',
			useremail: '',
			userphone: '+919502609898',
		};
		const instance = createCloudWithProjKey();
		const response: any = await instance.post(
			`https://client-authenticated-users-rodjic744a-uw.a.run.app/verifyauthUser`,
			payload
		);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const dynamicQuickSearch = async (data) => {
	try {
		const {
			dataSet,
			numPerPage,
			page,
			searchval,
			docPath,
			apiEndPoint,
			searchvaltype,
		} = data;
		const orgId = getOrganisationIdFirebase();
		const payload = {
			orgid: orgId,
			dataSet,
			numPerPage,
			page,
			searchval,
			docpath: `${orgId}/${docPath}`,
			...(apiEndPoint === 'flatQuickSearch' && {
				searchvaltype,
			}),
		};
		const instance = createCloudWithProjKey();
		const response: any = await instance.post(
			`${siteConfigConstants().REACT_APP_DYNAMIC_SEARCH}/${apiEndPoint}`,

			payload
		);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const getPreviousOrNextRecordFromFirestore = async (data) => {
	try {
		const { fieldVal, type, moduleName } = data;
		const orgId = getOrganisationIdFirebase();
		const db = getClientDB();
		const collectionRef = db.collection(`${orgId}/${moduleName}/${moduleName}`);
		const currentDocSnapshot = await collectionRef.doc(fieldVal).get();

		let qry;
		if (type === 'prev') {
			qry = collectionRef
				.orderBy('Updated_At_TimeStamp', 'desc')
				.endBefore(currentDocSnapshot)
				.limitToLast(1);
		} else if (type === 'next') {
			qry = collectionRef
				.orderBy('Updated_At_TimeStamp', 'desc')
				.startAfter(currentDocSnapshot)
				.limit(1);
		}

		const snapshot = await qry.get();
		const documents = snapshot.docs;
		let result;
		if (documents.length > 0) {
			const document = documents[0];
			result = { ...document.data(), id: document.id };
		}
		return result;
	} catch (error) {
		return error;
	}
};
