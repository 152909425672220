export const UPDATE_SOCIAL_TYPE = 'UPDATE_SOCIAL_TYPE';
export const UPDATE_SOCIAL_VIEW = 'UPDATE_SOCIAL_VIEW';
export const UPDATE_SOCIAL_ITEM = 'UPDATE_SOCIAL_ITEM';
export const UPDATE_SOCIAL_NAME = 'UPDATE_SOCIAL_NAME';
export const UPDATE_SOCIAL_DESCRIPTION = 'UPDATE_SOCIAL_DESCRIPTION';
export const UPDATE_AUDEIENCE = 'UPDATE_AUDEIENCE';
export const UPDATE_SOCIAL_ACCOUNTS = 'UPDATE_SOCIAL_ACCOUNTS';
export const UPDATE_SOCIAL_CAMPAIGN_PLATFORMS =
	'UPDATE_SOCIAL_CAMPAIGN_PLATFORMS';
export const UPDATE_SOCIAL_FREQUENCY = 'UPDATE_SOCIAL_FREQUENCY';
export const UPDATE_SOCIAL_INTERVAL = 'UPDATE_SOCIAL_INTERVAL';
export const UPDATE_SOCIAL_DAY = 'UPDATE_SOCIAL_DAY';

export const UPDATE_SOCIAL_SCHEDULE_DATE = 'UPDATE_SOCIAL_SCHEDULE_DATE';
export const UPDATE_SOCIAL_SCHEDULE_TIME = 'UPDATE_SOCIAL_SCHEDULE_TIME';

export const UPDATE_SOCIAL_END_DATE = 'UPDATE_SOCIAL_END_DATE';
export const UPDATE_SOCIAL_END_TIME = 'UPDATE_SOCIAL_END_TIME';
export const UPDATE_SOCIAL_TIMEZONE = 'UPDATE_SOCIAL_TIMEZONE';
export const UPDATE_SOCIAL_OCCASION = 'UPDATE_SOCIAL_OCCASION';
export const UPDATE_SOCIAL_SCHEDULE_TYPE = 'UPDATE_SOCIAL_SCHEDULE_TYPE';
export const UPDATE_SOCIAL_OCCASION_ADDITIONALDATA =
	'UPDATE_SOCIAL_OCCASION_ADDITIONALDATA';
export const UPDATE_SOCIAL_OCCASION_DAY = 'UPDATE_SOCIAL_OCCASION_DAY';
export const UPDATE_SOCIAL_OCCASION_TIME = 'UPDATE_SOCIAL_OCCASION_TIME';
export const UPDATE_SOCIAL_SCHEDULE_TIMEZONE =
	'UPDATE_SOCIAL_SCHEDULE_TIMEZONE';
export const UPDATE_SOCIAL_CREATIVEID = 'UPDATE_SOCIAL_CREATIVEID';
export const UPDATE_SOCIAL_CAMPAIGN_CREATIVE_EDIT =
	'UPDATE_SOCIAL_CAMPAIGN_CREATIVE_EDIT';
export const UPDATE_SOCIAL_CAMPAIGN_POST_TYPE =
	'UPDATE_SOCIAL_CAMPAIGN_POST_TYPE';

export const RESET_SOCIAL_CAMPAIGN_POST = 'RESET_SOCIAL_CAMPAIGN_POST';

export const UPDATE_SOCIAL_CAMPAIGN_CREATIVE_CAN_EDIT =
	'UPDATE_SOCIAL_CAMPAIGN_CREATIVE_CAN_EDIT';
export const UPDATE_SOCIAL_SELECT_PLATFORM = 'UPDATE_SOCIAL_SELECT_PLATFORM';
export const UPDATE_SOCIAL_CAMPAIGN_ACCOUNTS =
	'UPDATE_SOCIAL_CAMPAIGN_ACCOUNTS';

export interface UPDATE_SOCIAL_TYPE {
	type: typeof UPDATE_SOCIAL_TYPE;
	campaignType: string;
}
export interface UPDATE_SOCIAL_VIEW {
	type: typeof UPDATE_SOCIAL_VIEW;
	currentView: number;
}
export interface UPDATE_SOCIAL_CREATIVEID {
	type: typeof UPDATE_SOCIAL_CREATIVEID;
	socialCreativeId: string;
}
export interface UPDATE_SOCIAL_CAMPAIGN_CREATIVE_EDIT {
	type: typeof UPDATE_SOCIAL_CAMPAIGN_CREATIVE_EDIT;
	isSocialCampaignCreativeEdit: boolean;
}
export interface UPDATE_SOCIAL_CAMPAIGN_CREATIVE_CAN_EDIT {
	type: typeof UPDATE_SOCIAL_CAMPAIGN_CREATIVE_CAN_EDIT;
	isSocialCampaignCreativeCanEdit: string;
}
export interface RESET_SOCIAL_CAMPAIGN_POST {
	type: typeof RESET_SOCIAL_CAMPAIGN_POST;
}
export interface UPDATE_SOCIAL_CAMPAIGN_POST_TYPE {
	type: typeof UPDATE_SOCIAL_CAMPAIGN_POST_TYPE;
	postType: string;
}
export interface UPDATE_SOCIAL_ITEM {
	type: typeof UPDATE_SOCIAL_ITEM;
	item: any;
}
export interface UPDATE_SOCIAL_SELECT_PLATFORM {
	type: typeof UPDATE_SOCIAL_SELECT_PLATFORM;
	selectPlatform: any;
}
export interface UPDATE_SOCIAL_NAME {
	type: typeof UPDATE_SOCIAL_NAME;
	name: string;
}

export interface UPDATE_SOCIAL_DESCRIPTION {
	type: typeof UPDATE_SOCIAL_DESCRIPTION;
	description: string;
}

export interface UPDATE_AUDEIENCE {
	type: typeof UPDATE_AUDEIENCE;
	audience: number;
}
export interface UPDATE_SOCIAL_CAMPAIGN_PLATFORMS {
	type: typeof UPDATE_SOCIAL_CAMPAIGN_PLATFORMS;
	platforms: string[];
}
export interface UPDATE_SOCIAL_ACCOUNTS {
	type: typeof UPDATE_SOCIAL_ACCOUNTS;
	accounts: string[];
}
export interface UPDATE_SOCIAL_CAMPAIGN_ACCOUNTS {
	type: typeof UPDATE_SOCIAL_CAMPAIGN_ACCOUNTS;
	socialAccounts: any;
}
export interface UPDATE_SOCIAL_INTERVAL {
	type: typeof UPDATE_SOCIAL_INTERVAL;
	interval: Interval;
}

export interface UPDATE_SOCIAL_FREQUENCY {
	type: typeof UPDATE_SOCIAL_FREQUENCY;
	frequency: Frequency;
}

export interface UPDATE_SOCIAL_DAY {
	type: typeof UPDATE_SOCIAL_DAY;
	day: Day;
}

export interface UPDATE_SOCIAL_SCHEDULE_DATE {
	type: typeof UPDATE_SOCIAL_SCHEDULE_DATE;
	scheduleDate: any;
}

export interface UPDATE_SOCIAL_SCHEDULE_TIME {
	type: typeof UPDATE_SOCIAL_SCHEDULE_TIME;
	scheduleTime: any;
}

export interface UPDATE_SOCIAL_END_DATE {
	type: typeof UPDATE_SOCIAL_END_DATE;
	endDate: any;
}

export interface UPDATE_SOCIAL_END_TIME {
	type: typeof UPDATE_SOCIAL_END_TIME;
	endTime: any;
}
export interface UPDATE_SOCIAL_TIMEZONE {
	type: typeof UPDATE_SOCIAL_TIMEZONE;
	schedule_timezone: TimeZone;
}

export interface UPDATE_SOCIAL_OCCASION {
	type: typeof UPDATE_SOCIAL_OCCASION;
	occasion: string;
}

export interface UPDATE_SOCIAL_SCHEDULE_TYPE {
	type: typeof UPDATE_SOCIAL_SCHEDULE_TYPE;
	scheduleType: OccasionOn;
}
export interface UPDATE_SOCIAL_OCCASION_ADDITIONALDATA {
	type: typeof UPDATE_SOCIAL_OCCASION_ADDITIONALDATA;
	occasion_AdditionalData: OccasionOn;
}

export interface UPDATE_SOCIAL_OCCASION_DAY {
	type: typeof UPDATE_SOCIAL_OCCASION_DAY;
	occasion_day: number;
}
export interface UPDATE_SOCIAL_OCCASION_TIME {
	type: typeof UPDATE_SOCIAL_OCCASION_TIME;
	occasion_time: string;
}
export interface UPDATE_SOCIAL_SCHEDULE_TIMEZONE {
	type: typeof UPDATE_SOCIAL_SCHEDULE_TIMEZONE;
	scheduleTimeZone: TimeZone;
}

export enum Interval {
	Once = 'Once',
	Recurs = 'Recurs',
	Scheduled = 'Scheduled',
}

export enum Frequency {
	Daily = 'Daily',
	Weekly = 'Weekly',
	Monthly = 'Monthly',
	Yearly = 'Yearly',
}

export enum OccasionOn {
	'Run Now',
	'Schedule',
}
export const OccasionData = [
	{
		key: 'before',
		value: 'lt',
	},
	{
		key: 'on',
		value: 'eq',
	},
	{
		key: 'after',
		value: 'gt',
	},
];
export const CampaignRunInterval = [
	{
		key: 'Run Now',
		value: 'runNow',
	},
	{
		key: 'Schedule',
		value: 'schedule',
	},
];

export enum Day {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}
export enum TimeZone {
	'CST',
	'MST',
	'PST',
	'EST',
	'AST',
	'chT',
	'ST',
	'AKT',
}

export type NewCampaignDispatchTypes =
	| UPDATE_SOCIAL_TYPE
	| UPDATE_SOCIAL_VIEW
	| UPDATE_SOCIAL_ITEM
	| UPDATE_SOCIAL_DESCRIPTION
	| UPDATE_SOCIAL_NAME
	| UPDATE_AUDEIENCE
	| UPDATE_SOCIAL_CAMPAIGN_PLATFORMS
	| UPDATE_SOCIAL_ACCOUNTS
	| UPDATE_SOCIAL_FREQUENCY
	| UPDATE_SOCIAL_INTERVAL
	| UPDATE_SOCIAL_DAY
	| UPDATE_SOCIAL_SCHEDULE_DATE
	| UPDATE_SOCIAL_SCHEDULE_TIME
	| UPDATE_SOCIAL_END_DATE
	| UPDATE_SOCIAL_END_TIME
	| UPDATE_SOCIAL_TIMEZONE
	| UPDATE_SOCIAL_OCCASION
	| UPDATE_SOCIAL_SCHEDULE_TYPE
	| UPDATE_SOCIAL_OCCASION_ADDITIONALDATA
	| UPDATE_SOCIAL_OCCASION_DAY
	| UPDATE_SOCIAL_OCCASION_TIME
	| UPDATE_SOCIAL_CREATIVEID
	| UPDATE_SOCIAL_CAMPAIGN_CREATIVE_EDIT
	| UPDATE_SOCIAL_CAMPAIGN_POST_TYPE
	| RESET_SOCIAL_CAMPAIGN_POST
	| UPDATE_SOCIAL_CAMPAIGN_CREATIVE_CAN_EDIT
	| UPDATE_SOCIAL_SCHEDULE_TIMEZONE
	| UPDATE_SOCIAL_SELECT_PLATFORM
	| UPDATE_SOCIAL_CAMPAIGN_ACCOUNTS;
