export const SET_SENTIMENT_CONFIG_SOCIAL_ACCOUNTS =
	'SET_SENTIMENT_CONFIG_SOCIAL_ACCOUNTS';
export const SET_SENTIMENT_CONFIG_SOCIAL_PAGES =
	'SET_SENTIMENT_CONFIG_SOCIAL_PAGES';
export const SET_SENTIMENT_CONFIG_SELECTED_ACCOUNT =
	'SET_SENTIMENT_CONFIG_SELECTED_ACCOUNT';
export const SET_SENTIMENT_CONFIG_SELECTED_PAGE =
	'SET_SENTIMENT_CONFIG_SELECTED_PAGE';
export const SET_SENTIMENT_CONFIG_PLATFORM = 'SET_SENTIMENT_CONFIG_PLATFORM';
export const SET_SENTIMENT_CONFIG_SHOW_SOCIAL_PAGES =
	'SET_SENTIMENT_CONFIG_SHOW_SOCIAL_PAGES';

export const SET_SENTIMENT_CONFIG_FILTERED_SOCIAL_ACCOUNTS =
	'SET_SENTIMENT_CONFIG_FILTERED_SOCIAL_ACCOUNTS';

export const SET_SENTIMENT_CONFIG_TITLE = 'SET_SENTIMENT_CONFIG_TITLE';

export const SET_SENTIMENT_CONFIG_MEDIA_TYPE =
	'SET_SENTIMENT_CONFIG_MEDIA_TYPE';
export const SET_SENTIMENT_CONFIG_MEDIA_URL = 'SET_SENTIMENT_CONFIG_MEDIA_URL';
export const SET_SENTIMENT_CONFIG_SENTIMENT_TYPE =
	'SET_SENTIMENT_CONFIG_SENTIMENT_TYPE';
export const SET_SENTIMENT_CONFIG_ACTION_TYPE =
	'SET_SENTIMENT_CONFIG_ACTION_TYPE';
export const SET_SENTIMENT_CONFIG_COMMENT_TEXT =
	'SET_SENTIMENT_CONFIG_COMMENT_TEXT';
export const SET_SENTIMENT_CONFIG_ASSIGN_TO = 'SET_SENTIMENT_CONFIG_ASSIGN_TO';

export const RESET_SENTIMENT_CONFIG_DATA = 'RESET_SENTIMENT_CONFIG_DATA';
