import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';

export default function CountrySelect({
	countries,
	countryId,
	labelName = 'Country code',
	setCountryId,
	disabled = false,
}: any) {
	const [selectedCountryValue, setSelectedCountryValue] = useState(null);
	const onSelectCountry = (e, newValue) => {
		if (newValue) {
			setCountryId(newValue.countryId);
		} else {
			setCountryId('');
		}
	};

	useEffect(() => {
		const country = countries.find((item) => item.countryId === countryId);
		setSelectedCountryValue(country);
	}, [countryId]);
	return (
		<Autocomplete
			size='small'
			options={countries}
			value={selectedCountryValue}
			autoHighlight
			onChange={onSelectCountry}
			getOptionLabel={(option: any) => `${option.countryId} ${option.phoneCode}`}
			renderOption={(props, option) => (
				<Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
					<img loading='lazy' width='20' src={option.flagUrl} alt='' />
					{option.label} {option.phoneCode}
				</Box>
			)}
			PaperComponent={({ children }) => (
				<Paper
					style={{
						minWidth: '240px', // Set the desired width for the popover
					}}
				>
					{children}
				</Paper>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={labelName}
					InputProps={{
						...params.InputProps,
						autoComplete: 'new-password', // disable autocomplete and autofill
						startAdornment: (
							<>
								{selectedCountryValue && (
									<img
										src={selectedCountryValue.flagUrl}
										alt={selectedCountryValue.phoneCode}
										width='20'
									/>
								)}
							</>
						),
					}}
				/>
			)}
			disabled={disabled}
		/>
	);
}
