import {
	AuthDispatchTypes,
	SET_AUTHENTICAION_FLOW_VIEW,
	SET_USER_INFO,
	SET_USER_ACCOUNT_INFO,
	SET_AUTHENTICATION_TYPE,
	CANCEL_ORG_CREATON_FLOW,
} from './AuthActionTypes';

export interface IAuth {
	authenticationType: 'LOGIN' | 'REGISTER';
	authenticationFlowView: number;
	userInfo: any;
	userAccountInfo: any;
}

const initialState: IAuth = {
	authenticationType: 'LOGIN',
	authenticationFlowView: 0,
	userInfo: null,
	userAccountInfo: {
		Account_Type: 'business',
		Org_Name: '',
		Org_Strength: '',
		Country: '',
		Time_Zone: '',
		Industry: '',
		User_City: '',
		User_State: '',
		User_Locality: '',
		Features: {
			Feature_Email: false,
			Feature_SMS_MMS: false,
			Feature_Social_Media: false,
			Feature_Voice: false,
			Feature_Booking_Appointments: false,
			Feature_E_Commererce: false,
			Feature_Point_of_Sale: false,
			Feature_Website: false
		},
		Quantity: {
			Email: 0,
			SMS: 0,
			MMS: 0,
			Voice: 0,
			Social: 0,
		},
		Quantity_Prices: {
			Email: 0,
			SMS: 0,
			MMS: 0,
			Voice: 0,
			Social: 0,
		},
		Sub_Organization: {
			isSubOrgFlow: false,
			Parent_Org_Name: '',
			Parent_Org_Id: '',
			Parent_Org_Billing: false,
			Account_Type: 'business',
			Org_Name: '',
			Country: '',
			Time_Zone: '',
			Industry: '',
			User_City: '',
			User_State: '',
			User_Locality: '',
			Features: {
				Feature_Email: false,
				Feature_SMS_MMS: false,
				Feature_Social_Media: false,
				Feature_Voice: false,
				Feature_Booking_Appointments: false,
				Feature_E_Commererce: false,
				Feature_Point_of_Sale: false,
				Feature_Website: false
			},
			InviteUsers: null,
			Send_Payment_Req_Info: null,
			InviteUsersUI: null,
			Sender_Profile: null
		}
	},
};

const authenticationStore = (
	state: any = initialState,
	action: AuthDispatchTypes
): IAuth => {
	switch (action.type) {
		case SET_AUTHENTICATION_TYPE:
			return { ...state, authenticationType: action.payload };
		case SET_AUTHENTICAION_FLOW_VIEW:
			return { ...state, authenticationFlowView: action.payload };
		case SET_USER_INFO:
			return { ...state, userInfo: action.payload };
		case SET_USER_ACCOUNT_INFO:
			return { ...state, userAccountInfo: action.payload };
		case CANCEL_ORG_CREATON_FLOW:
			return {
				...state,
				userAccountInfo: initialState.userAccountInfo,
				authenticationFlowView: 0,
			};
		default:
			return state;
	}
};

export default authenticationStore;
