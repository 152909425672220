import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { GetUserProfileData } from 'firebaseApis/user2FactorAuthentication';

import {
	createWithXProjKey,
	getClientDB,
	getOrganisationIdFirebase,
	siteConfigConstants,
} from './AxiosManager';

export const DeleteUserFromClientAuth = async (uid) => {
	try {
		const url = `${siteConfigConstants().REACT_APP_MISCELLANEOUS_URL}/deleteUser`;
		const instance = createWithXProjKey();

		const result = await instance.post(url, {
			Firebase_UID: uid,
		});
		return result;
	} catch (e) {
		return null;
	}
};

export const getCustomAccessToken = async (payload, authUrl = '') => {
	try {
		const url = authUrl || siteConfigConstants().REACT_APP_MISCELLANEOUS_URL;
		const instance = createWithXProjKey();
		const result = await instance.post(`${url}/getCustomUserToken`, payload);
		return result;
	} catch (e) {
		return null;
	}
};

export const UpdateUserProfile = async (payload: any, userId) => {
	try {
		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`,
				userId
			),
			payload
		);
		return {
			success: true,
			message: 'Updated grid state successfully.',
		};
	} catch (e) {
		return null;
	}
};

export const GetUserStatusHistory = async () => {
	try {
		const docRef = doc(
			getClientDB(),
			`${getOrganisationIdFirebase()}/Status_History`
		);
		const docSnapshot = await getDoc(docRef);
		const data = docSnapshot.data();
		return data;
	} catch (e) {
		return null;
	}
};
export const UpdateUserStatusHistory = async (payload: any) => {
	try {
		await updateDoc(
			doc(getClientDB(), `${getOrganisationIdFirebase()}/Status_History`),
			payload
		);
		return {
			success: true,
			message: 'Updated status history successfully.',
		};
	} catch (e) {
		return null;
	}
};

export const saveGridState = async (payload: any) => {
	try {
		const user = await GetUserProfileData();
		const docRef = doc(
			getClientDB(),
			`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`,
			user.id
		);
		const docSnapshot = await getDoc(docRef);
		const data = docSnapshot.data();
		const newGridStates = {
			...data.GridStates,
			...payload,
		};
		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`,
				user.id
			),
			{ GridStates: newGridStates }
		);
		return {
			success: true,
			message: 'Updated grid state successfully.',
		};
	} catch (error) {
		return error;
	}
};

export const deleteGridState = async (payload: any) => {
	try {
		const user = await GetUserProfileData();
		const docRef = doc(
			getClientDB(),
			`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`,
			user.id
		);
		const docSnapshot = await getDoc(docRef);
		const data = docSnapshot.data();
		delete data.GridStates[payload];
		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`,
				user.id
			),
			{ GridStates: data.GridStates }
		);
		return {
			success: true,
			message: 'Updated grid state successfully.',
		};
	} catch (error) {
		return error;
	}
};

export const saveEmailCreativesState = async (payload: any) => {
	try {
		const user = await GetUserProfileData();

		await updateDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`,
				user.id
			),
			{ EmailCreativesState: payload }
		);
		return {
			success: true,
			message: 'Updated grid state successfully.',
		};
	} catch (error) {
		return error;
	}
};
