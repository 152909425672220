export const SET_VOICE_RESPONSE = 'SET_VOICE_RESPONSE';
export const SET_VOICE_PHONE_ERROR = 'SET_VOICE_PHONE_ERROR';
export const SET_VOICE_TREE_DATA = 'SET_VOICE_TREE_DATA';
export const SET_VOICE_VIEW = 'SET_VOICE_VIEW';
export const SET_VOICE_MAIN_MESSAGE = 'SET_VOICE_MAIN_MESSAGE';
export const RESET_VOICE_CREATIVE = 'RESET_VOICE_CREATIVE';
export const SET_VOICE_REPLY = 'SET_VOICE_REPLY';
export const SET_VOICE_NODE_ID = 'SET_VOICE_NODE_ID';
export const ADD_VOICE_REPLY = 'ADD_VOICE_REPLY';
export const SET_VOICE_SPEAKER_LANGUAGES = 'SET_VOICE_SPEAKER_LANGUAGES';
export const SET_VOICE_SPEAKERS = 'SET_VOICE_SPEAKERS';
export const SET_VOICE_CREATIVE_NAME = 'SET_VOICE_CREATIVE_NAME';
export const SET_VOICE_SELECTED_CATEGORY = 'SET_VOICE_SELECTED_CATEGORY';
export const SET_VOICE_CATEGORIES = 'SET_VOICE_CATEGORIES';
export const SET_VOICE_SELECTED_SUBCATEGORY = 'SET_VOICE_SELECTED_SUBCATEGORY';
export const SET_VOICE_MAIN_MESSAGE_HAVE_DYNAMIC =
	'SET_VOICE_MAIN_MESSAGE_HAVE_DYNAMIC';
export const SET_VOICE_SEARCH_TEXT = 'SET_VOICE_SEARCH_TEXT';
export const SET_VOICE_FILTER = 'SET_VOICE_FILTER';
export const SET_VOICE_GRID_VIEW = 'SET_VOICE_GRID_VIEW';
export const SET_VOICE_CREATIVE = 'SET_VOICE_CREATIVE';
export const SET_VOICE_SHOW_DELETE_MODAL = 'SET_VOICE_SHOW_DELETE_MODAL';
export const SET_VOICE_CREATIVES = 'SET_VOICE_CREATIVES';
export const SET_VOICE_LAST_VISIBLE = 'SET_VOICE_LAST_VISIBLE';
export const SET_VOICE_FIRST_VISIBLE = 'SET_VOICE_FIRST_VISIBLE';
export const SET_VOICE_PAGE_SIZE = 'SET_VOICE_PAGE_SIZE';
export const SET_VOICE_PAGE_NUM = 'SET_VOICE_PAGE_NUM';
export const SET_VOICE_TOTAL_ROWS = 'SET_VOICE_TOTAL_ROWS';
export const SET_VOICE_TOTAL_PAGES = 'SET_VOICE_TOTAL_PAGES';
export const SET_VOICE_CATEGORY_SAVE_ACTIONS =
	'SET_VOICE_CATEGORY_SAVE_ACTIONS';
export const SET_VOICE_MAIN_CATEGORY_SAVE_ACTIONS =
	'SET_VOICE_MAIN_CATEGORY_SAVE_ACTIONS';
export const SET_VOICE_INTERNAL_CATEGORIES = 'SET_VOICE_INTERNAL_CATEGORIES';
export const SET_VOICE_INTERNAL_SELECTED_CATEGORIES =
	'SET_VOICE_INTERNAL_SELECTED_CATEGORIES';
export const SET_VOICE_ADD_CAT_DATA = 'SET_VOICE_ADD_CAT_DATA';
export const SET_VOICE_DELETE_DATA = 'SET_VOICE_DELETE_DATA';
export const SET_VOICE_OPEN_MODAL = 'SET_VOICE_OPEN_MODAL';
export const SET_VOICE_MODAL_TYPE = 'SET_VOICE_MODAL_TYPE';
export const SET_VOICE_NEW_CATEGORY = 'SET_VOICE_NEW_CATEGORY';
export const SET_VOICE_FILTER_MODAL = 'SET_VOICE_FILTER_MODAL';
export const SET_VOICE_MODAL_IMAGE = 'SET_VOICE_MODAL_IMAGE';
export const RESET_ALL_VOICE_FILTERS = 'RESET_ALL_VOICE_FILTERS';
