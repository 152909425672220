import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import CreateOrg from 'views/NewSignUp/CreateOrg';
import ChooseOrg from 'views/NewSignUp/ChooseOrg';
import PaymentTerminals from 'views/POS/PaymentTerminals';
import Calendar from 'views/Calendar';
import RegisterForm from 'views/NewSignUp/RegisterForm';
import WelcomeScreen from 'views/NewSignUp/OrgCreateComponents/WelcomeScreen';
import JoinFromInvite from 'views/NewSignUp/JoinFromInvite';
import Protected from 'layouts/Protected';
import PaymentRequest from 'views/NewSignUp/SubOrgComponents/PaymentRequest';
import { clear, getUser, siteConfigConstants } from 'api/AxiosManager';
import {
	logout,
	refreshClientToken,
	refreshMasterToken,
} from 'firebaseApis/authentication';

import ScrollToTop from '../layouts/components/ScrollToTop';
import DomainRoute from '../layouts/Domain';
import Public from '../layouts/Public';
import Private from '../layouts/Private';
import Error404Page from '../layouts/components/404';
import Login from '../views/NewSignUp';
import AuthActions from '../views/NewSignUp/AuthActions';
import { Spinner } from '../components/Spinner';
import Toast, { ToastTypes } from '../components/ToastNotify';
import { delay } from '../helpers/utils';

const TeamMembers = lazy(() => import('views/POS/TeamMembers'));
const AddTeamMember = lazy(() => import('views/POS/TeamMembers/Add'));
const Cards = lazy(() => import('views/POS/Cards'));

const WebsiteBuilder = lazy(
	() => import('views/BrandManagement/Websites/New/WebsiteBuilder')
);

const Authentication = lazy(() => import('views/Authentication'));

const WebPagePreviewPage = lazy(
	() => import('views/BrandManagement/Websites/New/Preview')
);

const CreateWebsite = lazy(
	() => import('../views/BrandManagement/Websites/New')
);

// const Websites = lazy(() => import('../views/BrandManagement/Websites'));
const Websites = lazy(() => import('../views/BrandManagement/WebsiteBuilder'));
const NewWebsite = lazy(
	() => import('../views/BrandManagement/WebsiteBuilder/WebsiteNew')
);

const BookingEventNew = lazy(
	() => import('../views/BookingsNew/EventDescription')
);
const Leap360Orders = lazy(() => import('../views/Leap360-Orders'));
const BookingEvent = lazy(() => import('../views/BookingsNew'));
const Events = lazy(() => import('../views/Bookings/Orders/Events'));
const Event = lazy(() => import('../views/Bookings/Orders/Event'));
const EventCalendar = lazy(() => import('../views/Bookings/EventCalendar'));

const SocialMedia = lazy(() => import('../views/BrandManagement/SocialMedia'));

const EditTask = lazy(
	() => import('../views/BrandManagement/SocialMedia/Components/AssignTask/Edit')
);

const ModalPostView = lazy(
	() => import('../views/BrandManagement/SocialMedia/Components/ModalPostView')
);
const FacebookFullPreview = lazy(
	() =>
		import(
			'../views/BrandManagement/SocialMedia/Components/FullPreviews/FacebookFullPreview'
		)
);
const InstagramFullPreview = lazy(
	() =>
		import(
			'../views/BrandManagement/SocialMedia/Components/FullPreviews/InstagramFullPreview'
		)
);
const TwitterFullPreview = lazy(
	() =>
		import(
			'../views/BrandManagement/SocialMedia/Components/FullPreviews/TwitterFullPreview'
		)
);
const LinkedinFullPreview = lazy(
	() =>
		import(
			'../views/BrandManagement/SocialMedia/Components/FullPreviews/LinkedinFullPreview'
		)
);
const SingleChannelCampaigns = lazy(
	() => import('../views/Marketing/SingleChannelCampaigns')
);
const SingleChannelCampaignsNew = lazy(
	() => import('../views/Marketing/SingleChannelCampaigns/New')
);

const NewSegment = lazy(() => import('../views/Marketing/Segments/New'));
const NewTrigger = lazy(() => import('../views/Marketing/Triggers/New'));
const EditSegment = lazy(() => import('../views/Marketing/Segments/Edit'));
const EditTrigger = lazy(() => import('../views/Marketing/Triggers/Edit'));
const DynamicModuleDocuments = lazy(() => import('../views/Dynamic/Main'));
const NewDynamicModuleDocument = lazy(
	() => import('../views/Dynamic/Main/New')
);
const EditDynamicModuleDocument = lazy(
	() => import('../views/Dynamic/Main/Edit')
);

const UsersGroups = lazy(() => import('../views/Admin/UsersGroups'));
const Employees = lazy(() => import('../views/Admin/Employees'));

const AdminMarketting = lazy(() => import('../views/Admin/Marketting'));

const EditAccountConfig = lazy(
	() => import('../views/Admin/Accounts/AccountUpdate')
);
// not using in present code

// const NewEmailConfig = lazy(
// 	() => import('../views/Admin/Marketting/EmailConfig/NewEmailConfig')
// );
// const SMSConfiguration = lazy(
// 	() => import('../views/Admin/Marketting/SmsConfig/SMSConfiguration')
// );
// const VoiceConfiguration = lazy(
// 	() => import('../views/Admin/Marketting/VoiceConfig/VoiceConfiguration')
// );
const NewSentimentConfig = lazy(
	() => import('../views/Admin/Marketting/SentimentConfig/New')
);
const EditSentimentConfig = lazy(
	() => import('../views/Admin/Marketting/SentimentConfig/New')
);
const Admin = lazy(() => import('../views/Admin/NewMenuItems'));

const NewOrganizations = lazy(() => import('../views/Admin/NewOrganization'));
const DefaultOrganization = lazy(
	() => import('../views/Admin/NewOrganization/DefaultOrganization')
);
const DefaultLocation = lazy(
	() => import('../views/Admin/NewOrganization/Locations')
);
const DefaultNewLocation = lazy(
	() => import('../views/Admin/NewOrganization/Locations/NewEditLocation')
);
const SenderProfiles = lazy(() => import('../views/Admin/SenderProfiles'));
const NewSenderProfiles = lazy(
	() => import('../views/Admin/SenderProfiles/NewSenderProfiles')
);
const TwitterCallback = lazy(
	() => import('../views/Admin/Accounts/TwitterCallback')
);
const GoogleCallback = lazy(
	() => import('../views/Admin/Accounts/GoogleCallback')
);

const Analytics = lazy(() => import('../views/Analytics'));
const EmailDetails = lazy(
	() => import('../views/Analytics/Email/EmailDetails')
);
const SMSCampaignDetails = lazy(
	() => import('../views/Analytics/SMS/SMSCampaignDetails')
);
const VoiceCampaignDetails = lazy(
	() => import('../views/Analytics/Voice/VoiceCampaignDetails')
);

const VoiceRecipientDetails = lazy(
	() => import('../views/Analytics/Voice/VoiceRecipientDetails')
);

const SMSRecipientDetails = lazy(
	() => import('../views/Analytics/SMS/SMSRecipientDetails')
);
const EmailCampaignRecipientsDetails = lazy(
	() => import('../views/Analytics/Email/EmailCampaignRecipientsDetails')
);
const ForgotPassword = lazy(() => import('../views/ForgotPassword'));
const CheckYourEmail = lazy(() => import('../views/CheckYourEmail'));
const Dashboard = lazy(() => import('../views/Dashboard'));
const Creatives = lazy(() => import('../views/Marketing/Creatives'));
const NewSocial = lazy(() => import('../views/Marketing/Creatives/Social/New'));
const SocialTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/SocialTemplates')
);
const FacebookTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/FacebookTemplates/FacebookTemplate'
		)
);
const GoogleTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/GoogleTemplates/GoogleTemplate'
		)
);
const TwitterTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/TwitterTemplates/TwitterTemplate'
		)
);
const LinkedinTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/LinkedinTemplates/LinkedinTemplate'
		)
);
const InstagramTemplate = lazy(
	() =>
		import(
			'../views/Marketing/SocialMediaTemplates/InstagramTemplates/InstagramTemplate'
		)
);
const FacebookTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/FacebookTemplates')
);
const InstagramTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/InstagramTemplates')
);
const GoogleTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/GoogleTemplates')
);
const LinkedinTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/LinkedinTemplates')
);
const TwitterTemplates = lazy(
	() => import('../views/Marketing/SocialMediaTemplates/TwitterTemplates')
);
const NewSMS = lazy(() => import('../views/Marketing/Creatives/SMS/NewSMS'));
const NewVoice = lazy(
	() => import('../views/Marketing/Creatives/Voice/NewVoice')
);
const SocialViewPostActions = lazy(
	() =>
		import(
			'../views/Marketing/Creatives/Social/components/ViewPost/SocialViewPostActions'
		)
);
const SMSViewPostActions = lazy(
	() => import('../views/Marketing/Creatives/SMS/ViewPost/SMSViewPostActions')
);
const VoiceViewPostActions = lazy(
	() =>
		import('../views/Marketing/Creatives/Voice/ViewPost/VoiceViewPostActions')
);
const EditPost = lazy(
	() => import('../views/Marketing/Creatives/Social/components/EditPost')
);
const EditPostSMS = lazy(
	() => import('../views/Marketing/Creatives/SMS/EditPost')
);

const EditPostVoice = lazy(
	() => import('../views/Marketing/Creatives/Voice/EditPost')
);
const NewCreative = lazy(() => import('../views/Marketing/Creatives/New'));
const EditCreative = lazy(() => import('../views/Marketing/Creatives/Edit'));
const CreativeActions = lazy(
	() => import('../views/Marketing/Creatives/components/CreativeActions')
);
const Segments = lazy(() => import('../views/Marketing/Segments'));
const UserSettings = lazy(() => import('../views/User/UserSettings'));
const Billing = lazy(() => import('../views/User/Billing'));
const Triggers = lazy(() => import('../views/Marketing/Triggers'));
const SocialInbox = lazy(() => import('../views/Inbox/Social'));
const CampaignsInbox = lazy(() => import('../views/Inbox/Campaigns'));
const CommunicationInbox = lazy(() => import('../views/Inbox/Communication'));
const POSOrders = lazy(() => import('../views/POS/Orders'));
const WorkStations = lazy(() => import('../views/POS/WorkStations'));
const Store = lazy(() => import('../views/POS/Store'));
const POSAdmin = lazy(() => import('../views/POS/Admin'));
const PIM = lazy(() => import('../views/POS/PIM'));
const PIMCatalog = lazy(() => import('../views/POS/PIM/Catalog'));
const PIMCategory = lazy(() => import('../views/POS/PIM/Category'));
const PIMCatalog_Category = lazy(
	() => import('../views/POS/PIM/CatalogCategory')
);
const PIMProducts = lazy(() => import('../views/POS/PIM/Products'));
const PIMImages_Videos = lazy(() => import('../views/POS/PIM/Images'));
const PIMCustom_Attributes = lazy(
	() => import('../views/POS/PIM/CustomAttributes')
);
const PIMProduct_Class = lazy(() => import('../views/POS/PIM/ProductClass'));
const PIMSKU = lazy(() => import('../views/POS/PIM/SKUs'));
const PIMModifiers = lazy(() => import('../views/POS/PIM/Modifiers'));
const PIMCoupons = lazy(() => import('../views/POS/PIM/Coupons'));
const ProductCodes = lazy(() => import('../views/POS/PIM/ProductCodes'));
const AddEditProduct = lazy(
	() => import('../views/POS/PIM/Products/AddEditProduct')
);
const ViewResizeImages = lazy(
	() => import('../views/POS/PIM/Images/ViewResizeImages')
);
const AddEditSKU = lazy(() => import('../views/POS/PIM/SKUs/AddEditSKU'));
const AddEditCoupon = lazy(
	() => import('../views/POS/PIM/Coupons/AddEditCoupon')
);
const AddEditModifier = lazy(
	() => import('../views/POS/PIM/Modifiers/AddEditModifier')
);
const EditorPopUP = lazy(() => import('components/ImageEditor/PopUp'));
const PopUpResponse = lazy(
	() => import('components/ImageEditor/PopUpResponse')
);
const DynamicForms = lazy(() => import('../views/Admin/DynamicForms'));
const NewDynamicForm = lazy(
	() => import('../views/Admin/DynamicForms/Components/DynamicForm')
);

const EcommerceOrders = lazy(() => import('../views/ECommerce/Orders'));
const EcommerceAdmin = lazy(() => import('../views/ECommerce/Admin'));
const EcommerceContacts = lazy(() => import('../views/ECommerce/Contacts'));
const EcommerceWebsite = lazy(() => import('../views/ECommerce/Website'));
const EcommerceOrderInfo = lazy(
	() => import('../views/ECommerce/Orders/OrderInfo')
);

const BookingsOrders = lazy(() => import('../views/Bookings/NewOrders'));
const BookingsOrderInfo = lazy(
	() => import('../views/Bookings/NewOrders/OrderInfo')
);
const NewBooking = lazy(
	() => import('../views/Bookings/NewOrders/NewBooking/index')
);
const Waivers = lazy(() => import('../views/Bookings/Waivers'));
const Inventory = lazy(() => import('../views/POS/PIM/Inventory'));
const SupplierForm = lazy(
	() => import('../views/POS/PIM/Suppliers/SupplierForm/index')
);
const Supplier = lazy(() => import('../views/POS/PIM/Suppliers/index'));
const Transactions = lazy(() => import('../views/POS/PIM/Transactions/index'));
const InventoryForm = lazy(
	() => import('../views/POS/PIM/Inventory/AddEditInventory')
);
const Import = lazy(() => import('../views/Data/Import'));
const NewDataImport = lazy(() => import('../views/Data/Import/New'));

const InvoiceTable = lazy(() => import('../views/Backoffice/Invoices'));
const NewInvoice = lazy(() => import('../views/Backoffice/Invoices/New'));

const InvoiceAccountsTable = lazy(() => import('../views/Backoffice/Accounts'));
const NewInvoiceAccount = lazy(
	() => import('../views/Backoffice/Accounts/New')
);
const InvoiceView = lazy(
	() => import('../views/Backoffice/Invoices/InvoiceView')
);

// product management new routes

const ProductManagement = lazy(
	() => import('../views/ProductManagement/index')
);

const Catalogs = lazy(
	() => import('../views/ProductManagement/Catalogs/index')
);
const CatalogForm = lazy(
	() => import('../views/ProductManagement/Catalogs/CatalogForm/index')
);

const CategoryForm = lazy(
	() => import('../views/ProductManagement/Catalogs/CategoryForm/index')
);

const Products = lazy(
	() => import('../views/ProductManagement/Products/index')
);

const ProductClass = lazy(
	() => import('../views/ProductManagement/ProductClass/index')
);

const ProductClassAddEdit = lazy(
	() => import('../views/ProductManagement/ProductClass/AddEditProductClass')
);

const Main = () => {
	const [loading, setLoading] = useState<boolean>(true);

	const processAuthentication = async () => {
		const user = getUser();

		let clientToken = null;
		let masterToken = null;
		// Reauthenticate firestore login for client and master on every reload
		if (
			user?.firestoreConfig &&
			user.uid &&
			siteConfigConstants().REACT_APP_MISCELLANEOUS_URL
		) {
			clientToken = await refreshClientToken(user);
		}
		if (user?.uid && siteConfigConstants().REACT_APP_MISCELLANEOUS_URL) {
			masterToken = await refreshMasterToken(user);
		}
		if (!clientToken || !masterToken) {
			clear();
		}
		setLoading(false);
	};
	const handlePostMessage = async (event) => {
		if (event.data === 'logoutFromMaster') {
			Toast({
				title: 'Session timed out. Please login back.',
				type: ToastTypes.INFO,
			});
			await delay(1000);
			logout();
		}
	};
	useEffect(() => {
		processAuthentication();
		window.addEventListener('message', handlePostMessage);
		return () => window.removeEventListener('message', handlePostMessage);
	}, []);
	if (loading) return <Spinner />;
	return (
		<Router>
			<ScrollToTop>
				<Switch>
					{/* Public */}
					<Route
						exact
						path='/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={Login} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/redirectFromLeap360'
						render={() => (
							<Suspense fallback={<Spinner />}>
								<Authentication />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/signup'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={RegisterForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/auth'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={AuthActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/verifyemail'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={WelcomeScreen} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/join'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={JoinFromInvite} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/ForgotPassword'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={ForgotPassword} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/CheckYourEmail'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={CheckYourEmail} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/payment-request/:token'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Public component={PaymentRequest} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/chooseorg'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Protected component={ChooseOrg} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/createorg'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Protected component={CreateOrg} {...props} />
							</Suspense>
						)}
					/>

					{/* Private */}
					<Route
						exact
						path='/Dashboard'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Dashboard} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/calendar'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Calendar} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/UserSettings/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={UserSettings} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/UserSettings/:tab/:action'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={UserSettings} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Billing/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Billing} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Inbox/Social/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialInbox} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Inbox/Campaigns/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CampaignsInbox} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Communication/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CommunicationInbox} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Calendar'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EventCalendar} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Orders/Events'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Events} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Orders/Event/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Event} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Orders/Payment/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Event} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/Orders/Guests/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Event} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Booking_Builder'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={BookingEvent} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Booking_Builder/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={BookingEventNew} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Booking_Builder/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={BookingEventNew} {...props} />
							</Suspense>
						)}
					/>
					{/* AddOns */}
					<Route
						exact
						path='/Admin/Configurations/ADD_ONS'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Leap360Orders} {...props} />
							</Suspense>
						)}
					/>
					{/* Wesbites */}
					<Route
						exact
						path='/BrandManagement/Websites'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Websites} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/BrandManagement/WebsiteBuilder/new'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewWebsite} {...props} />
							</Suspense>
						)}
					/>
					{/* Wesbites */}
					<Route
						exact
						path='/BrandManagement/Websites/new'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CreateWebsite} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/BrandManagement/Websites/Website/:website'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={WebsiteBuilder} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/BrandManagement/Websites/Website/:websiteId/:headerId/:footerId/:pageId'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={WebPagePreviewPage} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/BrandManagement/EcommerceOrders'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EcommerceOrders} {...props} />
							</Suspense>
						)}
					/>

					{/* SocilaMedia */}
					<Route
						exact
						path='/BrandManagement/SocialMedia/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialMedia} {...props} />
							</Suspense>
						)}
					/>
					<Route
						path='/BrandManagement/SocialMedia'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialMedia} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/BrandManagement/SocialMedia/Post'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={ModalPostView} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Inbox/Received/Task/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditTask} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Inbox/Sent/Task/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditTask} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/SocialMedia/Posts/FbPost/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<DomainRoute component={FacebookFullPreview} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/SocialMedia/Posts/InstaPost/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<DomainRoute component={InstagramFullPreview} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/SocialMedia/Posts/TwitterPost/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<DomainRoute component={TwitterFullPreview} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/SocialMedia/Posts/LinkedInPost/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<DomainRoute component={LinkedinFullPreview} {...props} />
							</Suspense>
						)}
					/>
					{/* Marketing */}
					<Route
						exact
						path='/Marketing/SingleChannelCampaigns'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SingleChannelCampaigns} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SingleChannelCampaigns/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SingleChannelCampaignsNew} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SingleChannelCampaigns/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SingleChannelCampaignsNew} {...props} />
							</Suspense>
						)}
					/>

					{/* Creatives */}
					<Route
						exact
						path='/Marketing/Creatives'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/SMS'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Voice'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Voice/New/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewVoice} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Email'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Social'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Social/New/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSocial} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/FacebookTemplates/FacebookTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={FacebookTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/GoogleTemplates/GoogleTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={GoogleTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/InstagramTemplates/InstagramTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={InstagramTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/TwitterTemplates/TwitterTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={TwitterTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/LinkedinTemplates/LinkedinTemplate/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={LinkedinTemplate} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/FacebookTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={FacebookTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/InstagramTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={InstagramTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/GoogleTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={GoogleTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/TwitterTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={TwitterTemplates} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/SocialTemplates/LinkedinTemplates'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={LinkedinTemplates} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Marketing/Creatives/Social/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SocialViewPostActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Social/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditPost} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/SMS/New/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSMS} {...props} />
							</Suspense>
						)}
					/>
					{/* start */}
					<Route
						exact
						path='/Marketing/Creatives/Email/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewCreative} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/SMS/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditPostSMS} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Voice/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditPostVoice} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/SMS/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSViewPostActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Voice/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceViewPostActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Email/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CreativeActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Creatives/Email/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditCreative} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Marketing/Creatives/Email/edit/:id/:actionType'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditCreative} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Marketing/Creatives/Email/view/:tempType/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CreativeActions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Creatives} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Segments'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Segments} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Segments/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSegment} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Segments/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditSegment} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Triggers'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Triggers} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Triggers/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewTrigger} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Marketing/Triggers/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditTrigger} {...props} />
							</Suspense>
						)}
					/>
					{/* Customers */}
					<Route
						exact
						path='/Dynamic/:menu/:moduleName/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DynamicModuleDocuments} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/DynamicPath/:menu/:subMenu/:moduleName/'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DynamicModuleDocuments} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Dynamic/:menu/:moduleName/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDynamicModuleDocument} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/DynamicPath/:menu/:subMenu/:moduleName/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDynamicModuleDocument} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Dynamic/:menu/:moduleName/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditDynamicModuleDocument} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/DynamicPath/:menu/:subMenu/:moduleName/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditDynamicModuleDocument} {...props} />
							</Suspense>
						)}
					/>
					{/* Business */}

					{/* Admin */}
					<Route
						exact
						path='/Admin/Access_Management/UsersGroups/:tab'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={UsersGroups} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Access_Management/Employees'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Employees} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/Social_Accounts'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AdminMarketting} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/AccountConfig/:id/Edit'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditAccountConfig} {...props} />
							</Suspense>
						)}
					/>
					{/* not using in present code */}
					{/* <Route
						exact
						path='/Admin/Communications/EmailConfig/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewEmailConfig} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/EmailConfig/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewEmailConfig} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/SmsConfig/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSConfiguration} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/SmsConfig/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSConfiguration} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/VoiceConfig/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceConfiguration} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Communications/VoiceConfig/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceConfiguration} {...props} />
							</Suspense>
						)}
					/> */}
					<Route
						exact
						path='/Admin/Configurations/SentimentConfig/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSentimentConfig} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/SentimentConfig/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditSentimentConfig} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/NewOrganization'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewOrganizations} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/NewOrganization/Organization'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewOrganizations} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/Dynamic_Form'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DynamicForms} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/Dynamic_Form/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDynamicForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Dynamic_Form/:moduleName/:action'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDynamicForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Data_Import'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Import} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/Data_Import/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewDataImport} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Backoffice/Invoice'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={InvoiceTable} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Backoffice/Invoice/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewInvoice} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Backoffice/Accounts'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={InvoiceAccountsTable} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Backoffice/Accounts/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewInvoiceAccount} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Backoffice/Invoice/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={InvoiceView} {...props} />
							</Suspense>
						)}
					/>

					{/* // New Flow */}
					<Route
						exact
						path='/Admin/Organization'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Admin} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Admin} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Access_Management'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Admin} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/DynamicPath/:menu/:submenu'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Admin} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Organization/DefaultOrg'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DefaultOrganization} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Organization/Locations'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DefaultLocation} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Organization/Locations/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DefaultNewLocation} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Organization/Locations/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={DefaultNewLocation} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Sender_Profile'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SenderProfiles} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Sender_Profile/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSenderProfiles} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Sender_Profile/:id/:type'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewSenderProfiles} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/linkedin'
						render={() => (
							<Suspense fallback={<Spinner />}>
								<LinkedInCallback />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/twitter'
						render={() => (
							<Suspense fallback={<Spinner />}>
								<TwitterCallback />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/google'
						render={() => (
							<Suspense fallback={<Spinner />}>
								<GoogleCallback />
							</Suspense>
						)}
					/>

					{/* Analytics */}
					<Route
						exact
						path='/Analytics/Campaigns'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Analytics} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/Email'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Analytics} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/SMS'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Analytics} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/Voice'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Analytics} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/:id/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EmailDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/SMS/:id/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSCampaignDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/SMS/:id/:messageId/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SMSRecipientDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/Voice/:id/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceCampaignDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/Voice/:id/:messageId/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={VoiceRecipientDetails} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Analytics/Campaigns/:id/:messageId/:from/:to'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EmailCampaignRecipientsDetails} {...props} />
							</Suspense>
						)}
					/>

					{/* POS */}
					<Route
						exact
						path='/pos/orders'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={POSOrders} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/store'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Store} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/work-stations'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={WorkStations} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/payment-terminals'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PaymentTerminals} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/cards'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Cards} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/team-members'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={TeamMembers} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/pos/team-members/add'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddTeamMember} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Pos-Config'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={POSAdmin} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIM} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/catalog'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMCatalog} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/category'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMCategory} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/catalogcategory'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMCatalog_Category} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/products'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMProducts} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/images'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMImages_Videos} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/customattributes'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMCustom_Attributes} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/productclass'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMProduct_Class} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/skus'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMSKU} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/modifiers'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMModifiers} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/coupons'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PIMCoupons} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/productcodes'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={ProductCodes} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/products/add'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddEditProduct} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/images/view/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={ViewResizeImages} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/products/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddEditProduct} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/coupons/add'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddEditCoupon} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/coupons/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddEditCoupon} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/skus/add'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddEditSKU} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/skus/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddEditSKU} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/modifiers/add'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddEditModifier} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/modifiers/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={AddEditModifier} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/inventory'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Inventory} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/suppliers'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Supplier} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/transactions'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Transactions} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/suppliers/add'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SupplierForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/suppliers/edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={SupplierForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/Product_Catalog/inventory/add'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={InventoryForm} {...props} />
							</Suspense>
						)}
					/>

					{/* Ecommerce */}

					<Route
						exact
						path='/ecommerce/orders'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EcommerceOrders} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/ecommerce/orders/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EcommerceOrderInfo} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/Ecommerce_Admin'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EcommerceAdmin} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/ecommerce/contacts'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EcommerceContacts} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/ecommerce/website'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EcommerceWebsite} {...props} />
							</Suspense>
						)}
					/>

					{/* Bookings */}
					<Route
						exact
						path='/Bookings/orders'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={BookingsOrders} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/orders/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={BookingsOrderInfo} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={NewBooking} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Bookings/waivers'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Waivers} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/ImageEditor'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={EditorPopUP} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/ImageEditorResponse'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={PopUpResponse} {...props} />
							</Suspense>
						)}
					/>

					{/* Product Management */}

					<Route
						exact
						path='/Admin/Configurations/ProductManagement'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={ProductManagement} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/ProductManagement/Catalogs'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Catalogs} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/ProductManagement/Catalogs/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CatalogForm} {...props} />
							</Suspense>
						)}
					/>
					<Route
						exact
						path='/Admin/Configurations/ProductManagement/Catalogs/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CatalogForm} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/ProductManagement/Catalogs/AddCategory'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CategoryForm} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/ProductManagement/Catalogs/EditCategory/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={CategoryForm} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/ProductManagement/Products'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={Products} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/ProductManagement/ProductClass'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={ProductClass} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/ProductManagement/ProductClass/New'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={ProductClassAddEdit} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/Admin/Configurations/ProductManagement/ProductClass/Edit/:id'
						render={(props) => (
							<Suspense fallback={<Spinner />}>
								<Private component={ProductClassAddEdit} {...props} />
							</Suspense>
						)}
					/>

					<Route
						exact
						path='/404'
						render={(props) => <DomainRoute component={Error404Page} {...props} />}
					/>
					<Route
						render={(props) => <DomainRoute component={Error404Page} {...props} />}
					/>
				</Switch>
			</ScrollToTop>
		</Router>
	);
};

export default Main;
