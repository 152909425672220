import {
	SET_SENTIMENT_CONFIG_SOCIAL_ACCOUNTS,
	SET_SENTIMENT_CONFIG_FILTERED_SOCIAL_ACCOUNTS,
	SET_SENTIMENT_CONFIG_SOCIAL_PAGES,
	SET_SENTIMENT_CONFIG_SELECTED_ACCOUNT,
	SET_SENTIMENT_CONFIG_SELECTED_PAGE,
	SET_SENTIMENT_CONFIG_PLATFORM,
	SET_SENTIMENT_CONFIG_SHOW_SOCIAL_PAGES,
	SET_SENTIMENT_CONFIG_TITLE,
	SET_SENTIMENT_CONFIG_MEDIA_TYPE,
	SET_SENTIMENT_CONFIG_MEDIA_URL,
	SET_SENTIMENT_CONFIG_SENTIMENT_TYPE,
	SET_SENTIMENT_CONFIG_ACTION_TYPE,
	SET_SENTIMENT_CONFIG_COMMENT_TEXT,
	SET_SENTIMENT_CONFIG_ASSIGN_TO,
	RESET_SENTIMENT_CONFIG_DATA,
} from './SentimentConfigTypes';

interface ISentimentConfigReducer {
	socialAccounts: any;
	filteredSocialAccounts: any;
	socialPages: any;
	selectedAccount: string;
	selectedPage: string;
	showSocialPages: boolean;
	platform: string;
	mediaUrl: string;
	sentimentType: string;
	actionType: string;
	commentText: string;
	assignTo: any;
	mediaType: string;
	sentimentConfigtitle: string;
}

const initialState = {
	socialAccounts: [],
	filteredSocialAccounts: [],
	socialPages: [],
	selectedAccount: '',
	selectedPage: '',
	showSocialPages: false,
	platform: '',
	sentimentConfigtitle: 'New Sentiment Config',
	mediaType: '',
	mediaUrl: '',
	sentimentType: '',
	actionType: '',
	commentText: '',
	assignTo: '',
};

const SentimentConfigReducer = (
	state: any = initialState,
	action: any
): ISentimentConfigReducer => {
	switch (action.type) {
		case SET_SENTIMENT_CONFIG_SOCIAL_ACCOUNTS:
			return {
				...state,
				socialAccounts: action.payload,
			};
		case SET_SENTIMENT_CONFIG_FILTERED_SOCIAL_ACCOUNTS:
			return {
				...state,
				filteredSocialAccounts: action.payload,
			};
		case SET_SENTIMENT_CONFIG_SOCIAL_PAGES:
			return {
				...state,
				socialPages: action.payload,
			};
		case SET_SENTIMENT_CONFIG_SELECTED_ACCOUNT:
			return {
				...state,
				selectedAccount: action.payload,
			};
		case SET_SENTIMENT_CONFIG_SELECTED_PAGE:
			return {
				...state,
				selectedPage: action.payload,
			};
		case SET_SENTIMENT_CONFIG_PLATFORM:
			return {
				...state,
				platform: action.payload,
			};
		case SET_SENTIMENT_CONFIG_TITLE:
			return {
				...state,
				sentimentConfigtitle: action.payload,
			};
		case SET_SENTIMENT_CONFIG_MEDIA_TYPE:
			return {
				...state,
				mediaType: action.payload,
			};
		case SET_SENTIMENT_CONFIG_MEDIA_URL:
			return {
				...state,
				mediaUrl: action.payload,
			};
		case SET_SENTIMENT_CONFIG_SENTIMENT_TYPE:
			return {
				...state,
				sentimentType: action.payload,
			};
		case SET_SENTIMENT_CONFIG_ACTION_TYPE:
			return {
				...state,
				actionType: action.payload,
			};
		case SET_SENTIMENT_CONFIG_COMMENT_TEXT:
			return {
				...state,
				commentText: action.payload,
			};
		case SET_SENTIMENT_CONFIG_ASSIGN_TO:
			return {
				...state,
				assignTo: action.payload,
			};
		case SET_SENTIMENT_CONFIG_SHOW_SOCIAL_PAGES:
			return {
				...state,
				showSocialPages: action.payload,
			};

		case RESET_SENTIMENT_CONFIG_DATA:
			return {
				...initialState,
				socialAccounts: [],
				filteredSocialAccounts: [],
				socialPages: [],
				selectedAccount: '',
				selectedPage: '',
				showSocialPages: false,
				platform: '',
				sentimentConfigtitle: 'New Sentiment Config',
				mediaType: '',
				mediaUrl: '',
				sentimentType: '',
				actionType: '',
				commentText: '',
				assignTo: '',
			};
		default:
			return state;
	}
};

export default SentimentConfigReducer;
