import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';

import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'reducers';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import FlatIcons from 'components/FlatIcons';
// import AvatarComponent from 'storybook-mui/build/components/Avatar';
import './nav.scss';
import { ToggleCollapse } from '../../reducers/Layout/LayoutActions';
import { setTableViewType } from '../../reducers/Table/TableActions';

export const NavPill = ({ item, openTab, setOpenTab }: INavPill) => {
	const [selectedSubMenuToShowChildItems, setSelectedSubMenuToShowChildItems] =
		useState<ISubItem>(null);
	const [menuHover, setMenuHover] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const toggleCollapse = () => dispatch(ToggleCollapse());

	let current: any;
	if (!location.pathname.includes('Dynamic')) {
		current =
			item.href !== '' ? location.pathname.indexOf(item.href) === 0 : false;
	} else {
		const temp = item?.subItems?.filter(
			(submenu) => location.pathname.includes(submenu.href) && submenu.href !== ''
		);
		if (temp?.length > 0) {
			current = true;
		}
	}

	// const [isShowing, setIsShowing] = useState<any>(null);

	const collapse = useSelector(
		(state: RootStore) => state.layoutReducer
	).Collapse;

	const onSubItemClick = (_item: any) => {
		dispatch(setTableViewType({ viewType: 'table' }));
		if (collapse) {
			toggleCollapse();
		}
		// Mostly for collapse
		if (_item.onClick) {
			_item.onClick();
		}

		// If the item has sub items,
		if (_item.subItems) {
			setOpenTab(_item.href);
		} else {
			setOpenTab(null);
			history.push(_item.href);
		}

		// If we click the tab that is currently open, close it
		if (openTab === _item.href) {
			setOpenTab(null);
		}
	};

	const openOrCloseNestedItems = (subitem) => {
		if (!selectedSubMenuToShowChildItems)
			setSelectedSubMenuToShowChildItems(subitem);
		else setSelectedSubMenuToShowChildItems(null);
	};

	useEffect(() => {
		if (collapse) {
			setOpenTab(null);
		}
	}, [collapse]);
	return (
		<>
			<div className='w-auto'>
				<div
					onClick={() => onSubItemClick(item)}
					onMouseEnter={() => setMenuHover(true)}
					onMouseLeave={() => setMenuHover(false)}
					data-after-content={item.name}
					className={`cursor-pointer mb-3 relative transition delay-700 ease-in-out ${
						current
							? 'activenav text-white font-bold hover:shadow'
							: 'navitems cursor-pointer border-gray-light border text-gray-darkest hover:bg-gray-50 hover:shadow hover:text-gray-darkest'
					} ${!collapse && 'w-full md:w-48'} 
					${menuHover && collapse && 'hoverMe'}
				    group flex items-center h-10 justify-start rounded-r-full px-4 py-2 text-lg font-semibold `}
				>
					<FlatIcons iconName={item.icon} />
					{!collapse && (
						<p className='ml-4 text-gray-darkest hidden md:block'>{item.name}</p>
					)}
					<p className='ml-4 text-gray-darkest block md:hidden'>{item.name}</p>
				</div>
			</div>

			<Transition
				show={openTab === item.href}
				enter='transition ease-in-out duration-300 transform'
				enterFrom='-translate-x-full'
				enterTo='translate-x-0'
				leave='transition ease-in-out duration-300 transform'
				leaveFrom='translate-x-0'
				leaveTo='-translate-x-full'
			>
				<div className='sub-menu-block'>
					<div className='-mt-3 mb-8 pr-2 w-2/3 md:w-auto pb-2 -ml-3'>
						{item.subItems?.map((subitem: ISubItem, siIndex: number) => {
							const siCurrent = location.pathname.indexOf(subitem.href) > -1;
							const nestedSubItems: any = subitem?.subItems;
							return (
								<div
									key={siIndex}
									onClick={() => {
										if (subitem.name === '2-Way Communication') {
											openOrCloseNestedItems(subitem);
											return;
										}
										if (nestedSubItems) {
											setSelectedSubMenuToShowChildItems(null);
											setOpenTab(null);
											history.push(subitem.href);
											return;
										}
										if (!nestedSubItems) {
											onSubItemClick(subitem);
										}
									}}
									className={`transition duration-200 ease-in-out cursor-pointer ${
										siCurrent
											? 'text-leapbrown-600 font-bold'
											: 'cursor-pointer hover:text-leapbrown-400 hover:bg-gray-50 hover:shadow-inner hover:font-bolder'
									}  pl-8 py-1 text-lg font-medium text-gray-dark w-auto`}
								>
									<div className='flex justify-between items-center'>
										<h3 className='text-base font-semibold'>{subitem.name}</h3>
										{nestedSubItems && !selectedSubMenuToShowChildItems && (
											<Icon icon={IconTypes.Right} />
										)}
										{/* {nestedSubItems && selectedSubMenuToShowChildItems && (
											<Icon
												icon={IconTypes.Down}
												onClick={() => setSelectedSubMenuToShowChildItems(null)}
											/>
										)} */}
									</div>
									{subitem.name === '2-Way Communication' &&
										selectedSubMenuToShowChildItems?.name === subitem.name && (
											<div className='flex flex-col gap-2 absolute bg-gray-50 child-menu-block shadow-md'>
												{selectedSubMenuToShowChildItems.subItems.map((citem, cindex) => (
													<div
														className='flex flex-col gap-1 hover:bg-gray-100 p-2'
														key={cindex}
														onClick={() => {
															setSelectedSubMenuToShowChildItems(null);
															history.push(citem.href);
														}}
													>
														<div className='flex items-start'>
															{/* <AvatarComponent
															id='child-menu-avatar'
															alt='child-menu-avatar'
															content={citem.avatar}
															sx={{ width: 24, height: 24, textTransform: 'uppercase' }}
														/> */}
															<div className='ml-2 flex flex-col'>
																<h3 className='text-gray-darkest text-base font-semibold'>
																	{citem.name}
																</h3>
																{/* <p className='text-xs text-gray-400'>{citem.description}</p> */}
															</div>
														</div>
													</div>
												))}
											</div>
										)}
								</div>
							);
						})}
					</div>
				</div>
			</Transition>
		</>
	);
};

export interface INavPill {
	item: INavItem;
	openTab: string;
	setOpenTab: any;
}
export interface INavItem {
	name: string;
	href?: string;
	icon: any;
	subItems?: ISubItem[];
	onClick?: any;
}
export interface ISubItem {
	name: any;
	description?: any;
	avatar?: any;
	href: string;
	subItems?: ISubItem[];
}

export default NavPill;
