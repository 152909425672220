import {
	ProductDispatchTypes,
	SET_NEW_PRODUCT_DATA,
	SET_RESET_PRODUCT_DATA,
	SET_ALL_PRODUCTS_DATA,
	OPEN_NEW_PRODUCT_MODEL,
	OPEN_ALL_IMAGE_MODEL,
	OPEN_ALL_PRODUCT_MODEL,
	SET_PRODUCT_TAB_MODEL,
	SET_PRODUCT_ACTION_TYPE,
	SET_LINKED_CATEGORY_LIST,
	SET_LINKED_IMAGES_LIST,
} from './ProductActionTypes';

export interface IProduct {
	newProductData: any;
	productData: any;
	productModel: boolean;
	allProductModel: boolean;
	allImageModel: boolean;
	productTabModel: boolean;
	productActionType: string;
	linkedCategoryList: any;
	linkedImagesList: any;
}

const initialState: IProduct = {
	newProductData: {},
	productData: [],
	productModel: false,
	allProductModel: true,
	allImageModel: false,
	productTabModel: false,
	productActionType: '',
	linkedCategoryList: [],
	linkedImagesList: [],
};

const productReducer = (
	state: any = initialState,
	action: ProductDispatchTypes
): IProduct => {
	switch (action.type) {
		case SET_NEW_PRODUCT_DATA:
			return { ...state, newProductData: action.payload };
		case SET_RESET_PRODUCT_DATA:
			return { ...state, newProductData: {} };
		case SET_ALL_PRODUCTS_DATA:
			return { ...state, productData: action.payload };
		case OPEN_NEW_PRODUCT_MODEL:
			return { ...state, productModel: action.payload };
		case OPEN_ALL_IMAGE_MODEL:
			return { ...state, allImageModel: action.payload };
		case OPEN_ALL_PRODUCT_MODEL:
			return { ...state, allProductModel: action.payload };
		case SET_PRODUCT_TAB_MODEL:
			return { ...state, productTabModel: action.payload };
		case SET_PRODUCT_ACTION_TYPE:
			return { ...state, productActionType: action.payload };
		case SET_LINKED_CATEGORY_LIST:
			return { ...state, linkedCategoryList: action.payload };
		case SET_LINKED_IMAGES_LIST:
			return { ...state, linkedImagesList: action.payload };
		default:
			return state;
	}
};

export default productReducer;
