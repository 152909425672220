import React, { useState, useEffect, useImperativeHandle } from 'react';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
	AddExistingUserToOrganisation,
	SendLoginInfoToUser,
} from 'firebaseApis/users';
import Button from 'storybook-mui/build/components/Button';
import InputTextField from 'storybook-mui/build/components/TextField';
import CheckboxField from 'storybook-mui/build/components/Checkbox';
import Modal from 'storybook-mui/build/components/Modal';
import { Slide } from '@mui/material';
import { BasicRegister, GetAllCountries } from 'firebaseApis/authentication';
import { checkRecordExistsInMasterDB } from 'firebaseApis/common';
import { showLoading, hideLoading } from 'reducers/Alerts/AlertActions';
import CountrySelect from 'components/Phone/CountrySelect';
import PhoneNumber from 'components/Phone/PhoneNumber';
import LinearProgressBar from 'components/LinearProgressBar';
import { delay } from 'helpers/utils';
import { INewUser, IUserDataInLocalStorage } from './onboardInterfaces';

export const RegisterForm = ({
	onSubmit,
	formRef,
	validateOnChange,
	setSuccessMessage1,
	setSuccessMessage2,
	setShowSuccessMessage,
	setUserAdded,
}: IRegisterForm) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);
	const [passwordVisibility, setPasswordVisibility] = useState(true);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(true);
	const [passwordUpper, setPasswordUpper] = useState<boolean>(false);
	const [passwordLower, setPasswordLower] = useState<boolean>(false);
	const [passwordEightChar, setPasswordEightChar] = useState<boolean>(false);
	const [passwordNumberSymbol, setPasswordNumberSymbol] =
		useState<boolean>(false);
	const [passwordNewAndConfirmMatches, setPasswordNewAndConfirmMatches] =
		useState<boolean>(false);
	const [showTermsPolicyModal, setShowTermsPolicyModal] = useState(false);
	const [countries, setCountries] = useState<any>([]);
	const registerUser = async (values: INewUser) => {
		try {
			setLoading(true);
			const result = await BasicRegister(values);
			if (result.success) {
				const dataForLocalStorage: IUserDataInLocalStorage = {
					fName: values.FirstName,
					lName: values.LastName,
					email: values.Email,
					login: values.Login,
					userId: result.data.id,
					emailVerified: false,
					mobileType: values.select_Phone_Type,
					countryCode: values.phone_countryCode,
					phoneNumber: values.phoneNumber,
					landline_ext: values.landline_ext,
					email_optIn: values.email_optIn,
					sms_optIn: values.sms_optIn,
					voice_optIn: values.voice_optIn,
					userRegistrationType: 'basic',
				};
				const userType = values.outsideOrganization ? 'External' : 'Internal';
				const result2 = await AddExistingUserToOrganisation(
					dataForLocalStorage.userId,
					'',
					'',
					null,
					userType
				);
				if (result2.success) {
					if (values?.sendEmailToUser) {
						const data = {
							email: values.Email,
							FirstName: values.FirstName,
							Password: values.Password,
						};
						SendLoginInfoToUser(data);
					}
					setUserAdded(true);
					setSuccessMessage1(
						'The User has been created and a verification email has been sent to the provided email address.'
					);
					setSuccessMessage2(
						'The user can access your organization after the verification is complete.'
					);
					setShowSuccessMessage(true);
					setCurrentProgress(100);
					await delay(300);
					setLoading(false);
					setCurrentProgress(0);
				} else {
					Toast({ title: result2.message, type: ToastTypes.ERROR });
				}
			} else {
				setCurrentProgress(100);
				await delay(300);
				setLoading(false);
				setCurrentProgress(0);
				Toast({ title: result.message, type: ToastTypes.ERROR });
			}
		} catch (error) {
			setCurrentProgress(100);
			await delay(300);
			setLoading(false);
			setCurrentProgress(0);
			Toast({ title: 'Something Went Wrong', type: ToastTypes.ERROR });
		}
	};

	const registerForm = useFormik({
		initialValues: {
			FirstName: '',
			LastName: '',
			Email: '',
			Name: '',
			Password: '',
			ConfirmPassword: '',
			email_optIn: true,
			sms_optIn: true,
			voice_optIn: true,
			select_Phone_Type: 'Mobile',
			landline_ext: '',
			phone_countryCode: '+1',
			phone_countryId: 'US',
			phoneNumber: '',
			sendEmailToUser: true,
			outsideOrganization: false,
		},
		validationSchema: Yup.object().shape({
			FirstName: Yup.string()
				.required('Required')
				.matches(/^[^\\/?`"']*$/, 'Special characters are not allowed'),
			LastName: Yup.string()
				.required('Required')
				.matches(/^[^\\/?`"']*$/, 'Special characters are not allowed'),
			Email: Yup.string().required('Required').email('Invalid email'),
			Password: Yup.string()
				.required('Required')
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\s!@#$%^&*()_+=[\]{}|\\:;"'<>,.?/-])/,
					'Password must contain at least one lowercase letter, one uppercase letter, one number, symbol or whitespace character'
				)
				.min(8, 'Password must be at least 8 characters long'),

			ConfirmPassword: Yup.string()
				.required('Required')
				.oneOf([Yup.ref('Password')], 'Passwords must match'),
		}),
		validateOnChange,
		onSubmit: async (values, formikProps) => {
			const res = await checkRecordExistsInMasterDB(
				'Users',
				'Email',
				values.Email?.toLowerCase()
			);
			if (res?.recordExists) {
				registerForm.setFieldError(
					'Email',
					'The User email address you have entered already exists in Leap360. Please enter a different email address or select "Add Existing User" option to continue'
				);
			} else {
				formikProps.setSubmitting(false);
				if (values.ConfirmPassword === values.Password)
					registerUser({
						...registerForm.values,
						ConfirmPassword: '',
						UID: '',
						userRegistrationType: 'basic',
						ProfilePicUrl: '',
						Login: '',
					});
			}
		},
	});
	const validatepasswordAndConfirmPassword = (e) => {
		setPasswordNewAndConfirmMatches(false);
		if (registerForm.values.Password && e) {
			if (registerForm.values.Password === e) {
				setPasswordNewAndConfirmMatches(true);
			}
		}
	};
	const handlePasswordValidation = (e) => {
		setPasswordNewAndConfirmMatches(false);
		const { value } = e.target;
		if (registerForm.values.ConfirmPassword && value) {
			if (registerForm.values.ConfirmPassword === value) {
				setPasswordNewAndConfirmMatches(true);
			}
		}
		registerForm.setFieldValue('Password', value);
		const lowerCaseLetters = /[a-z]/g;
		if (value.match(lowerCaseLetters)) {
			setPasswordLower(true);
		} else {
			setPasswordLower(false);
		}
		const upperCaseLetters = /[A-Z]/g;
		if (value.match(upperCaseLetters)) {
			setPasswordUpper(true);
		} else {
			setPasswordUpper(false);
		}
		const numberSymbol = /[[!@#$%^&*()_+-={~`} :";'<>?,.\/|0-9]/g;
		if (value.match(numberSymbol)) {
			setPasswordNumberSymbol(true);
		} else {
			setPasswordNumberSymbol(false);
		}
		if (value.length >= 8) {
			setPasswordEightChar(true);
		} else {
			setPasswordEightChar(false);
		}
	};

	const getData = async () => {
		dispatch(showLoading());
		const result = await GetAllCountries();
		dispatch(hideLoading());
		const countriesResult: any = result.map((item) => ({
			value: `${item.country_id}`,
			label: item.country_name,
			states: item.states,
			countryId: item.country_id,
			phoneFormat: item.phone_format,
			phoneCode: item.phone_code,
			flagUrl: item.flag_svg_url,
		}));
		setCountries(countriesResult);
	};

	const onCountrySelectChange = (val) => {
		if (val) {
			const phCode = countries.find((item) => item.countryId === val).phoneCode;
			registerForm.setFieldValue('phone_countryCode', phCode);
			registerForm.setFieldValue('phone_countryId', val);
		} else {
			registerForm.setFieldValue('phone_countryCode', '');
			registerForm.setFieldValue('phone_countryId', '');
		}
	};

	useImperativeHandle(formRef, () => ({
		submitForm() {
			registerForm.handleSubmit();
		},
	}));

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			{loading && (
				<LinearProgressBar
					delay={16000}
					incrementBy={1}
					currentProgress={currentProgress}
					message='Adding user into organization...'
				/>
			)}

			<Slide direction='up' in mountOnEnter unmountOnExit>
				<div className='w-full md:w-3/4 lg:w-1/2 mx-auto flex flex-col'>
					<div className='w-full'>
						<form className='flex gap-4 flex-col lg:flex-row ' onSubmit={onSubmit}>
							<div className='flex flex-col w-full space-y-4'>
								<p>
									Enter all the details of the user you want to invite to your
									organization.
								</p>
								<div className='flex flex-col lg:flex-row lg:space-x-5 lg:space-y-0 space-y-4 px-4'>
									<InputTextField
										name='FirstName'
										fieldId='firstName'
										labelName='First name'
										value={registerForm.values.FirstName}
										onChange={registerForm.handleChange}
										autoComplete='given-name'
										errorMessage={registerForm.errors.FirstName}
										valid={!registerForm.errors.FirstName}
									/>
									<InputTextField
										name='LastName'
										fieldId='lastName'
										labelName='Last name'
										value={registerForm.values.LastName}
										onChange={registerForm.handleChange}
										autoComplete='family-name'
										errorMessage={registerForm.errors.LastName}
										valid={!registerForm.errors.LastName}
									/>
								</div>
								<div className='grid grid-cols-1 lg:grid-cols-3 px-4 gap-4'>
									<div className='lg:col-span-2 flex flex-col gap-4'>
										<InputTextField
											name='Email'
											fieldId='email'
											labelName='Email address'
											value={registerForm.values.Email}
											valid={registerForm.errors.Email === undefined}
											errorMessage={registerForm.errors.Email}
											onChange={registerForm.handleChange}
											autoComplete='email'
										/>
										<InputTextField
											autoComplete='new-password'
											name='Password'
											type={passwordVisibility ? 'password' : 'text'}
											fieldId='password'
											labelName='Password'
											value={registerForm.values.Password}
											errorMessage={registerForm.errors.Password}
											valid={registerForm.errors.Password === undefined}
											onChange={handlePasswordValidation}
											adornmentMessage={
												<IconButton
													aria-label='toggle password visibility'
													onClick={() => setPasswordVisibility(!passwordVisibility)}
													onMouseDown={(event) => event.preventDefault()}
												>
													{passwordVisibility ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											}
										/>
										<div>
											<InputTextField
												name='ConfirmPassword'
												type={confirmPasswordVisibility ? 'password' : 'text'}
												autoComplete='new-password'
												fieldId='ConfirmPassword'
												labelName='Confirm password'
												valid={registerForm.errors.ConfirmPassword === undefined}
												errorMessage={registerForm.errors.ConfirmPassword}
												value={registerForm.values.ConfirmPassword}
												onChange={(e) => {
													registerForm.setFieldValue('ConfirmPassword', e.target.value);
													validatepasswordAndConfirmPassword(e.target.value);
												}}
												adornmentMessage={
													<IconButton
														aria-label='toggle password visibility'
														onClick={() =>
															setConfirmPasswordVisibility(!confirmPasswordVisibility)
														}
														onMouseDown={(event) => event.preventDefault()}
													>
														{confirmPasswordVisibility ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												}
											/>
										</div>
									</div>
								</div>
								<div className='flex flex-col gap-1 px-4 py-4'>
									<div className='flex items-center gap-2'>
										{passwordEightChar ? (
											<CheckCircleIcon className=' text-green-500 font-bold' />
										) : (
											<CheckCircleIcon className='text-gray-lightest' />
										)}
										<p className='text-gray-darkest  text-sm'>
											Atleast 8 characters long
										</p>
									</div>
									<div className='flex items-center gap-2'>
										{passwordLower ? (
											<CheckCircleIcon className=' text-green-500 font-bold' />
										) : (
											<CheckCircleIcon className='text-gray-lightest' />
										)}
										<p className='text-gray-darkest  text-sm '>One lowercase character</p>
									</div>
									<div className='flex items-center gap-2'>
										{passwordUpper ? (
											<CheckCircleIcon className=' text-green-500 font-bold' />
										) : (
											<CheckCircleIcon className='text-gray-lightest' />
										)}
										<p className='text-gray-darkest  text-sm'>One uppercase character</p>
									</div>
									<div className='flex items-center gap-2'>
										{passwordNumberSymbol ? (
											<CheckCircleIcon className=' text-green-500 font-bold' />
										) : (
											<CheckCircleIcon className='text-gray-lightest' />
										)}
										<p className='text-gray-darkest text-sm'>
											One number, symbol or whitespace character
										</p>
									</div>
									<div className='flex items-center gap-2'>
										{passwordNewAndConfirmMatches ? (
											<CheckCircleIcon className=' text-green-500 font-bold' />
										) : (
											<CheckCircleIcon className='text-gray-lightest' />
										)}
										<p className='text-gray-darkest text-sm'>
											Password and confirm password must be the same
										</p>
									</div>
								</div>
								<div className='grid grid-cols-1 lg:grid-cols-3 lg:space-x-5 lg:space-y-0 space-y-4  w-full px-4'>
									{countries.length > 0 && (
										<CountrySelect
											countries={countries}
											countryId={registerForm.values.phone_countryId}
											setCountryId={onCountrySelectChange}
										/>
									)}
									<div className='lg:col-span-2'>
										<PhoneNumber
											mask={
												countries.find(
													(item) => item.countryId === registerForm.values.phone_countryId
												)?.phoneFormat
											}
											phoneValue={registerForm.values.phoneNumber}
											setPhoneValue={(val) =>
												registerForm.setFieldValue('phoneNumber', val)
											}
										/>
									</div>
								</div>
								{registerForm.values.select_Phone_Type === 'Landline' && (
									<div>
										<InputTextField
											labelName='Ext.'
											variant='outlined'
											value={registerForm.values.landline_ext}
											onChange={(e) =>
												registerForm.setFieldValue('landline_ext', e.target.value)
											}
											name='Ext'
											fieldId='Ext'
										/>
									</div>
								)}
								<div className='flex flex-col px-4'>
									<CheckboxField
										checked={registerForm.values.sendEmailToUser}
										labelName={
											<p>Send an email with the login credentials to the user</p>
										}
										name='sendEmailToUser'
										fieldId='send-email-to-user'
										onChange={registerForm.handleChange}
									/>

									<CheckboxField
										checked={registerForm.values.outsideOrganization}
										labelName={<p>This user is outside your organization</p>}
										name='outsideOrganization'
										fieldId='user-from-outside-organization'
										onChange={registerForm.handleChange}
									/>
								</div>
							</div>
						</form>

						<Modal
							size='xs'
							modalId='termsAndPolicy-modal'
							open={showTermsPolicyModal}
							setOpen={() => setShowTermsPolicyModal(!showTermsPolicyModal)}
							closeIcon
							title=''
							content={
								<div className='flex flex-col gap-4 pt-4'>
									<h1 className='font-semibold text-lg text-gray-600'>
										Terms & Policy content coming soon...
									</h1>
									<div className='flex justify-end'>
										<Button
											title='OK'
											size='small'
											onClick={() => setShowTermsPolicyModal(false)}
										/>
									</div>
								</div>
							}
						/>
					</div>
				</div>
			</Slide>
		</>
	);
};

export interface IRegisterForm {
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
	formRef: any;
	validateOnChange: boolean;
	setSuccessMessage1: (message: string) => void;
	setSuccessMessage2: (message: string) => void;
	setShowSuccessMessage: (show: boolean) => void;
	setUserAdded: (added: boolean) => void;
}
export default RegisterForm;
