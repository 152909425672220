import { formikValidationHelpers } from 'helpers/formikValidationHelpers';
import * as Yup from 'yup';

export const signupSchema = Yup.object().shape({
	Password: Yup.string()
		.required('Required')
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9])/,
			'Password must contain at least one letter and one number'
		)
		.min(8, 'Password must be at least 8 characters long'),
	Email: formikValidationHelpers.email,
	FirstName: formikValidationHelpers.stringRequiredWithSpecialCharctersExclude,
	LastName: formikValidationHelpers.stringRequiredWithSpecialCharctersExclude,
});

export const resetPasswordSchema = Yup.object().shape({
	Password: Yup.string()
		.required('Required')
		.matches(
			/^(?=.*[a-zA-Z])(?=.*[0-9])/,
			'Password must contain at least one letter and one number'
		)
		.min(8, 'Password must be at least 8 characters long'),
});

export const signinSchema = Yup.object().shape({
	Password: formikValidationHelpers.stringRequired,
	Email: formikValidationHelpers.email,
});
