// import { applyMiddleware, combineReducers, createStore } from 'redux';
import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

import userStore from './user';
import leadsStore from './Leads';
import creativesStore from './Creatives';
import layoutReducer from './Layout';
import newCampaignStore from './NewCampaign';
import socialCampaignStore from './NewCampaign/Social';

import alertsReducer from './Alerts/index';
import createOrgReducer from './CreateOrg/index';
import tableReducer from './Table';
import CompanyLogin from './CompanyLogin';
import brandManagementStore from './BrandManagement';
import emailBuilder from './EmailBuilder';
import WebsiteBuilder from './WebsiteBuilder';
import organizationStore from './Organization';
import authenticationStore from './Authentication';
import smsCreativeStore from './SMSCreatives';
import voiceCreativeStore from './VoiceCreatives';
import emailCreativeStore from './EmailCreatives';
import sentimentConfigStore from './SentimentConfigs';
import welcomeProgressBarReducer from './WelcomeProgressbar';
import dynamicStore from './Dynamic';
import bookingsReducer from './Bookings';
import productReducer from './Products';
import WebsiteBuilderSetup from './WebsiteBuilderSetup';

const rootStore = combineReducers({
	userStore,
	newCampaignStore,
	layoutReducer,
	leadsStore,
	alertsReducer,
	creativesStore,
	tableReducer,
	socialCampaignStore,
	CompanyLogin,
	brandManagementStore,
	emailBuilder,
	WebsiteBuilder,
	organizationStore,
	authenticationStore,
	smsCreativeStore,
	voiceCreativeStore,
	emailCreativeStore,
	sentimentConfigStore,
	welcomeProgressBarReducer,
	dynamicStore,
	createOrgReducer,
	bookingsReducer,
	productReducer,
	WebsiteBuilderSetup,
});
const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composedEnhancers = compose(middlewareEnhancer);
const store = createStore(rootStore, {}, composedEnhancers);

export type RootStore = ReturnType<typeof rootStore>;

export default store;
