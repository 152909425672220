export const SET_SMS_MAIN_MESSAGE = 'SET_SMS_MAIN_MESSAGE';
export const SET_SMS_MAIN_MESSAGE_HAVE_DYNAMIC =
	'SET_SMS_MAIN_MESSAGE_HAVE_DYNAMIC';
export const SET_SMS_VIEW = 'SET_SMS_VIEW';
export const RESET_SMS_CREATIVE = 'RESET_SMS_CREATIVE';
export const SET_SMS_DYNAMIC_VARIABLES = 'SET_SMS_DYNAMIC_VARIABLES';
export const SET_SMS_REPLY = 'SET_SMS_REPLY';
export const SET_SMS_RESPONSE = 'SET_SMS_RESPONSE';
export const SET_SMS_DISPLAY_MESSAGE = 'SET_SMS_DISPLAY_MESSAGE';
export const SET_SMS_MEDIA_URLS = 'SET_SMS_MEDIA_URLS';
export const SET_SMS_MAIN_MEDIA_URLS = 'SET_SMS_MAIN_MEDIA_URLS';
export const ADD_SMS_REPLY = 'ADD_SMS_REPLY';
export const SET_SMS_NODE_ID = 'SET_SMS_NODE_ID';
export const SET_SMS_TREE_DATA = 'SET_SMS_TREE_DATA';
export const SET_SMS_SELECTED_CATEGORY = 'SET_SMS_SELECTED_CATEGORY';
export const SET_SMS_SELECTED_SUBCATEGORY = 'SET_SMS_SELECTED_SUBCATEGORY';
export const SET_SMS_CATEGORIES = 'SET_SMS_CATEGORIES';
export const SET_SMS_CREATIVE_NAME = 'SET_SMS_CREATIVE_NAME';
export const RESET_ALL_SMS_FILTERS = 'RESET_ALL_SMS_FILTERS';
export const SET_SMS_SEARCH_TEXT = 'SET_SMS_SEARCH_TEXT';
export const SET_SMS_FILTER = 'SET_SMS_FILTER';
export const SET_SMS_GRID_VIEW = 'SET_SMS_GRID_VIEW';
export const SET_SMS_CREATIVE = 'SET_SMS_CREATIVE';
export const SET_SMS_SHOW_DELETE_MODAL = 'SET_SMS_SHOW_DELETE_MODAL';
export const SET_SMS_CREATIVES = 'SET_SMS_CREATIVES';
export const SET_SMS_LAST_VISIBLE = 'SET_SMS_LAST_VISIBLE';
export const SET_SMS_FIRST_VISIBLE = 'SET_SMS_FIRST_VISIBLE';
export const SET_SMS_PAGE_NUM = 'SET_SMS_PAGE_NUM';
export const SET_SMS_PAGE_SIZE = 'SET_SMS_PAGE_SIZE';
export const SET_SMS_TOTAL_ROWS = 'SET_SMS_TOTAL_ROWS';
export const SET_SMS_TOTAL_PAGES = 'SET_SMS_TOTAL_PAGES';
export const SET_SMS_FILTER_MODAL = 'SET_SMS_FILTER_MODAL';
export const SET_SMS_MODAL_IMAGE = 'SET_SMS_MODAL_IMAGE';
export const SET_SMS_INTERNAL_CATEGORIES = 'SET_SMS_INTERNAL_CATEGORIES';
export const SET_SMS_MAIN_CATEGORY_SAVE_ACTIONS =
	'SET_SMS_MAIN_CATEGORY_SAVE_ACTIONS';
export const SET_SMS_CATEGORY_SAVE_ACTIONS = 'SET_SMS_CATEGORY_SAVE_ACTIONS';
export const SET_SMS_INTERNAL_SELECTED_CATEGORIES =
	'SET_SMS_INTERNAL_SELECTED_CATEGORIES';
export const SET_SMS_ADD_CAT_DATA = 'SET_SMS_ADD_CAT_DATA';
export const SET_SMS_DELETE_DATA = 'SET_SMS_DELETE_DATA';
export const SET_SMS_OPEN_MODAL = 'SET_SMS_OPEN_MODAL';
export const SET_SMS_MODAL_TYPE = 'SET_SMS_MODAL_TYPE';
export const SET_SMS_NEW_CATEGORY = 'SET_SMS_NEW_CATEGORY';
export const SET_SMS_PRODUCTS = 'SET_SMS_PRODUCTS';

