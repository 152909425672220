import Slide from '@mui/material/Slide';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'storybook-mui/build/components/Button';
import Icon from 'storybook-mui/build/components/Icon';
import { IconTypes } from 'storybook-mui/build/components/IconTypes';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import ChipComponent from 'storybook-mui/build/components/Chip';
import { INewOrgInitialState } from 'reducers/CreateOrg';
import { SetNewOrgFlowStep } from 'reducers/CreateOrg/CreateOrgActions';

function OrgReviewDetails() {
	const dispatch = useDispatch();
	const newOrgStore: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);
	useEffect(() => {
		dispatch(increaseProgressVal(80));
	}, []);
	const organization =
		newOrgStore.Org_Flow_Type === 'SUB' ? 'Sub Organization' : 'Organization';
	return (
		<div className='w-full org-review-ct'>
			<Slide direction='left' in mountOnEnter unmountOnExit>
				<div className='flex flex-col gap-2 items-center justify-center pb-5'>
					<div>
						<h2 className='text-2xl text-center lg:text-left lg:text-4xl font-bold text-primary-800 uppercase'>
							Review your {organization} details
						</h2>
					</div>
					<div className='w-full bg-white py-6 mb-10'>
						<div className='w-full mx-auto flex flex-col gap-3 lg:gap-6'>
							<div className='flex justify-center gap-6'>
								<label className='w-44 lg:text-2xl text-gray-500 font-light uppercase text-right'>
									Account type:
								</label>
								<div className='flex gap-3 w-44'>
									<h4 className='lg:text-2xl text-gray-800 font-bold uppercase'>
										{newOrgStore.Org_Data.Account_Type}
									</h4>
									<div
										className='rounded-full w-6 h-6 p-3 lg:w-8 lg:h-8 lg:p-5 flex justify-center items-center bg-info-500 border-info-50 text-white text-center  cursor-pointer'
										onClick={() => {
											dispatch(SetNewOrgFlowStep(0));
										}}
									>
										<Icon icon={IconTypes.Edit} className='text-sm lg:text-2xl' />
									</div>
								</div>
							</div>
							<div className='flex justify-center gap-3 lg:gap-6'>
								<label className='w-44 lg:text-2xl text-gray-500 font-light uppercase text-right'>
									Industry:
								</label>
								<div className='flex gap-3 w-44'>
									<h4 className='lg:text-2xl text-gray-800 font-bold uppercase lg:whitespace-nowrap'>
										{JSON.parse(newOrgStore.Org_Data.Industry).label}
									</h4>
									<div
										className='rounded-full w-6 h-6 p-3 lg:w-8 lg:h-8 lg:p-5 flex justify-center items-center bg-info-500 border-info-50 text-white text-center  cursor-pointer'
										onClick={() => {
											dispatch(SetNewOrgFlowStep(1));
										}}
									>
										<Icon icon={IconTypes.Edit} className='text-sm lg:text-2xl' />
									</div>
								</div>
							</div>
							<div className='flex justify-center gap-6'>
								<label className='w-44 lg:text-2xl text-gray-500 font-light uppercase text-right'>
									Name:
								</label>
								<div className='flex gap-3 w-44'>
									<h4 className='lg:text-2xl text-gray-800 font-bold uppercase lg:whitespace-nowrap'>
										{newOrgStore.Org_Data.Org_Name}
									</h4>
									<div
										className='rounded-full w-6 h-6 p-3 lg:w-8 lg:h-8 lg:p-5 flex justify-center items-center bg-info-500 border-info-50 text-white text-center  cursor-pointer'
										onClick={() => {
											dispatch(SetNewOrgFlowStep(2));
										}}
									>
										<Icon icon={IconTypes.Edit} className='text-sm lg:text-2xl' />
									</div>
								</div>
							</div>
							<div className='flex justify-center gap-6'>
								<label className='w-44 lg:text-2xl text-gray-500 font-light uppercase text-right'>
									Location:
								</label>
								<div className='flex gap-3 w-44'>
									<h4 className='lg:text-2xl text-gray-800 font-bold uppercase lg:whitespace-nowrap'>
										{JSON.parse(newOrgStore.Org_Data.Country).label}
									</h4>
									<div
										className='rounded-full w-6 h-6 p-3 lg:w-8 lg:h-8 lg:p-5 flex justify-center items-center bg-info-500 border-info-50 text-white text-center  cursor-pointer'
										onClick={() => {
											dispatch(SetNewOrgFlowStep(3));
										}}
									>
										<Icon icon={IconTypes.Edit} className='text-sm lg:text-2xl' />
									</div>
								</div>
							</div>
							<div className='flex justify-center gap-10 py-3'>
								<h4 className='text-2xl xl:text-3xl text-gray-800 uppercase'>
									Interests
								</h4>
								<h1
									className='text-xl xl:text-2xl uppercase trxt-gray-300 underline cursor-pointer'
									onClick={() => {
										dispatch(SetNewOrgFlowStep(4));
									}}
								>
									change
								</h1>
							</div>
							<div className='flex justify-center gap-4'>
								{newOrgStore?.Org_Data.Features_Interested?.map((feature) => (
									<ChipComponent
										key={feature}
										id={feature}
										size='medium'
										label={
											<h3 className='text-base xl:text-xl text-gray-800 uppercase'>
												{feature}
											</h3>
										}
									/>
								))}
							</div>
						</div>
					</div>
					<div className='flex items-center gap-4'>
						<Button
							size='medium'
							title={
								<h3 className='text-xl xl:text-2xl font-thin tracking-wider'>
									Confirm & Continue
								</h3>
							}
							onClick={() => dispatch(SetNewOrgFlowStep(newOrgStore.Step + 1))}
						/>
						<h3 className='text-2xl text-gray-600'>Final step ahead!</h3>
					</div>
				</div>
			</Slide>
		</div>
	);
}

export default OrgReviewDetails;
