import {
	ADD_VOICE_REPLY,
	RESET_VOICE_CREATIVE,
	SET_VOICE_MAIN_MESSAGE,
	SET_VOICE_NODE_ID,
	SET_VOICE_REPLY,
	SET_VOICE_RESPONSE,
	SET_VOICE_TREE_DATA,
	SET_VOICE_VIEW,
	SET_VOICE_SPEAKER_LANGUAGES,
	SET_VOICE_SPEAKERS,
	SET_VOICE_CREATIVE_NAME,
	SET_VOICE_SELECTED_CATEGORY,
	SET_VOICE_CATEGORIES,
	SET_VOICE_SELECTED_SUBCATEGORY,
	SET_VOICE_MAIN_MESSAGE_HAVE_DYNAMIC,
	SET_VOICE_SEARCH_TEXT,
	RESET_ALL_VOICE_FILTERS,
	SET_VOICE_FILTER,
	SET_VOICE_GRID_VIEW,
	SET_VOICE_CREATIVE,
	SET_VOICE_SHOW_DELETE_MODAL,
	SET_VOICE_CREATIVES,
	SET_VOICE_LAST_VISIBLE,
	SET_VOICE_FIRST_VISIBLE,
	SET_VOICE_PAGE_SIZE,
	SET_VOICE_PAGE_NUM,
	SET_VOICE_TOTAL_ROWS,
	SET_VOICE_TOTAL_PAGES,
	SET_VOICE_INTERNAL_CATEGORIES,
	SET_VOICE_CATEGORY_SAVE_ACTIONS,
	SET_VOICE_MAIN_CATEGORY_SAVE_ACTIONS,
	SET_VOICE_INTERNAL_SELECTED_CATEGORIES,
	SET_VOICE_ADD_CAT_DATA,
	SET_VOICE_DELETE_DATA,
	SET_VOICE_OPEN_MODAL,
	SET_VOICE_MODAL_TYPE,
	SET_VOICE_NEW_CATEGORY,
	SET_VOICE_FILTER_MODAL,
	SET_VOICE_MODAL_IMAGE,
	SET_VOICE_PHONE_ERROR,
} from './VoiceCreativesActionTypes';

interface ISMSCreative {
	voiceTreeData: any;
	view: number;
	voiceReplies: [];
	voiceReply: string;
	voiceResponse: any;
	id: string;
	currentId: string;
	mainMessage: any;
	voiceSpeakerLanguages: [];
	voiceSpeakers: [];
	mainMessageHaveDynamicVariable: boolean;
	voiceFilters: {};
}

// Default Settings
export const voiceSettings = {
	response_voice: 'Polly.Matthew-Neural',
	voiceSpeakerUrl:
		'https://storage.googleapis.com/internal_dev_voice_file/en_usa/Matthew-Neural.m4a',
	response_language: 'en-US',
	if_no_input_response: [],
	if_wrong_input_response: [],
	input_mode: '',
	input_timeout: 5,
	sameSettings: true,
	speech: true,
	dtmf: true,
};
const initialState: ISMSCreative = {
	voiceTreeData: { children: [], ...voiceSettings },
	view: 1,
	voiceReplies: [],
	voiceReply: '',
	voiceResponse: [],
	id: '',
	currentId: '',
	mainMessage: [],
	voiceSpeakerLanguages: [],
	voiceSpeakers: [],
	mainMessageHaveDynamicVariable: false,
	voiceFilters: {
		searchText: '',
		filter: false,
		gridView: false,
		creative: {},
		showDeleteModal: false,
		voiceCreatives: [],
		lastVisible: {},
		firstVisible: {},
		pageSize: 25,
		pageNum: 0,
		totalRows: 0,
		totalPages: 0,
		internalCategories: [],
		categorySaveActions: {},
		mainCategorySaveActions: {},
		selectedInternalCategories: [],
		addCatData: {},
		deleteData: {},
		openModal: false,
		modalType: '',
		newCategory: '',
		filterModal: {},
		modalImage: '',
	},
};

const voiceCreativesReducer = (state: any = initialState, action) => {
	switch (action.type) {
		case SET_VOICE_RESPONSE:
			return { ...state, voiceResponse: action.payload };
		case SET_VOICE_PHONE_ERROR:
			return { ...state, voicePhoneError: action.payload };
		case SET_VOICE_MAIN_MESSAGE:
			return { ...state, mainMessage: action.payload };
		case SET_VOICE_MAIN_MESSAGE_HAVE_DYNAMIC:
			return { ...state, mainMessageHaveDynamicVariable: action.payload };

		case SET_VOICE_TREE_DATA:
			return { ...state, voiceTreeData: action.payload };
		case SET_VOICE_VIEW:
			return { ...state, view: action.payload };
		case SET_VOICE_REPLY:
			return { ...state, voiceReply: action.payload };
		case SET_VOICE_NODE_ID:
			return { ...state, currentId: action.payload };
		case ADD_VOICE_REPLY:
			return { ...state, voiceReplies: action.payload };
		case SET_VOICE_SPEAKER_LANGUAGES:
			return { ...state, voiceSpeakerLanguages: action.payload };
		case SET_VOICE_SPEAKERS:
			return { ...state, voiceSpeakers: action.payload };
		case SET_VOICE_CREATIVE_NAME:
			return { ...state, creativeName: action.payload };
		case SET_VOICE_SELECTED_CATEGORY:
			return { ...state, selectedCategory: action.payload };
		case SET_VOICE_CATEGORIES:
			return { ...state, categories: action.payload };
		case SET_VOICE_SELECTED_SUBCATEGORY:
			return { ...state, selectedSubCategory: action.payload };

		case SET_VOICE_SEARCH_TEXT:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, searchText: action.payload },
			};
		case SET_VOICE_FILTER:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, filter: action.payload },
			};
		case SET_VOICE_GRID_VIEW:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, gridView: action.payload },
			};
		case SET_VOICE_CREATIVE:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, creative: action.payload },
			};
		case SET_VOICE_SHOW_DELETE_MODAL:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, showDeleteModal: action.payload },
			};
		case SET_VOICE_CREATIVES:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, voiceCreatives: action.payload },
			};
		case SET_VOICE_LAST_VISIBLE:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, lastVisible: action.payload },
			};
		case SET_VOICE_FIRST_VISIBLE:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, firstVisible: action.payload },
			};
		case SET_VOICE_PAGE_SIZE:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, pageSize: action.payload },
			};
		case SET_VOICE_PAGE_NUM:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, pageNum: action.payload },
			};
		case SET_VOICE_TOTAL_ROWS:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, totalRows: action.payload },
			};
		case SET_VOICE_TOTAL_PAGES:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, totalPages: action.payload },
			};
		case SET_VOICE_INTERNAL_CATEGORIES:
			return {
				...state,
				voiceFilters: { ...state.voiceFilters, internalCategories: action.payload },
			};
		case SET_VOICE_CATEGORY_SAVE_ACTIONS:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					categorySaveActions: action.payload,
				},
			};
		case SET_VOICE_MAIN_CATEGORY_SAVE_ACTIONS:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					mainCategorySaveActions: action.payload,
				},
			};
		case SET_VOICE_INTERNAL_SELECTED_CATEGORIES:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					selectedInternalCategories: action.payload,
				},
			};
		case SET_VOICE_ADD_CAT_DATA:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					addCatData: action.payload,
				},
			};
		case SET_VOICE_DELETE_DATA:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					deleteData: action.payload,
				},
			};
		case SET_VOICE_OPEN_MODAL:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					openModal: action.payload,
				},
			};
		case SET_VOICE_MODAL_TYPE:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					modalType: action.payload,
				},
			};
		case SET_VOICE_NEW_CATEGORY:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					newCategory: action.payload,
				},
			};
		case SET_VOICE_FILTER_MODAL:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					filterModal: action.payload,
				},
			};
		case SET_VOICE_MODAL_IMAGE:
			return {
				...state,
				voiceFilters: {
					...state.voiceFilters,
					modalImage: action.payload,
				},
			};
		case RESET_ALL_VOICE_FILTERS:
			return { ...state, voiceFilters: initialState.voiceFilters };
		case RESET_VOICE_CREATIVE:
			return {
				voiceTreeData: { children: [], ...voiceSettings },
				mainMessageHaveDynamicVariable: false,
				view: 1,
				voiceReplies: [],
				voiceReply: '',
				voiceResponse: [],
				id: '',
				currentId: '',
				mainMessage: [],
				voiceSpeakerLanguages: [],
				voiceSpeakers: [],
				voiceFilters: state.voiceFilters,
			};
		default:
			return state;
	}
};

export default voiceCreativesReducer;
