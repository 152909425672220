export const SET_AUTHENTICAION_FLOW_VIEW = 'SET_AUTHENTICAION_FLOW_VIEW';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_ACCOUNT_INFO = 'SET_USER_ACCOUNT_INFO';
export const SET_AUTHENTICATION_TYPE = 'SET_AUTHENTICATION_TYPE';
export const CANCEL_ORG_CREATON_FLOW = 'CANCEL_ORG_CREATON_FLOW';

export interface SET_AUTHENTICAION_FLOW_VIEW {
	type: typeof SET_AUTHENTICAION_FLOW_VIEW;
	payload: number;
}

export interface SET_AUTHENTICATION_TYPE {
	type: typeof SET_AUTHENTICATION_TYPE;
	payload: string;
}

export interface SET_USER_INFO {
	type: typeof SET_USER_INFO;
	payload: any;
}
export interface SET_USER_ACCOUNT_INFO {
	type: typeof SET_USER_ACCOUNT_INFO;
	payload: any;
}
export interface CANCEL_ORG_CREATON_FLOW {
	type: typeof CANCEL_ORG_CREATON_FLOW;
	payload: any;
}
export type AuthDispatchTypes =
	| SET_AUTHENTICATION_TYPE
	| SET_AUTHENTICAION_FLOW_VIEW
	| SET_USER_INFO
	| SET_USER_ACCOUNT_INFO
	| CANCEL_ORG_CREATON_FLOW
