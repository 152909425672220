import { collection, getDocs, query, where } from 'firebase/firestore';
import { masterDb } from 'firebaseConfig';

const GooglemapApi = 'https://maps.googleapis.com/maps/api';

export const getGeoLocation = async (lat: any, long: any) => {
	const siteConfigList = await getDocs(
		query(
			collection(masterDb, 'Site_Config'),
			where('Constant', '==', 'MAPAPIKEY')
		)
	);
	const resultArray = siteConfigList.docs.map((document: any) => ({
		id: document.id,
		...document.data(),
	}));
	const GOOGLE_MAP_KEY =
		resultArray.length > 0 ? resultArray[0]?.Constant_Value?.bucket : '';

	const response = await fetch(
		`${GooglemapApi}/geocode/json?latlng=${lat},${long}&key=${GOOGLE_MAP_KEY}`
	);
	return response.json();
};

export const getTimeZoneOfGeoLocation = async (
	lat: any,
	long: any,
	timestamp: any
) => {
	const siteConfigList = await getDocs(
		query(
			collection(masterDb, 'Site_Config'),
			where('Constant', '==', 'MAPAPIKEY')
		)
	);
	const resultArray = siteConfigList.docs.map((document: any) => ({
		id: document.id,
		...document.data(),
	}));
	const GOOGLE_MAP_KEY =
		resultArray.length > 0 ? resultArray[0]?.Constant_Value?.bucket : '';
	const response: any =await fetch(
		`${GooglemapApi}/timezone/json?location=${lat},${long}&timestamp=${timestamp}&key=${GOOGLE_MAP_KEY}`
	);
	return response.json();
};
