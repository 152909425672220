import axios from 'axios';
import firebase from 'firebase/compat/app';

export interface ISiteConfigConstants {
	REACT_APP_AUTHORIZATION: string;
	REACT_APP_BASE_URL: string;
	REACT_APP_TWO_WAY_AUTHENTICATION: string;
	REACT_APP_CLOUD_URL: string;
	REACT_APP_DELETE_ORGANIZATION: string;
	REACT_APP_EMAIL_URL: string;
	REACT_APP_ImageEditor: string;
	REACT_APP_MODE: string;
	REACT_APP_SMS_URL: string;
	REACT_APP_SOCIAL_FEEDS_URL: string;
	REACT_APP_SOCIAL_URL: string;
	REACT_APP_TWILIO_APIS_URL: string;
	REACT_APP_VERIFONE_ENCRYPTION_KEY: string;
	REACT_APP_VOICE_URL: string;
	REACT_APP_TRIGGER_URL: string;
	REACT_APP_SMS_CHAT_URL: string;
	REACT_APP_SOCIAL_LOGIN_URL: string;
	REACT_APP_POS_DOMAIN: string;
	REACT_APP_LOGOUT_REDIRECT_URL: string;
	REACT_APP_BILLING_API: string;
	REACT_APP_CREATE_ORGANIZATION_PAYMENT_API: string;
	ORGANIZATION_ASSOCIATED_WITH_DOMAIN: string;
	REACT_APP_EXPORT_BIGQUERY: string;
	// pos cloud runs
	REACT_APP_POS_CATALOG: string;

	REACT_APP_POS_STORE: string;

	REACT_APP_ECOMMERCE_SHIPPING_URL: string;
	VERIFONE_CLIENT_ECOM_PAYMENT: string;
	REACT_APP_MISCELLANEOUS_URL: string;
	REACT_APP_BOOKING_URL: string;
	REACT_SUSPEND_REACTIVATE_TWILIO_SENDGRID_ACCOUNT: string;
	REACT_APP_DYNAMIC_SEARCH: string;
	REACT_APP_SEND_EMAIL: string;
	REACT_APP_VERIFY_AUTH_USER: string;
	REACT_APP_VERIFONE_PAYMENT_API;
}
export const siteConfigConstants = (): ISiteConfigConstants =>
	JSON.parse(localStorage.getItem('siteConfig')) ?? {};

export const cloudBaseUrl = (): string =>
	siteConfigConstants().REACT_APP_CLOUD_URL;

export const setUser = (value: string) =>
	localStorage.setItem('user', JSON.stringify(value));

export const getUser = () => {
	const userString = localStorage.getItem('user');
	if (userString) {
		return JSON.parse(userString);
	}
	return {};
};
export const getUserName = () => {
	const userString = localStorage.getItem('user');
	if (userString) {
		const user = JSON.parse(userString);
		return `${user.fName} ${user.lName}`;
	}
	return {};
};
export const getOrgnaizationId = () => getUser().organizationPath;

export const getOrganisationIdFirebase = () =>
	getUser().organisationPathFirebase;

export const getAuthUserName = () => `${getUser().fName} ${getUser().lName}`;

export const getUserId = () => getUser().userId;
export const getClientDB = () => {
	const user = JSON.parse(localStorage.getItem('user'));
	const clientApp = firebase.initializeApp(
		user.firestoreConfig,
		user.organisationPathFirebase
	);
	const clientDB = clientApp.firestore();
	return clientDB;
};

export const getXProjKey = () => {
	const user = JSON.parse(localStorage.getItem('user'));
	return user?.firestoreConfig ? user?.firestoreConfig['x-proj-key'] : '';
};

export const setToken = (value: string) => localStorage.setItem('token', value);
export const getToken = () => localStorage.getItem('token');
export const clear = () => localStorage.clear();

export const create2WayAuth = () => {
	axios.defaults.headers = {
		Authorization: siteConfigConstants().REACT_APP_AUTHORIZATION,
		'x-proj-key': getXProjKey(),
		'organization-id': getOrganisationIdFirebase(),
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_TWO_WAY_AUTHENTICATION,
	});
};

export const create = () => {
	axios.defaults.headers = {
		Authorization: siteConfigConstants().REACT_APP_AUTHORIZATION,
		'x-proj-key': getXProjKey(),
		'organization-id': getOrganisationIdFirebase(),
		organization_id: getOrganisationIdFirebase(),
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_BASE_URL,
	});
};

export const createOnlyWithOrgId = () => {
	axios.defaults.headers = {
		'organization-id': getOrganisationIdFirebase(),
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_BASE_URL,
	});
};
export const createInstanceWithArgs = (
	baseUrl,
	appAuthorization,
	xProjKey,
	orgId
) => {
	axios.defaults.headers = {
		Authorization: appAuthorization,
		'x-proj-key': xProjKey,
		'organization-id': orgId,
	};
	return axios.create({
		baseURL: baseUrl,
	});
};
export const createAxiosForPhoneNumberPurchase = (apikey) => {
	axios.defaults.headers = {
		Authorization: siteConfigConstants().REACT_APP_AUTHORIZATION,
		'x-api-key': apikey,
		'organization-id': getOrganisationIdFirebase(),
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_BASE_URL,
	});
};

export const createAxiosForPayment = () => {
	axios.defaults.headers = {
		Authorization:
			'Basic OWQ1MzdjNTgtMzVjMi00MjJlLWFjMGEtMDUwNWQwOWMwZTE5OlV2RVFTbFdSRVVnUm9XTkh3eGZyUWFFYnJZR0FUYlBCeHBiZg==',
		Accept: 'application/json',
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_BASE_URL,
	});
};

export const createCloud = () => {
	axios.defaults.headers = {
		Authorization: siteConfigConstants().REACT_APP_AUTHORIZATION,
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_CLOUD_URL,
	});
};

export const createCloudWithProjKey = () => {
	axios.defaults.headers = {
		Authorization: siteConfigConstants().REACT_APP_AUTHORIZATION,
		'x-proj-key': getXProjKey(),
		organization_id: getOrganisationIdFirebase(),
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_CLOUD_URL,
	});
};

export const createCloudRun = (url) => {
	axios.defaults.headers = {
		'x-proj-key': getXProjKey(),
		organization_id: getOrganisationIdFirebase(),
	};
	return axios.create({
		baseURL: url,
	});
};
export const createWithXProjKey = () => {
	axios.defaults.headers = {
		'x-proj-key':
			'U2FsdGVkX1+Kv7Yddvn8camjNSc48B0x5qujEr4A2oDIaHNuKEUO2svCBvGNtHKX7VfQk4T1JiwRbLEsihWr7MV33V2v5rl+OIIPWBNFr5KAtJFfardwf7xVCBWOSPfkLu8jTFMWxXvWaUqfiME8UA==',
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_BASE_URL,
	});
};

export const createWithXProjKeyWithoutUserid = () => {
	axios.defaults.headers = {
		'x-proj-key':
			'U2FsdGVkX1+c8wHNAnfN2+uinoBThuk3c+phVLkq4E/uCix07kJ6J2HmczqOWW2MszyxP4ey+WNTl231/PEWmD5k5N2uCpkGgkpFjrYwIafTHcf/7puiqLv3j3+z617BD6Be8tCt+r87DaIuHKnaYoHmRDzklobsujXHVgXiwaQ=',
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_CLOUD_URL,
	});
};
export const createWithXProjKeyWithoutUseridDateFormates = () => {
	axios.defaults.headers = {
		'x-proj-key':
			'U2FsdGVkX1/XThBn9v/amhiafmq82fTUApPKfSCouzPv8oxFdK2S+gEL+5kbKwEpofAEMQPmouQaRpREwFSwZfbQxUxJfVkRDU5dJaBh7RdMj4/3XcwifD3u9kNkgzJZzocAhcbrXlc8oDZMIb59WlFX9iiuEOiXd+2mk04oRm6IWkQOSHpwhEq6zh/aypJo',
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_CLOUD_URL,
	});
};

export const createWithXProjKeyConfigurations = () => {
	axios.defaults.headers = {
		Authorization: siteConfigConstants().REACT_APP_AUTHORIZATION,
		created_by_user_id: '4185c01b192b8788e4d35da4c688363c',
		last_modified_by_user_id: '4185c01b192b8788e4d35da4c688363c',
		'x-proj-key':
			'U2FsdGVkX1/XThBn9v/amhiafmq82fTUApPKfSCouzPv8oxFdK2S+gEL+5kbKwEpofAEMQPmouQaRpREwFSwZfbQxUxJfVkRDU5dJaBh7RdMj4/3XcwifD3u9kNkgzJZzocAhcbrXlc8oDZMIb59WlFX9iiuEOiXd+2mk04oRm6IWkQOSHpwhEq6zh/aypJo',
	};
	return axios.create({
		baseURL: siteConfigConstants().REACT_APP_CLOUD_URL,
	});
};
export const axiosInstance = () => axios;
