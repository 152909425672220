import MUIGridGlobal from 'components/Table/MUIGlobalGrid';

import { hideLoading, showLoading } from 'reducers/Alerts/AlertActions';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
	GetPosPaymentTerminals,
	RefreshPosPaymentTerminals,
} from 'firebaseApis/pos_payment_terminals';
import Button from 'storybook-mui/build/components/Button';
import PageHeading from 'storybook-mui/build/components/PageHeading';

function PaymentTerminals() {
	const dispatch = useDispatch();

	const [paymentTerminals, setPaymentTerminals] = useState<any>([]);

	const getPaymentTerminals = async () => {
		dispatch(showLoading());
		const result = await GetPosPaymentTerminals();
		setPaymentTerminals(result.data ?? []);
		dispatch(hideLoading());
	};

	const refreshTerminals = async () => {
		dispatch(showLoading());
		await RefreshPosPaymentTerminals();
		getPaymentTerminals();
		dispatch(hideLoading());
	};

	const columns = [
		{
			headerName: 'PAYMENT TERMINAL ID',
			field: 'Payment_Terminal_Id',
			flex: 1,
			minWidth: 200,
		},
		{
			headerName: 'POI STATE',
			field: 'POIState',
			flex: 1,
			minWidth: 100,
		},
		{
			headerName: 'SERIAL NUMBER',
			field: 'serialNumber',
			flex: 1,
			minWidth: 150,
		},
	];

	useEffect(() => {
		getPaymentTerminals();
	}, []);

	return (
		<>
			<PageHeading menuName='POS' pageTitle='Payment Terminals' />

			<div className='flex items-center justify-end my-2 lg:-mt-10 mt-4 mb-4'>
				<Button
					title='Refresh'
					onClick={() => refreshTerminals()}
					variant='contained'
				/>
			</div>

			<div>
				<MUIGridGlobal
					columns={columns}
					dataSource={paymentTerminals ?? []}
					rowCount={paymentTerminals?.length}
					noRecordsText='No Payment Terminals Found'
				/>
			</div>
		</>
	);
}

export default PaymentTerminals;
