import React from 'react';
import './style.scss';
import Leap360LoaderGif from 'assets/img/leapspin.gif';

export const Spinner = () => (
	<div
		className='fixed top-0 right-0 left-0 bottom-0 z-50 bg-blackRgba bg-opacity-50'
		style={{ zIndex: 99999 }}
	>
		<div className='fixed left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 z-50'>
			<img src={Leap360LoaderGif} className=' ease-linear h-52 w-52' />
		</div>
	</div>
);
