import React from 'react';
import Button from 'storybook-mui/build/components/Button';
import cogwheel from '../../assets/img/cogwheel.png';

export interface IHistory {
	history: any;
}

export const Error404Page = ({ history }: IHistory) => (
	<div className='bg-white bg-opacity-25 '>
		<div className='w-full m-auto py-5 min-h-screen flex items-center justify-center'>
			<div className='bg-white shadow overflow-hidden sm:rounded-lg pb-8'>
				<div className='border-t border-gray-200 text-center pt-8'>
					<div className='flex'>
						<img
							src={cogwheel}
							alt='b2c'
							className='object-contain p-2 animate-spin mt-14 -mr-8 opacity-20 w-20 h-20 cursor-default'
						/>
						<img
							src={cogwheel}
							alt='b2c'
							className='object-contain p-2 animate-spin-rev opacity-20 w-32 h-32 cursor-default'
						/>
					</div>

					<div className='flex justify-center'>
						<h1 className='text-6xl md:text-9xl font-black text-yellow-700 tracking-widest '>
							404
						</h1>
					</div>

					<h1 className='text-xl md:text-5xl font-medium py-8 text-gray-700'>
						oops! Page not found
					</h1>
					<p className='text-sm  md:text-xl pb-8 px-12'>
						The page you are looking for does not exist. It might have been moved or
						deleted.
					</p>

					<Button
					size='large'
						title='Back to Home'
						onClick={() => history.push('/')}
					/>
				</div>
			</div>
		</div>
	</div>
);

export default Error404Page;
