import Slide from '@mui/material/Slide';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'storybook-mui/build/components/Button';
import Modal from 'storybook-mui/build/components/Modal';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';
import {
	SetNewOrgData,
	SetNewOrgFlowStep,
} from 'reducers/CreateOrg/CreateOrgActions';
import { INewOrgInitialState } from 'reducers/CreateOrg';

function AccountType() {
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const dispatch = useDispatch();
	const newOrgStore: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);
	const orgData = newOrgStore.Org_Data;
	useEffect(() => {
		dispatch(increaseProgressVal(25));
	}, []);

	const continueHandler = (type) => {
		dispatch(
			SetNewOrgData({
				...newOrgStore.Org_Data,
				Account_Type: type,
			})
		);
		dispatch(SetNewOrgFlowStep(newOrgStore.Step + 1));
	};

	return (
		<div className='w-full mx-auto px-4'>
			<Slide direction='left' in mountOnEnter unmountOnExit>
				<div className='pt-5 items-center justify-center pb-5'>
					<div className='flex flex-col w-full'>
						<h1 className='text-xl lg:text-4xl font-semibold text-primary-800 uppercase text-center lg:text-left'>
							How do you plan to use Leap 360?
						</h1>
						<div className='flex flex-col lg:flex-row business-org-list-ct mt-4 lg:mt-10 gap-6'>
							<div className='flex flex-col flex-1 justify-center items-center card-ct border-solid border-2 border-inherit p-2 pb-3 px-3 shadow-md bg-white'>
								<h3 className='card-title uppercase text-info-600 pb-4 text-lg lg:text-2xl font-bold whitespace-nowrap'>
									Business Organization
								</h3>
								<p className='text-center lg:text-left pb-4 lg:pb-8 text-lg lg:text-xl'>
									All our marketing and CRM features, with the ability to create
									suborganizations.
								</p>
								<Button
									disabled={orgData?.Account_Type === ''}
									title='Select & Continue'
									onClick={() => continueHandler('business')}
								/>
							</div>
							<div className='flex flex-col flex-1 justify-center items-center card-ct border-solid border-2 border-inherit p-2 pb-3 px-3 shadow-md bg-white'>
								<h3 className='card-title uppercase text-info-600 pb-4 text-lg lg:text-2xl font-bold whitespace-nowrap'>
									Individual
								</h3>
								<p className='text-center lg:text-left pb-4 lg:pb-8 text-lg lg:text-xl'>
									Simplified to make managing your personal brand easy as possible.
								</p>
								<Button
									disabled={orgData?.Account_Type === ''}
									title='Select & Continue'
									onClick={() => setShowErrorModal(true)}
								/>
							</div>
							<div className='flex flex-col flex-1 justify-center items-center card-ct border-solid border-2 border-inherit p-2 pb-3 px-3 shadow-md bg-white'>
								<h3 className='card-title uppercase text-info-600 pb-4 text-lg lg:text-2xl font-bold whitespace-nowrap'>
									Agency
								</h3>
								<p className='text-center lg:text-left pb-4 lg:pb-8 text-lg lg:text-xl'>
									Optimized to manage the marketing goals of multiple clients.
								</p>
								<Button
									disabled={orgData?.Account_Type === ''}
									title='Select & Continue'
									onClick={() => setShowErrorModal(true)}
								/>
							</div>
						</div>
					</div>
				</div>
			</Slide>

			<Modal
				size='xs'
				modalId='bussiness-modal'
				open={showErrorModal}
				setOpen={() => setShowErrorModal(!showErrorModal)}
				closeIcon
				title=''
				content={
					<div className='flex flex-col gap-4 pt-4'>
						<h1 className='font-semibold text-lg text-gray-600'>
							For now, we are supporting only Business Organization sign up. <br />
							For Individual or Agency sign up, please contact us at +1 (469) 354-0027
							or sales@leap360.com
						</h1>
						<div className='flex justify-end'>
							<Button
								title='OK'
								size='small'
								onClick={() => setShowErrorModal(false)}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}

export default AccountType;
