export const SET_SOCIAL_POST_TYPE = 'SET_SOCIAL_POST_TYPE';
export const SET_SELECTED_PLATFORMS = 'SET_SELECTED_PLATFORMS';
export const RESET_SELECTED_PLATFORMS = 'RESET_SELECTED_PLATFORMS';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const SET_POST_MESSAGE = 'SET_POST_MESSAGE';
export const SET_POST_MESSAGES = 'SET_POST_MESSAGES';
export const SET_SHOW_PREVIEW = 'SET_SHOW_PREVIEW';
export const SET_CROP_IMAGE_URL = 'SET_CROP_IMAGE_URL';
export const SET_PREVIEW_IMAGE = 'SET_PREVIEW_IMAGE';
export const SET_FILE_INFO = 'SET_FILE_INFO';
export const RESET_POST = 'RESET_POST';
export const RESET_POST_TYPE = 'RESET_POST_TYPE';
export const RESET_SOCIAL_CREATIVE = 'RESET_SOCIAL_CREATIVE';
export const SET_ALL_PLATFORMS_FLAG = 'SET_ALL_PLATFORMS_FLAG';
export const SET_UPLOADED_IMAGE = 'SET_UPLOADED_IMAGE';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_FB_FEELING = 'SET_FB_FEELING';
export const SET_FB_ICON = 'SET_FB_ICONS';
export const SET_FB_ACTION = 'SET_FB_ACTION';
export const SET_GOOGLE_BUTTON_URL = 'SET_GOOGLE_BUTTON_URL';
export const SET_GOOGLE_SUMMARY = 'SET_GOOGLE_SUMMARY';
export const SET_GOOGLE_START_DATE = 'SET_GOOGLE_START_DATE';
export const SET_GOOGLE_START_TIME = 'SET_GOOGLE_START_TIME';
export const SET_GOOGLE_END_DATE = 'SET_GOOGLE_END_DATE';
export const SET_GOOGLE_END_TIME = 'SET_GOOGLE_END_TIME';
export const SET_FB_BUTTON_URL = 'SET_FB_BUTTON_URL';
export const SET_INSTA_HASH_TAG = 'SET_INSTA_HASH_TAG';
export const SET_FB_CTA_BUTTON_TYPE = 'SET_FB_CTA_BUTTON_TYPE';
export const SET_GOOGLE_CTA_BUTTON_TYPE = 'SET_GOOGLE_CTA_BUTTON_TYPE';
export const SET_GOOGLE_OFFER_EXTRAS = 'SET_GOOGLE_OFFER_EXTRAS';
export const SET_LINKEDIN_STATUS_EXTRAS = 'SET_LINKEDIN_STATUS_EXTRAS';
export const SET_SOCIAL_EXTRAS = 'SET_SOCIAL_EXTRAS';

export const SET_SOCIAL_CATEGORIES = 'SET_SOCIAL_CATEGORIES';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';

export const SET_SOCIAL_SUBCATEGORIES = 'SET_SOCIAL_SUBCATEGORIES';
export const SET_SELECTED_SUBCATEGORY = 'SET_SELECTED_SUBCATEGORY';

export const SET_CREATIVE_NAME = 'SET_CREATIVE_NAME';
export const SET_SOCIAL_CREATIVE = 'SET_SOCIAL_CREATIVE';
export const SET_SOCIAL_CREATIVE_FILTER = 'SET_SOCIAL_CREATIVE_FILTER';
export const SET_SOCIAL_SHOW_DELETE_MODAL = 'SET_SOCIAL_SHOW_DELETE_MODAL';
export const SET_SOCIAL_CREATIVES_FILTER = 'SET_SOCIAL_CREATIVES_FILTER';
export const SET_SOCIAL_LAST_VISIBLE = 'SET_SOCIAL_LAST_VISIBLE';
export const SET_SOCIAL_FIRST_VISIBLE = 'SET_SOCIAL_FIRST_VISIBLE';
export const SET_SOCIAL_CREATIVE_ERROR = 'SET_SOCIAL_CREATIVE_ERROR';
export const SET_SOCIAL_GRID_PAGE_NUM = 'SET_SOCIAL_GRID_PAGE_NUM';
export const SET_SOCIAL_GRID_VIEW = 'SET_SOCIAL_GRID_VIEW';
export const SET_SOCIAL_SEARCH_TEXT = 'SET_SOCIAL_SEARCH_TEXT';
export const SET_SOCIAL_FILTER = 'SET_SOCIAL_FILTER';
export const SET_SOCIAL_ACCOUNTS = 'SET_SOCIAL_ACCOUNTS';
export const SET_SOCIAL_MEDIA_TEMPLATE = 'SET_SOCIAL_MEDIA_TEMPLATE';
export const SET_SOCIAL_PAGE_SIZE = 'SET_SOCIAL_PAGE_SIZE';
export const SET_SOCIAL_PAGE_NUM = 'SET_SOCIAL_PAGE_NUM';
export const SET_SOCIAL_TOTAL_ROWS = 'SET_SOCIAL_TOTAL_ROWS';
export const SET_SOCIAL_TOTAL_PAGES = 'SET_SOCIAL_TOTAL_PAGES';
export const SET_SOCIAL_INTERNAL_CATEGORIES = 'SET_SOCIAL_INTERNAL_CATEGORIES';
export const SET_SOCIAL_CATEGORY_SAVE_ACTIONS =
	'SET_SOCIAL_CATEGORY_SAVE_ACTIONS';
export const SET_SOCIAL_MAIN_CATEGORY_SAVE_ACTIONS =
	'SET_SOCIAL_MAIN_CATEGORY_SAVE_ACTIONS';
export const SET_SOCIAL_INTERNAL_SELECTED_CATEGORIES =
	'SET_SOCIAL_INTERNAL_SELECTED_CATEGORIES';
export const SET_SOCIAL_ADD_CAT_DATA = 'SET_SOCIAL_ADD_CAT_DATA';
export const SET_SOCIAL_DELETE_DATA = 'SET_SOCIAL_DELETE_DATA';
export const SET_SOCIAL_OPEN_MODAL = 'SET_SOCIAL_OPEN_MODAL';
export const SET_SOCIAL_MODAL_TYPE = 'SET_SOCIAL_MODAL_TYPE';
export const SET_SOCIAL_NEW_CATEGORY = 'SET_SOCIAL_NEW_CATEGORY';
export const SET_SOCIAL_FIILTER_MODAL = 'SET_SOCIAL_FIILTER_MODAL';
export const RESET_ALL_SOCIAL_FILTERS = 'RESET_ALL_SOCIAL_FILTERS';
