import React, { useReducer } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import EN from './translations/en.json';
import ES from './translations/es.json';

const translations = {
	en: EN,
	es: ES,
};

const getTranslate = (langCode) => (key) => translations[langCode][key] || key;
const selection = localStorage.getItem('language') || 'en';
const initialState = {
	langCode: selection,
	translate: getTranslate(selection),
};
export const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({ children }) => {
	const reducer = (state, action) => {
		switch (action.type) {
			case 'setLanguage':
				moment.locale(action.payload);
				return {
					langCode: action.payload,
					translate: getTranslate(action.payload),
				};
			default:
				return { ...initialState };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<I18nContext.Provider value={{ ...state, dispatch }}>
			{children}
		</I18nContext.Provider>
	);
};
I18nContextProvider.propTypes = {
	children: PropTypes.element.isRequired,
};
