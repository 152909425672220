import React, { useEffect, useState } from 'react';
import { clear, getUser } from 'api/AxiosManager';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'components/Spinner';
import WelcomeLinearProgress from 'components/Welcome-comp/Welcome-progress-bar';
import { RootStore } from 'reducers';
import { showLoading } from 'reducers/Alerts/AlertActions';
import Header from './components/Header';

export function Protected({ component: Component, ...props }: any) {
	const history = useHistory();
	const user = getUser();
	const { loading } = useSelector((state: RootStore) => state.alertsReducer);
	const layoutStore = useSelector((state: RootStore) => state.layoutReducer);
	const { progressVal } = useSelector(
		(state: RootStore) => state.welcomeProgressBarReducer
	);
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

	const dispatch = useDispatch();
	const logout = () => {
		clear();
		dispatch(showLoading());
		history.push('/');
		window.location.reload();
	};

	function checkUserAndOrgIsValidOrNot() {
		if (Object.keys(user ?? null).length === 0 || !user?.userId) {
			logout();
		}
	}

	useEffect(() => {
		checkUserAndOrgIsValidOrNot();
	}, []);

	return (
		<>
			{loading && <Spinner />}
			<div className='flex flex-col h-full'>
				<Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				<WelcomeLinearProgress
					color='primary'
					delay={300}
					value={progressVal}
					showBackDrop={false}
					message=''
				/>
				<div
					className={`flex bg-gray-lightest h-full ${
						layoutStore.HeaderNav && user ? 'mt-16' : 'mt-2'
					}`}
				>
					<div className='block md:flex flex-col flex-1 pt-4 w-full fullscreenwithheader overflow-auto'>
						<main className='flex-1 relative z-0 focus:outline-none'>
							<div className='w-full mx-auto px-4 sm:px-6 md:px-8 h-full'>
								<Component {...props} />
							</div>
						</main>
					</div>
				</div>
			</div>
		</>
	);
}

export default Protected;
