export const LOGGING_IN = 'LOGGING_IN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGGED_OUT = 'LOGGED_OUT';
export const USER = 'USER';

// SINGLE CRUD
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_POSTED = 'USER_POSTED';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL = 'USER_FAIL';
export const SET_COMPANY_LOGO_UPDATED = 'SET_COMPANY_LOGO_UPDATED';

export interface LOGGING_IN {
	type: typeof LOGGING_IN;
}

export interface LOGIN_SUCCESS {
	type: typeof LOGIN_SUCCESS;
}

export interface LOGIN_FAIL {
	type: typeof LOGIN_FAIL;
}

export interface LOGGED_OUT {
	type: typeof LOGGED_OUT;
}

export interface USER {
	type: typeof USER;
	user: any;
}
export interface GET_USER {
	type: typeof GET_USER;
}
export interface USER_POSTED {
	type: typeof USER_POSTED;
	user: any;
}
export interface UPDATE_USER {
	type: typeof UPDATE_USER;
}
export interface CREATE_USER {
	type: typeof CREATE_USER;
}
export interface DELETE_USER {
	type: typeof DELETE_USER;
}

export interface USER_SUCCESS {
	type: typeof USER_SUCCESS;
	user: any;
}
export interface USER_FAIL {
	type: typeof USER_FAIL;
}
export interface SET_COMPANY_LOGO_UPDATED {
	type: typeof SET_COMPANY_LOGO_UPDATED;
}

export type UserDispatchTypes =
	| LOGGING_IN
	| LOGIN_SUCCESS
	| LOGIN_FAIL
	| LOGGED_OUT
	| USER
	| GET_USER
	| UPDATE_USER
	| USER_POSTED
	| CREATE_USER
	| DELETE_USER
	| USER_SUCCESS
	| USER_FAIL
	| SET_COMPANY_LOGO_UPDATED;
