import {
	createWithXProjKey,
	getAuthUserName,
	getClientDB,
	getOrganisationIdFirebase,
	getUserName,
	getXProjKey,
	siteConfigConstants,
} from 'api/AxiosManager';
import CryptoJS from 'crypto-js';
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	orderBy,
	query,
	Timestamp,
	where,
} from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { masterDb } from 'firebaseConfig';
import moment from 'moment';
import { getCustomAccessToken } from 'api/Users';

import { SaveDynamicModuleDocument } from './dynamic';

export const AddExistingUserToOrganisation = async (
	userId: string,
	orgId,
	orgName,
	firestoreConfig: any,
	userType: string,
	createdBy = '',
	reactAppBaseUrl = siteConfigConstants().REACT_APP_DYNAMIC_SEARCH,
	xProjKey = getXProjKey(),
	appAuthorization = siteConfigConstants().REACT_APP_AUTHORIZATION,
	registerFromInvite = false,
	miscellaneousUrl = siteConfigConstants().REACT_APP_MISCELLANEOUS_URL
) => {
	const localStorageData = JSON.parse(localStorage.getItem('user'));

	const organizationId = orgId !== '' ? orgId : getOrganisationIdFirebase();
	let clientDB: any;
	let clientApp: any;
	if (firestoreConfig) {
		clientApp = firebase.initializeApp(firestoreConfig, orgId);
		clientDB = clientApp.firestore();
	} else {
		clientDB = getClientDB();
	}

	try {
		if (registerFromInvite) {
			// if user registers from join from invite then authenticate client first
			const userInMaster = await getDoc(doc(masterDb, `Users/${userId}`));
			if (userInMaster.exists()) {
				const payload = {
					Have_To_Create_User: true,
					UID: userInMaster.data().UID,
				};
				const clientAuthResult = await getCustomAccessToken(
					payload,
					miscellaneousUrl
				);
				if (clientAuthResult && registerFromInvite) {
					const clientAuth = getAuth(clientApp);
					const accessTokenClient = clientAuthResult.data.custom_user_token;
					await signInWithCustomToken(clientAuth, accessTokenClient);
				}
			}
		}
		let result: any = {};

		const resultSnapshots = await getDocs(
			query(
				collection(clientDB, `/${organizationId}/User_Profiles/User_Profiles`),
				where('Associate_User', '==', userId)
			)
		);
		if (resultSnapshots.docs.length > 0) {
			result = {
				success: false,
				message: 'User already exists in organisation',
			};
		} else {
			const userInMaster = await getDoc(doc(masterDb, `Users/${userId}`));
			if (userInMaster.exists()) {
				if (!registerFromInvite) {
					const payloadObj = {
						Have_To_Create_User: true,
						UID: userInMaster.data().UID,
					};
					const clientAuthResult = await getCustomAccessToken(payloadObj);
					if (!clientAuthResult) {
						result = {
							success: false,
							message: 'Something went wrong',
						};
						return result;
					}
				}

				// adding data to users mapping in master
				const payloadForUsersMappingInMaster = {
					Created_By_User_Id: orgId !== '' ? userId : localStorageData.userId,
					Created_Date_Time_Gmt: moment().toString(),
					IS_Accept: true,
					IS_Accept_Invitation: true,
					Last_Modified_By_User_Id: orgId !== '' ? userId : localStorageData.userId,
					Last_Modified_Date_Time_Gmt: moment().toString(),
					Org_Name: orgName !== '' ? orgName : localStorageData?.organizationName,
					Organization_Id: organizationId,
					User_Id: userId,
					UID: userInMaster.data().UID,
				};
				await addDoc(
					collection(masterDb, `UsersMapping`),
					payloadForUsersMappingInMaster
				);

				const leap360User = userInMaster.data().Email?.indexOf('@leap360.com') > -1;
				// adding data to users in client
				const profilePayload = {
					First_Name:
						userInMaster.data()?.FirstName || userInMaster.data()?.Name.split(' ')[0],
					Last_Name:
						userInMaster.data()?.LastName ||
						userInMaster.data()?.Name.split(' ')[1] ||
						'',
					Emails: [
						{
							Email_Id: userInMaster.data().Email,
							Email_Opt_In: userInMaster.data().email_optIn || false,
						},
					],
					Phones: [
						{
							Phone_Number: userInMaster.data().phoneNumber || '',
							Country_Code: userInMaster.data().phone_countryCode || '',
							Text_Opt_In: userInMaster.data().sms_optIn || false,
							Voice_Opt_In: userInMaster.data().voice_optIn || false,
						},
					],
					ContactInfo: [
						{
							Email_Id: userInMaster.data().Email,
							Phone_Number: userInMaster.data().phoneNumber || '',
							Country_Code: userInMaster.data().phone_countryCode || '',
						},
					],
					Email_Id: userInMaster.data().Email,
					Phone_Number: userInMaster.data().phoneNumber || '',
					Country_Code: userInMaster.data().phone_countryCode || '',
					GroupedFieldsValues: {
						Email_Id: userInMaster.data().Email,
						Phone_Number: userInMaster.data().phoneNumber || '',
						Country_Code: userInMaster.data().phone_countryCode || '',
					},
					Associate_User: userId, // userid
					Created_By: createdBy || getUserName(),
					Created_At: moment().toString(),
					Created_At_TimeStamp: Timestamp.now(),
					Updated_By: createdBy || getUserName(),
					Updated_At: moment().toString(),
					Updated_At_TimeStamp: Timestamp.now(),
					Is_Active: true,
					Is_Locked: false,
					User_Id: userId,
					Login: userInMaster.data().Login,
					User_Type: userType,
					Billing_Status: !leap360User,
					UI_Visibility: !leap360User,
					Allow_Campaigns: !leap360User,
				};
				await SaveDynamicModuleDocument({
					documentName: 'User Profiles',
					moduleName: 'User_Profiles',
					primaryKeyFields: [],
					payload: profilePayload,
					orgId: organizationId,
					clientDB,
					reactAppBaseUrl,
					xProjKey,
					appAuthorization,
				});
				/* await UpdateDynamicModuleDocumentsCount(
					1,
					'User_Profiles',
					clientDB,
					organizationId
				);
				InsertDataInBigQuery({ documentId: userId, moduleName: 'Users' }); */

				result = {
					id: userId,
					success: true,
					message: 'User added to organisation',
				};
			} else {
				result = {
					success: false,
					message: 'User does not exist',
				};
			}
		}
		return result;
	} catch (error) {
		return {
			success: false,
			message: error.message,
		};
	}
};

export const SendInvitationToUser = async (email, userType = 'Internal') => {
	let result;
	const user = JSON.parse(localStorage.getItem('user'));
	const tokenObj = {
		organizationId: getOrganisationIdFirebase(),
		organizationName: user.organizationName,
		clientDb: user.firestoreConfig,
		createdBy: getAuthUserName(),
		email,
		reactAppBaseUrl: siteConfigConstants().REACT_APP_DYNAMIC_SEARCH,
		xProjKey: getXProjKey(),
		appAuthorization: siteConfigConstants().REACT_APP_AUTHORIZATION,
		miscellaneousUrl: siteConfigConstants().REACT_APP_MISCELLANEOUS_URL,
		createdOn: moment().toString(),
		userType,
	};

	const encryptedToken = CryptoJS.AES.encrypt(
		JSON.stringify(tokenObj),
		'PPCRM'
	).toString();

	const redirectUrl = `${window.location.origin}/join?token=${encryptedToken}`;
	try {
		const payload = {
			emailObj: {
				to: [
					{
						email,
					},
				],
				from: {
					email: 'support@leap360.com',
					name: 'Leap360',
				},
				category: [],
				subject: 'Invitation to join the organization',
				text: 'and easy to do anywhere, even with Node.js testing',
				html: `<h1>Hi , You got an invitation from ${user.organizationName} </h1> <hr/> <p>Please click on the link below to join the ${user.organizationName} organisation</p><p><a href=${redirectUrl}>ACCEPT INVITATION</a></p>`,
			},
		};
		const instance = createWithXProjKey();
		await instance.post(
			`${siteConfigConstants().REACT_APP_SEND_EMAIL}/sendEMail`,
			payload
		);
		result = {
			success: true,
			message: 'Invitation sent successfully',
		};
	} catch (error) {
		result = {
			success: false,
			message: 'Something went wrong',
		};
	}

	return result;
};

export const SendLoginInfoToUser = async (user: any) => {
	let result;
	const { email } = user;
	try {
		const payload = {
			emailObj: {
				to: [
					{
						email,
					},
				],
				from: {
					email: 'support@leap360.com',
					name: 'Leap360',
				},
				category: [],
				subject: 'Leap360 CRM Login Credentials',
				text: 'and easy to do anywhere, even with Node.js testing',
				html: `<p>Hello ${
					user?.FirstName
				},</p><p>You have been added as a new user to leap360 by ${getAuthUserName()}. Please find below your login credentials:</p> <ul>
				<li>Username: ${email}</li>
				<li>Password: ${user?.Password}</li>
			  </ul><p>
			  We kindly remind you to verify your account by clicking on the link provided in our previous email. This is an important step to ensure the security of your account.
			  </p><p>Please note that for security reasons, we highly recommend that you change your password as soon as possible after logging in.</p>  <p>If you have any questions or concerns, please feel free to reach out to our support team at <a href="mailto:support@leap360.com">support@leap360.com</a>.</p>

			  <p>Best regards,<br>Leap360 support team</p>`,
			},
		};
		const instance = createWithXProjKey();
		await instance.post(
			`${siteConfigConstants().REACT_APP_SEND_EMAIL}/sendEMail`,
			payload
		);
		result = {
			success: true,
			message: 'Invitation sent successfully',
		};
	} catch (error) {
		result = {
			success: false,
			message: 'Something went wrong',
		};
	}

	return result;
};

export const GetAllUsers = async () => {
	try {
		const qry = query(
			collection(
				getClientDB(),
				`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles`
			),
			where('UI_Visibility', '==', true),
			where('Allow_Campaigns', '==', true),
			orderBy('Updated_At_TimeStamp', 'desc')
		);

		const resultSnapshots: any = await getDocs(qry);

		const resultArray = resultSnapshots.docs.map((document: any) => ({
			...document.data(),
			id: document.id,
		}));

		return {
			data: resultArray.filter((item) => item?.Display !== false),
		};
	} catch (error) {
		return error;
	}
};

export const DeleteUserFromOrganisation = async (userId: string) => {
	try {
		await deleteDoc(
			doc(
				getClientDB(),
				`${getOrganisationIdFirebase()}/User_Profiles/User_Profiles/${userId}`
			)
		);
		const userMappingSnapshots = await getDocs(
			query(
				collection(masterDb, `UsersMapping`),
				where('User_Id', '==', userId),
				where('Organization_Id', '==', getOrganisationIdFirebase())
			)
		);
		const userMappingArray = userMappingSnapshots.docs.map((document: any) => ({
			...document.data(),
			id: document.id,
		}));
		await deleteDoc(doc(masterDb, `UsersMapping/${userMappingArray[0].id}`));
		return true;
	} catch (error) {
		return error;
	}
};

export const getUserInfo = async (userId) => {
	try {
		const userInMaster = await getDoc(doc(masterDb, `Users/${userId}`));
		return userInMaster.data();
	} catch (e) {
		return null;
	}
};
