import React, { useState, useEffect } from 'react';
import Slide from '@mui/material/Slide';
import {
	CreateOrganisation,
	GetOrganisationInfoById,
	MakeVerifonePaymentWithToken,
} from 'firebaseApis/authentication';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'storybook-mui/build/components/Button';
import { SwitchField } from 'storybook-mui/build/components/Switch/Switch';
import { increaseProgressVal } from 'reducers/WelcomeProgressbar';

import Toast, { ToastTypes } from 'components/ToastNotify';
import { INewOrgInitialState } from 'reducers/CreateOrg';
import {
	CancelNewOrgFlow,
	SetNewOrgFlowStep,
} from 'reducers/CreateOrg/CreateOrgActions';
import LinearProgressBar from 'components/LinearProgressBar';
import SendPaymentReqModal from './SendPaymentRequestModal';

const BillingConfirmation = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);
	const [diffBilling, setDiffBilling] = useState(false);
	const dispatch = useDispatch();

	const orgStoreData: INewOrgInitialState = useSelector(
		(state: any) => state.createOrgReducer
	);

	const [showPaymentReqModal, setShowPaymentReqModal] = useState(false);

	useEffect(() => {
		dispatch(increaseProgressVal(85));
	}, []);

	const sameBillingHandler = async () => {
		try {
			setLoading(true);
			const response = await await MakeVerifonePaymentWithToken();
			if (response.status === 'AUTHORIZED') {
				const response2 = await CreateOrganisation({
					newOrgData: orgStoreData,
					cardValues: null,
					paymentInfo: {
						reused_token: response.reused_token,
					},
				});
				if (response2.data && response2.data?.Organization_Id) {
					Toast({
						title: 'Sub Organization Created Successfully',
						type: ToastTypes.SUCCESS,
					});
					setCurrentProgress(100);
					dispatch(increaseProgressVal(10));
					await GetOrganisationInfoById(response2.data?.Organization_Id);
					dispatch(SetNewOrgFlowStep(orgStoreData.Step + 1));
				} else {
					setCurrentProgress(0);
					Toast({
						title: 'Sub Organization Creation Failed',
						type: ToastTypes.ERROR,
					});
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Toast({
				title: error?.message || 'Something went wrong',
				type: ToastTypes.ERROR,
			});
			setCurrentProgress(0);
		}
	};

	const handleSendPaymentRequest = (val) => {
		setShowPaymentReqModal(val);
	};

	const handleIndepdntBillngRequest = () => {
		dispatch(SetNewOrgFlowStep(orgStoreData.Step + 1));
	};
	const onProgressComplete = async () => {
		await new Promise((resolve) => setTimeout(resolve, 300));
		setLoading(false);
		setCurrentProgress(0);
		dispatch(CancelNewOrgFlow());
	};
	if (loading) {
		return (
			<LinearProgressBar
				delay={50000}
				incrementBy={1}
				currentProgress={currentProgress}
				onProgressComplete={onProgressComplete}
				message='Please be patient while we setup your Business/Organization. This may take a couple of minutes...'
			/>
		);
	}
	return (
		<div className='w-full mx-auto billing-conf-ct'>
			<Slide direction='left' in mountOnEnter unmountOnExit>
				<div className='flex flex-col items-center justify-center pb-5'>
					<div className='flex items-center bg-white p-10 rounded-xl'>
						<div className='w-10/12 xl:w-11/12'>
							<p className='text-xl xl:text-2xl font-black text-info-500'>
								Continue to use same billing information as parent organization.
							</p>
						</div>
						<div>
							<Button title='Next' onClick={sameBillingHandler} />
						</div>
					</div>
					<div className='flex items-center justify-center mt-3'>
						<SwitchField
							sx={{
								'text-transform': 'uppercase',
								color: '#47484C',
							}}
							size='medium'
							fieldId='diffBillingInfo'
							name='diffBillingInfo'
							onChange={() => setDiffBilling(!diffBilling)}
							checked={diffBilling}
							labelName='Use different billing info'
						/>
					</div>
				</div>
			</Slide>
			<Slide direction='up' in mountOnEnter unmountOnExit>
				<div>
					<SendPaymentReqModal
						isShowModal={showPaymentReqModal}
						changePaymentReqModalHandler={handleSendPaymentRequest}
					/>
				</div>
			</Slide>
			{diffBilling && (
				<Slide direction='up' in mountOnEnter unmountOnExit>
					<div className='flex justify-center items-center mt-4'>
						<div className='mr-4'>
							<Button
								title={<h3 className='text-info-500'>send payment request</h3>}
								sx={{
									borderColor: '#ddd',
								}}
								size='medium'
								variant='outlined'
								color='inherit'
								type='button'
								onClick={() => handleSendPaymentRequest(true)}
							/>
						</div>
						<div className='ml-4'>
							<Button
								title={<h3 className='text-info-500'>set up independent billing</h3>}
								sx={{
									borderColor: '#ddd',
								}}
								size='medium'
								variant='outlined'
								color='inherit'
								type='button'
								onClick={() => handleIndepdntBillngRequest()}
							/>
						</div>
					</div>
				</Slide>
			)}
		</div>
	);
};

export default BillingConfirmation;
