import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Card from 'storybook-mui/build/components/Card';
import { GetAllOrders } from 'firebaseApis/calendar';
import listPlugin from '@fullcalendar/list';
import { useHistory } from 'react-router';

const buttonText = {
	today: 'Today',
	month: 'Month',
	week: 'Week',
	day: 'Day',
	list: 'Agenda',
};

function Calendar() {
	const [orderEvents, setOrderEvents] = useState<[]>([]);
	const history = useHistory();
	const calendarRef = useRef<FullCalendar>(null!);
	const handleClick = (event) => {
		history.push(`/bookings/orders/${event.event.extendedProps.orderID}`);
	};

	const getOrdersData = async () => {
		const result = await GetAllOrders();
		const tempObj: any = [];
		let tempBGColor;
		let tempBorderColor;

		result.forEach((order: any) => {
			if (order.orderStatus === 'Booked') {
				tempBGColor = '#004c93';
				tempBorderColor = '#004c93';
			} else if (order.orderStatus === 'Completed') {
				tempBGColor = 'green';
				tempBorderColor = 'green';
			} else if (order.orderStatus === 'Cancelled') {
				tempBGColor = '#df8b0c';
				tempBorderColor = '#df8b0c';
			}

			if (order.orderStatus !== 'Pending') {
				const obj = {
					id: order.orderId,
					title: order.eventName,
					start: `${order.eventDate}T${order.startTime}`,
					end: `${order.eventDate}T${order.endTime}`,
					status: order.orderStatus,
					guestOfHonour: order.Guest_Of_Honor,
					backgroundColor: tempBGColor,
					borderColor: tempBorderColor,
					orderID: order.orderId,
				};

				tempObj.push(obj);
			}
		});
		setOrderEvents(tempObj);
	};

	const handleDateClick = (event) => {
		const calendarApi: any = calendarRef.current.getApi();
		calendarApi.changeView('timeGridDay', event.dateStr);
	};

	useEffect(() => {
		getOrdersData();
	}, []);

	return (
		<div className='pt-5'>
			<Card
				title=''
				contentSx={{ height: '100vh' }}
				content={
					<>
						<FullCalendar
							allDaySlot={false}
							plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
							initialView='dayGridMonth'
							events={orderEvents}
							height='100%'
							headerToolbar={{
								start: 'today,prev,next',
								center: 'title',
								end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
							}}
							buttonText={buttonText}
							slotLabelInterval={{
								hour: 1,
							}}
							weekends
							firstDay={0}
							slotEventOverlap={false}
							nowIndicator
							eventClick={(e) => {
								handleClick(e);
							}}
							dateClick={handleDateClick}
							ref={calendarRef}
							dayMaxEvents={2}
						/>
					</>
				}
			/>
		</div>
	);
}

export default Calendar;