import * as Yup from 'yup';

export const credicardValidation = Yup.object().shape({
	number: Yup.string().required('Required').min(16, 'Invalid Card Number'),
	name: Yup.string()
		.required('Required')
		.matches(/^[a-zA-Z ]+( [a-zA-Z ]+ )*$/, 'Special characters not allowed'),

	firstName: Yup.string()
		.trim()
		.required('Required')
		.matches(/^[a-zA-Z ]+( [a-zA-Z ]+ )*$/, 'Special characters not allowed'),
	state: Yup.string()
		.trim()
		.required('Required')
		.matches(/^[a-zA-Z ]+( [a-zA-Z ]+ )*$/, 'Special characters not allowed'),
	lastName: Yup.string()
		.trim()
		.required('Required')
		.matches(/^[a-zA-Z ]+( [a-zA-Z ]+ )*$/, 'Special characters not allowed'),
	city: Yup.string()
		.trim()
		.required('Required')
		.matches(/^[a-zA-Z ]+( [a-zA-Z ]+ )*$/, 'Special characters not allowed'),
	addressLine1: Yup.string().required('Required').trim(),
	addressLine2: Yup.string(),
	zip: Yup.string()
		.required('Required')
		.matches(/^[0-9]+$/, 'Only numbers are allowed')
		.max(6, 'Max 6 digits'),

	phoneNumber: Yup.string()
		.required('Required')
		.matches(/^[0-9]+$/, 'Only numbers are allowed')
		.min(10, 'Invalid Phone Number')
		.max(10, 'Max 10 digits'),

	cvv: Yup.string()
		.required('Required')
		.matches(/^[0-9]+$/, 'Only numbers are allowed')
		.max(3, 'CVV must be 3 digits'),
	expiryDate: Yup.string()
		.required('Required')
		.min(5, 'Invalid Expiry Date')
		.max(5, 'Invalid Expiry Date')
		.test('expiryDate', 'Invalid Expiry Date', (value) => {
			const currentMonth = new Date().getMonth() + 1;
			const currentYear = parseInt(
				new Date().getFullYear().toString().substr(-2),
				10
			);
			const enteredMonth = parseInt(value?.split('/')[0], 10);
			const enteredYear = parseInt(value?.split('/')[1], 10);
			if (enteredMonth > 12) return false;
			if (enteredYear < currentYear) {
				return false;
			}
			if (enteredYear === currentYear && enteredMonth < currentMonth) {
				return false;
			}

			return true;
		}),
});
