import React from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

interface IPhoneNumber {
	mask: string;
	phoneValue: string;
	setPhoneValue: (val) => void;
	label?: string;
}
const PhoneNumber = ({
	mask,
	phoneValue,
	setPhoneValue,
	label = 'Phone number',
}: IPhoneNumber) => {
	const phoneValueChange = (e) => {
		setPhoneValue(e.target.value.replace(/[^\d]/g, ''));
	};

	return (
		<InputMask
			mask={mask || '999-999-9999'}
			value={phoneValue}
			onChange={phoneValueChange}
			maskChar='_'
			alwaysShowMask
		>
			{() => (
				<TextField
					type='text'
					label={label}
					variant='outlined'
					size='small'
					sx={{
						input: {
							letterSpacing: '2px',
							color: '#474745',
						},
					}}
				/>
			)}
		</InputMask>
	);
};

export default PhoneNumber;
