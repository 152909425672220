import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'components/Spinner';

import { RootStore } from '../reducers';

export function PublicRoute({ component: Component, ...props }: any) {
	const { loading } = useSelector((state: RootStore) => state.alertsReducer);

	return (
		<>
			{loading && <Spinner />}
			<Component {...props} />
		</>
	);
}

export default PublicRoute;
