import React, { useState, useEffect } from 'react';
import Toast, { ToastTypes } from 'components/ToastNotify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AddExistingUserToOrganisation } from 'firebaseApis/users';

// Storybook Components
import Button from 'storybook-mui/build/components/Button';
import InputTextField from 'storybook-mui/build/components/TextField';
import CountrySelect from 'components/Phone/CountrySelect';
import PhoneNumber from 'components/Phone/PhoneNumber';
import { BasicRegister, GetAllCountries } from 'firebaseApis/authentication';
import {
	SetAuthenticationType,
	SetUserInfo,
} from 'reducers/Authentication/AuthActions';
import { checkRecordExistsInMasterDB } from 'firebaseApis/common';
import { showLoading, hideLoading } from 'reducers/Alerts/AlertActions';
import LinearProgressBar from 'components/LinearProgressBar';
import Leap360Logo from '../../assets/img/leap360.png';

export const JoinFromInvite = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { token } = queryString?.parse(search);
	const bytes = CryptoJS.AES.decrypt(token.replaceAll(' ', '+'), 'PPCRM');
	const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	const {
		organizationId,
		organizationName,
		email,
		clientDb,
		createdBy,
		reactAppBaseUrl,
		xProjKey,
		appAuthorization,
		miscellaneousUrl,
		userType,
	}: IJoinFromInvite = decryptedData;
	const [passwordVisibility, setPasswordVisibility] = useState(true);
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(true);
	const [passwordUpper, setPasswordUpper] = useState<boolean>(false);
	const [passwordLower, setPasswordLower] = useState<boolean>(false);
	const [passwordEightChar, setPasswordEightChar] = useState<boolean>(false);
	const [passwordNumberSymbol, setPasswordNumberSymbol] =
		useState<boolean>(false);
	const [passwordNewAndConfirmMatches, setPasswordNewAndConfirmMatches] =
		useState<boolean>(false);
	const [validationOnChange, setValidateOnChange] = useState(false);
	const [countries, setCountries] = useState<any>([]);
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const [currentProgress, setCurrentProgress] = useState<number>(0);

	const registerUser = async (values: any) => {
		setLoading(true);
		try {
			const newValues = {
				...values,
				email_optIn: false,
				sms_optIn: false,
				voice_optIn: false,
				phoneNumber: '',
				phone_countryCode: '',
				createdBy,
			};
			const result = await BasicRegister(newValues);
			if (result.success) {
				const dataForLocalStorage: any = {
					fName: values.FirstName,
					lName: values.LastName,
					email: values.Email,
					userId: result.data.id,
					emailVerified: false,
				};
				Toast({ title: 'Registration Successfull', type: ToastTypes.SUCCESS });
				dispatch(SetUserInfo(values));
				dispatch(SetAuthenticationType('REGISTER'));
				const result2 = await AddExistingUserToOrganisation(
					dataForLocalStorage.userId,
					organizationId,
					organizationName,
					clientDb,
					userType,
					createdBy,
					reactAppBaseUrl,
					xProjKey,
					appAuthorization,
					true,
					miscellaneousUrl
				);
				if (result2.success) {
					dispatch(SetUserInfo(dataForLocalStorage));
					history.push('/verifyemail');
					setLoading(false);
					setCurrentProgress(0);
				} else {
					setLoading(false);
					setCurrentProgress(0);
					Toast({ title: result2.message, type: ToastTypes.ERROR });
				}
			} else {
				setLoading(false);
				setCurrentProgress(0);
				Toast({ title: 'Email already exists', type: ToastTypes.ERROR });
			}
		} catch (error) {
			setLoading(false);
			setCurrentProgress(0);
			Toast({ title: 'Something Went Wrong', type: ToastTypes.ERROR });
		}
	};

	const registerForm = useFormik({
		initialValues: {
			FirstName: '',
			LastName: '',
			Email: email || '',
			Name: '',
			Password: '',
			select_Phone_Type: 'Mobile',
			landline_ext: '',
			phone_countryId: 'US',
			phoneNumber: '',
			ConfirmPassword: '',
			phone_countryCode: '+1',
		},
		validationSchema: Yup.object().shape({
			FirstName: Yup.string()
				.required('Required')
				.matches(/^[^\\/?`"']*$/, 'Special characters are not allowed'),
			LastName: Yup.string()
				.required('Required')
				.matches(/^[^\\/?`"']*$/, 'Special characters are not allowed'),
			Email: Yup.string().required('Required').email('Invalid email'),
			Password: Yup.string()
				.required('Required')
				.matches(
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\s!@#$%^&*()_+=[\]{}|\\:;"'<>,.?/-])/,
					'Password must contain at least one lowercase letter, one uppercase letter, one number, symbol or whitespace character'
				)
				.min(8, 'Password must be at least 8 characters long'),

			ConfirmPassword: Yup.string()
				.required('Required')
				.oneOf([Yup.ref('Password')], 'Passwords must match'),
		}),
		validateOnChange: validationOnChange,
		onSubmit: async (values, formikProps) => {
			const res = await checkRecordExistsInMasterDB(
				'Users',
				'Email',
				values.Email?.toLowerCase()
			);
			if (res?.recordExists) {
				registerForm.setFieldError(
					'Email',
					'The User email address you have entered already exists in Leap360. Please enter a different email address or select "Add Existing User" option to continue'
				);
			} else {
				formikProps.setSubmitting(false);
				if (values.ConfirmPassword === values.Password)
					registerUser({
						...registerForm.values,
						ConfirmPassword: '',
						UID: '',
						userRegistrationType: 'basic',
						ProfilePicUrl: '',
						Login: '',
					});
			}
		},
	});
	const validatepasswordAndConfirmPassword = (e) => {
		setPasswordNewAndConfirmMatches(false);
		if (registerForm.values.Password && e) {
			if (registerForm.values.Password === e) {
				setPasswordNewAndConfirmMatches(true);
			}
		}
	};
	const handlePasswordValidation = (e) => {
		const { value } = e.target;
		setPasswordNewAndConfirmMatches(false);
		if (registerForm.values.ConfirmPassword && value) {
			if (registerForm.values.ConfirmPassword === value) {
				setPasswordNewAndConfirmMatches(true);
			}
		}
		registerForm.setFieldValue('Password', value);
		const lowerCaseLetters = /[a-z]/g;
		if (value.match(lowerCaseLetters)) {
			setPasswordLower(true);
		} else {
			setPasswordLower(false);
		}
		const upperCaseLetters = /[A-Z]/g;
		if (value.match(upperCaseLetters)) {
			setPasswordUpper(true);
		} else {
			setPasswordUpper(false);
		}
		const numberSymbol = /[[!@#$%^&*()_+-={~`} :";'<>?,.\/|0-9]/g;
		if (value.match(numberSymbol)) {
			setPasswordNumberSymbol(true);
		} else {
			setPasswordNumberSymbol(false);
		}
		if (value.length >= 8) {
			setPasswordEightChar(true);
		} else {
			setPasswordEightChar(false);
		}
	};

	const getData = async () => {
		dispatch(showLoading());
		const result = await GetAllCountries();
		dispatch(hideLoading());
		const countriesResult: any = result.map((item) => ({
			value: `${item.country_id}`,
			label: item.country_name,
			states: item.states,
			countryId: item.country_id,
			phoneFormat: item.phone_format,
			phoneCode: item.phone_code,
			flagUrl: item.flag_svg_url,
		}));
		setCountries(countriesResult);
	};

	const onCountrySelectChange = (val) => {
		if (val) {
			const phCode = countries.find((item) => item.countryId === val).phoneCode;
			registerForm.setFieldValue('phone_countryCode', phCode);
			registerForm.setFieldValue('phone_countryId', val);
		} else {
			registerForm.setFieldValue('phone_countryCode', '');
			registerForm.setFieldValue('phone_countryId', '');
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			{loading && (
				<LinearProgressBar
					delay={16000}
					incrementBy={1}
					currentProgress={currentProgress}
					message='Adding user into organization...'
				/>
			)}

			<div className='h-screen w-auto flex justify-center items-center px-4 lg:px-0 lg:pt-16 bg-white'>
				<div className='flex flex-col justify-center items-center w-full md:w-1/2  gap-5'>
					<img src={Leap360Logo} className='w-36 lg:w-44 mx-auto' />
					<h1 className=' text-xl lg:text-3xl font-semibold text-gray-600'>
						SIGNUP
					</h1>

					<form
						className='flex mb-3 gap-10 flex-col mt-5'
						id='invite-user-registration-form'
					>
						<div className='flex flex-col w-full space-y-4'>
							<div className='flex flex-col md:flex-row md:space-x-5 md:space-y-0 space-y-4'>
								<InputTextField
									name='FirstName'
									fieldId='firstName'
									labelName='First name'
									value={registerForm.values.FirstName}
									errorMessage={registerForm.errors.FirstName}
									valid={registerForm.errors.FirstName === undefined}
									onChange={registerForm.handleChange}
									autoComplete='given-name'
								/>
								<InputTextField
									name='LastName'
									fieldId='lastName'
									labelName='Last name'
									value={registerForm.values.LastName}
									errorMessage={registerForm.errors.LastName}
									valid={registerForm.errors.LastName === undefined}
									onChange={registerForm.handleChange}
									autoComplete='family-name'
								/>
							</div>
							<div className='grid grid-cols-1 md:grid-cols-3  gap-4'>
								<div className='md:col-span-2 flex flex-col gap-4'>
									<InputTextField
										name='Email'
										fieldId='email'
										labelName='Email address'
										value={registerForm.values.Email}
										valid={registerForm.errors.Email === undefined}
										onChange={registerForm.handleChange}
										autoComplete='email'
										errorMessage={registerForm.errors.Email}
										disabled
									/>
									<InputTextField
										autoComplete='new-password'
										name='Password'
										type={passwordVisibility ? 'password' : 'text'}
										fieldId='password'
										labelName='Password'
										value={registerForm.values.Password}
										errorMessage={registerForm.errors.Password}
										valid={registerForm.errors.Password === undefined}
										onChange={handlePasswordValidation}
										adornmentMessage={
											<IconButton
												aria-label='toggle password visibility'
												onClick={() => setPasswordVisibility(!passwordVisibility)}
												onMouseDown={(event) => event.preventDefault()}
											>
												{passwordVisibility ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										}
									/>
									<div>
										<InputTextField
											name='ConfirmPassword'
											type={confirmPasswordVisibility ? 'password' : 'text'}
											autoComplete='new-password'
											fieldId='ConfirmPassword'
											labelName='Confirm password'
											valid={registerForm.errors.ConfirmPassword === undefined}
											errorMessage={registerForm.errors.ConfirmPassword}
											value={registerForm.values.ConfirmPassword}
											onChange={(e) => {
												registerForm.setFieldValue('ConfirmPassword', e.target.value);
												validatepasswordAndConfirmPassword(e.target.value);
											}}
											adornmentMessage={
												<IconButton
													aria-label='toggle password visibility'
													onClick={() =>
														setConfirmPasswordVisibility(!confirmPasswordVisibility)
													}
													onMouseDown={(event) => event.preventDefault()}
												>
													{confirmPasswordVisibility ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											}
										/>
									</div>
								</div>
							</div>
							<div className='flex flex-col gap-1'>
								<div className='flex items-center gap-2'>
									{passwordEightChar ? (
										<CheckCircleIcon className=' text-green-500 font-bold' />
									) : (
										<CheckCircleIcon className='text-gray-lightest' />
									)}
									<p className='text-gray-darkest  text-sm'>Atleast 8 characters long</p>
								</div>
								<div className='flex items-center gap-2'>
									{passwordLower ? (
										<CheckCircleIcon className=' text-green-500 font-bold' />
									) : (
										<CheckCircleIcon className='text-gray-lightest' />
									)}
									<p className='text-gray-darkest  text-sm '>One lowercase character</p>
								</div>
								<div className='flex items-center gap-2'>
									{passwordUpper ? (
										<CheckCircleIcon className=' text-green-500 font-bold' />
									) : (
										<CheckCircleIcon className='text-gray-lightest' />
									)}
									<p className='text-gray-darkest  text-sm'>One uppercase character</p>
								</div>
								<div className='flex items-center gap-2'>
									{passwordNumberSymbol ? (
										<CheckCircleIcon className=' text-green-500 font-bold' />
									) : (
										<CheckCircleIcon className='text-gray-lightest' />
									)}
									<p className='text-gray-darkest text-sm'>
										One number, symbol or whitespace character
									</p>
								</div>
								<div className='flex items-center gap-2'>
									{passwordNewAndConfirmMatches ? (
										<CheckCircleIcon className=' text-green-500 font-bold' />
									) : (
										<CheckCircleIcon className='text-gray-lightest' />
									)}
									<p className='text-gray-darkest text-sm'>
										Password and confirm password must be the same
									</p>
								</div>
							</div>
							<div className='grid grid-cols-1 md:grid-cols-3 md:space-x-5 md:space-y-0 space-y-4  w-full '>
								{countries.length > 0 && (
									<CountrySelect
										countries={countries}
										countryId={registerForm.values.phone_countryId}
										setCountryId={onCountrySelectChange}
									/>
								)}
								<div className='lg:col-span-2'>
									<PhoneNumber
										mask={
											countries.find(
												(item) => item.countryId === registerForm.values.phone_countryId
											)?.phoneFormat
										}
										phoneValue={registerForm.values.phoneNumber}
										setPhoneValue={(val) =>
											registerForm.setFieldValue('phoneNumber', val)
										}
									/>
								</div>
							</div>
							{registerForm.values.select_Phone_Type === 'Landline' && (
								<div>
									<InputTextField
										labelName='Ext.'
										variant='outlined'
										value={registerForm.values.landline_ext}
										onChange={(e) =>
											registerForm.setFieldValue('landline_ext', e.target.value)
										}
										name='Ext'
										fieldId='Ext'
									/>
								</div>
							)}
						</div>

						<div className='text-center '>
							<Button
								onClick={() => {
									setValidateOnChange(true);
									registerForm.handleSubmit();
								}}
								title='Create Account'
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default JoinFromInvite;

interface IJoinFromInvite {
	organizationId: string;
	organizationName: string;
	email: string;
	clientDb: any;
	createdBy: string;
	reactAppBaseUrl: any;
	xProjKey: any;
	appAuthorization: any;
	miscellaneousUrl: any;
	userType: string;
}
